<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" v-if="booking.sailingSchedule">
            <SailingSchedule :selectedSchedule="booking.sailingSchedule"  :selectedVessel="booking.vessel"/>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card outlined style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              ">
              <v-card-text class="my-0 py-0">
                <v-list dense subheader>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">local_shipping</v-icon>
                    Cargo Tracking
                    <v-spacer></v-spacer>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list dense style="max-height: 65vh; overflow-y: auto">
                    <v-list-item v-for="container in containers" :key="container.id"
                      style="border-bottom: 0.5px solid grey">
                      <v-list-item-action class="mx-0 px-0">
                        <v-list dense subheader class="mx-0 text-center">
                          <h4>{{ container.ctoNo }}</h4>
                          <h4 v-if="container.containerNo">{{ container.containerNo }}</h4>

                          <v-chip small class="primaryText--text my-2" :color="getStatusColor(container)"
                            style="border: none">
                            {{ getContainerStatus(container) }}
                          </v-chip>
                        </v-list>
                        <v-col> </v-col>
                      </v-list-item-action>
                      <v-list-item-content class="text-left">
                        <v-row>
                          <v-col cols="12" sm="5" class="my-0 py-0">
                            <v-list-item v-if="container.transportCoordinator">
                              <v-list-item-action>
                                <v-avatar :color="container.transportCoordinator.logo ? 'white' : 'secondary'">
                                  <v-img contain v-if="container.transportCoordinator.logo"
                                    :src="container.transportCoordinator.logo"></v-img>
                                  <h4 v-else>{{ container.transportCoordinator.name.charAt(0) }}</h4>
                                </v-avatar>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title v-if="container.transportCoordinator.alias">{{
            container.transportCoordinator.alias
          }}</v-list-item-title>
                                <v-list-item-title v-else-if="container.transportCoordinator">{{
            container.transportCoordinator.name
          }}</v-list-item-title>
                                <v-list-item-title v-else>-</v-list-item-title>
                                <v-list-item-subtitle>Transport Coordinator</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col cols="12" sm="5" class="my-0 py-0">
                            <v-list-item v-if="container.transporter">
                              <v-list-item-action>
                                <v-avatar :color="container.transporter.logo ? 'white' : 'secondary'">
                                  <v-img contain v-if="container.transporter.logo"
                                    :src="container.transporter.logo"></v-img>
                                  <h4 v-else>{{ container.transporter.name.charAt(0) }}</h4>
                                </v-avatar>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title v-if="container.transporter.alias">{{
            container.transporter.alias
          }}</v-list-item-title>
                                <v-list-item-title v-else-if="container.transporter">{{
            container.transporter.name
          }}</v-list-item-title>
                                <v-list-item-title v-else>-</v-list-item-title>
                                <v-list-item-subtitle>Transporter</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col cols="12" sm="2" class="my-0 py-4">
                            <v-btn text icon @click="showContainerDetails(container)">
                              <v-icon>{{ container.showDetails ? 'expand_less' : 'expand_more' }}</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <div v-if="container.showDetails">
                          <v-expand-transition>
                            <template>
                              <v-timeline>
                                <v-timeline-item v-for="(item, index) in  milestones(container)" :key="index"
                                  :color="item.color" :icon="item.icon" right>
                                  <template v-slot:opposite>{{ item.type }}</template>
                                  <div>
                                    <p v-if='item.locode'>
                                      <v-chip pill small outlined class="primaryText--text">
                                        <v-avatar size="24" left>
                                          <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
            .substring(0, 2)
            .toLowerCase()}.svg`"></v-img> </v-avatar>{{ item.name }}
                                      </v-chip>
                                    </p>
                                    <p v-else>{{ item.name }}</p>
                                    <strong style="font-size: 12px" v-if="item.date">{{
                                      formatDateTime(item.date, item.time)
                                      }}</strong>
                                  </div>
                                </v-timeline-item>
                              </v-timeline>
                            </template>
                          </v-expand-transition>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import SailingSchedule from "../SystemComponents/SailingSchedule.vue";
export default {
  props: ["booking", "containers"],
  data: () => ({
    types: [
      {
        name: "Empty Pickup",
        typeCode: "E",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "EMPTY_PICKUP",
        type: ["EXPORT"],
        icon: 'local_shipping',
        color: 'blue'
      },
      {
        name: "Inland Arrival",
        typeCode: "INLAND_ARRIVAL",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "INLAND_TRANSPORT_ARRIVAL",
        type: ["EXPORT"],
        icon: 'local_shipping',
        color: 'blue'
      },
      {
        name: "Full Pickup",
        typeCode: "F",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "FULL_PICKUP",
        type: ["IMPORT"],
      },
      {
        name: "Loading Point 1",
        typeCode: "1",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_1",
        type: ["EXPORT"],
        icon: 'warehouse',
        color: 'orange'
      },
      {
        name: "Loading Point 2",
        typeCode: "2",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_2",
        type: ["EXPORT"],
        icon: 'warehouse',
        color: 'orange'
      },
      {
        name: "Plug In",
        typeCode: "PI",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_IN",
        type: ["EXPORT"],
        icon: 'power',
        color: '#800000'
      },
      {
        name: "Plug Out",
        typeCode: "PI",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_OUT",
        type: ["EXPORT"],
        icon: 'power',
        color: '#800000'
      },
      {
        name: "Delivery Point 1",
        typeCode: "1",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "DELIVERY_POINT_1",
        type: ["IMPORT"],
        icon: 'warehouse',
        color: 'orange'
      },
      {
        name: "Delivery Point 2",
        typeCode: "2",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "DELIVERY_POINT_2",
        type: ["IMPORT"],
        icon: 'warehouse',
        color: 'orange'
      },
      {
        name: "Delivery Point 3",
        typeCode: "3",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "DELIVERY_POINT_3",
        type: ["IMPORT"],
        icon: 'warehouse',
        color: 'orange'
      },
      {
        name: "Weigh Bridge",
        typeCode: "W",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "WEIGH_BRIDGE",
        type: ["EXPORT"],
        icon: 'scale',
        color: 'purple'
      },
      {
        name: "Port Holding",
        typeCode: "P",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "FULL_DROP_OFF",
        type: ["EXPORT"],
        color: 'green',
        icon: 'anchor'
      },
      {
        name: "Drop Off Empty",
        typeCode: "D",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "EMPTY_DROP_OFF",
        type: ["IMPORT"],
      },

      //GLEN ADDONS FOR TRACKING OCEAN INSIGHTS
      {
        name: "Loaded On Vessel",
        typeCode: "L",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "LOAD",
        type: ['EXPORT'],
        icon: 'task_alt',
        color: 'silver'

      },
      {
        name: "Loaded On Vessel Transhipment Port 1",
        typeCode: "L_TSP1",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "LOAD_TSP_1",
        type: ['EXPORT'],
        icon: 'task_alt',
        color: 'silver'

      },
      {
        name: "Loaded On Vessel Transhipment Port 2",
        typeCode: "L_TSP2",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "LOAD_TSP_2",
        type: ['EXPORT'],
        icon: 'task_alt',
        color: 'silver'

      },
      {
        name: "Loaded On Vessel Transhipment Port 3",
        typeCode: "L_TSP3",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "LOAD_TSP_3",
        type: ['EXPORT'],
        icon: 'task_alt',
        color: 'silver'

      },
      {
        name: "Vessel Departure POL",
        typeCode: "VD_POL",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_DEPARTURE_POL",
        type: ['EXPORT'],
        icon: 'directions_boat',
        color: 'red'

      },
      {
        name: "Vessel Arrival",
        typeCode: "VA",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_ARRIVAL",
        type: ['EXPORT'],
        icon: 'location_on',
        color: 'green'

      },
      {
        name: "Vessel Arrival Transhipment Port 1",
        typeCode: "VA_TSP1",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_ARRIVAL_TSP_1",
        type: ['EXPORT'],
        icon: 'location_on',
        color: 'green'

      },
      {
        name: "Vessel Arrival Transhipment Port 2",
        typeCode: "VA_TSP2",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_ARRIVAL_TSP_2",
        type: ['EXPORT'],
        icon: 'location_on',
        color: 'green'

      },
      {
        name: "Vessel Arrival Transhipment Port 3",
        typeCode: "VA_TSP3",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_ARRIVAL_TSP_3",
        type: ['EXPORT'],
        icon: 'location_on',
        color: 'green'
      },
      {
        name: "Vessel Arrival Transhipment Port 4",
        typeCode: "VA_TSP4",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_ARRIVAL_TSP_4",
        type: ['EXPORT'],
        icon: 'location_on',
        color: 'green'
      },
      {
        name: "Discharge",
        typeCode: "Disc",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "DISCHARGE",
        type: ['EXPORT'],
        icon: 'label_important',
        color: 'grey'

      },
      {
        name: "Discharge POD",
        typeCode: "Disc_POD",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "POD_DISCHARGE",
        type: ['EXPORT'],
        icon: 'label_important',
        color: 'grey'

      },
      {
        name: "Discharge POD",
        typeCode: "Disc_POD_1",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "DISCHARGE_POD",
        type: ['EXPORT'],
        icon: 'label_important',
        color: 'grey'

      },
      {
        name: "Discharge Transhipment 2",
        typeCode: "Disc_POD_TSP2",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "DISCHARGE_TSP_2",
        type: ['EXPORT'],
        icon: 'label_important',
        color: 'grey'

      },
      {
        name: "Discharge Transhipment 3",
        typeCode: "Disc_POD_TSP3",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "DISCHARGE_TSP_3",
        type: ['EXPORT'],
        icon: 'label_important',
        color: 'grey'

      },
      {
        name: "Vessel Departure",
        typeCode: "VD_1",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_DEPARTURE",
        type: ['EXPORT'],
        icon: 'directions_boat',
        color: 'red'

      },
      {
        name: "Vessel Departure Transhipment Port 1",
        typeCode: "VD_TSP1",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_DEPARTURE_TSP_1",
        type: ['EXPORT'],
        icon: 'directions_boat',
        color: 'red'

      },
      {
        name: "Vessel Departure Transhipment Port 2",
        typeCode: "VD_TSP2",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_DEPARTURE_TSP_2",
        type: ['EXPORT'],
        icon: 'directions_boat',
        color: 'red'

      },
      {
        name: "Vessel Departure Transhipment Port 3",
        typeCode: "VD_TSP3",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_DEPARTURE_TSP_3",
        type: ['EXPORT'],
        icon: 'directions_boat',
        color: 'red'

      },
      {
        name: "Vessel Departure Transhipment Port 4",
        typeCode: "VD_TSP4",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_DEPARTURE_TSP_4",
        type: ['EXPORT'],
        icon: 'directions_boat',
        color: 'red'

      },
      {
        name: "Vessel Arrival at POD",
        typeCode: "VA_POD",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "VESSEL_ARRIVAL_POD",
        type: ['EXPORT'],
        icon: 'sports_score',
        color: 'green'

      },
      {
        name: "Final Destination",
        typeCode: "FINAL_DEST",
        eventType: "TRACKING",
        modeOfTransport: "VESSEL",
        value: "FINAL_DESTINATION",
        type: ['EXPORT'],
        icon: 'sports_score',
        color: 'green'

      },
    ],
    transportStatuses: [
      {
        text: "Pending",
        value: "PENDING",
        color: "orange",
      },
      // {
      //     text: 'Booking Confirmed',
      //     value: 'BOOKING_CONFIRMED',
      //     color: 'green lighten-1'
      // },
      {
        text: "Pre-Allocated",
        value: "PRE_ALLOCATED",
        color: "green darken-2",
      },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "lime darken-3",
      },
      {
        text: "Instr. Sent",
        value: "INSTRUCTION_SENT",
        color: "blue-grey",
      },
      {
        text: "Empty Out",
        value: "EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "success",
      },
    ],
  }),
  components: {
    SailingSchedule,
  },


  methods: {
    showContainerDetails(container) {
      this.$set(container, 'showDetails', !container.showDetails);
    },
    formatDateTime(date, time) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (time) {
          result += ' ' + time
        }
      }
      return result;
    },
    //old status color
    // getStatusColor(status) {
    //   //check if the 
    //   let find = this.transportStatuses.find((x) => x.text == status);
    //   return find ? find.color : "grey";
    // },
    getContainerStatus(container) {

      if (container.containerMilestones.length > 0) {
        const filtered = container.containerMilestones.filter(m => ((m.source === 'OceanInsights' && m.isActual === true) || (m.source === 'MSC' && m.isActual === true)) && m.type !== 'GATE_OUT_EMPTY');

        if (filtered.length > 0) {
          const groupedByType = filtered.reduce((acc, current) => {
            const type = current.type;
            if (!acc[type]) {
              acc[type] = current;
            } else {
              if (new Date(current.date) > new Date(acc[type].date)) {
                acc[type] = current;
              }
            }
            return acc;
          }, {});
          let lastMilestone = Object.values(groupedByType)[Object.values(groupedByType).length - 1];
          let find = this.types.find((x) => x.value == lastMilestone.type).name;
          return find;
        }
        else {
          return container.transportStatus;
        }
      }
      else {
        return container.transportStatus;
      }
    },
    getStatusColor(container) {
      if (container.containerMilestones.length > 0) {
        const filtered = container.containerMilestones.filter(m => ((m.source === 'OceanInsights' && m.isActual === true) || (m.source === 'MSC' && m.isActual === true)) && m.type !== 'GATE_OUT_EMPTY');
        console.log('filtered', filtered);
        if (filtered.length > 0) {
          const groupedByType = filtered.reduce((acc, current) => {
            const type = current.type;
            if (!acc[type]) {
              acc[type] = current;
            } else {
              if (new Date(current.date) > new Date(acc[type].date)) {
                acc[type] = current;
              }
            }
            return acc;
          }, {});
          let lastMilestone = Object.values(groupedByType)[Object.values(groupedByType).length - 1];
          let find = this.types.find((x) => x.value == lastMilestone.type).color;
          return find;
        }
        else {
          let find = this.transportStatuses.find((x) => x.text == container.transportStatus);
          if (find) {
            return find.color;
          }
          else {
            return "grey";
          }
        }
      }
      else {
        return "grey";
      }
      //random comment
    },
    milestones(container) {
      let containerObj = {};
      container.containerMilestones.forEach((x) => {
        if (containerObj[x.type]) {
          if (x.isActual === true) {
            containerObj[x.type] = x;
          }
        }
        else{
          containerObj[x.type] = x;
        }
        // containerObj[x.type] = x;
      });
      console.log('containerObj 1', containerObj);

      containerObj = Object.keys(containerObj).reduce((object, key) => {
        // if ((containerObj[key].source === 'OceanInsights' && containerObj[key].isActual === false) && (containerObj[key].source === 'MSC' && containerObj[key].isActual === false)) {
        //   return object;
        // }
        if ((containerObj[key].source === 'OceanInsights' || containerObj[key].source === 'MSC') && containerObj[key].isActual === false) {
          return object;  // Skip adding this entry to the result object
        }

        object[key] = containerObj[key];
        return object;
      }, {});
      let available = this.types.filter((x) =>
        x.type.includes(this.booking.movementType)
      );
      let result = [];
      // console.log('available', available);
      console.log('containerObj', containerObj);
      for (let i = 0; i < available.length; i++) {
        // console.log('availible',available[i]);
        let obj = {
          name: "-",
          value: available[i].type,
          type: available[i].name,
          typeCode: available[i].typeCode,
          icon: available[i].icon,
          color: available[i].color
        };
        console.log('obj', obj);
        if (containerObj[available[i].value]) {
          console.log('isnide');
          let point = containerObj[available[i].value];
          // console.log('containerObj',containerObj);
          obj.date = point.date;
          obj.time = point.time ? point.time : null;
          if (!obj.time && obj.date) {
            obj.time = obj.date.split('T')[1].split('.')[0];
          }
          if (point.poi) {
            obj.name = point.poi.friendlyName ?? point.poi.name;
            obj.locode = point.completeLocation.locode
          } else if (!point.poi && (point.source === 'OceanInsights' || point.source === 'MSC')) {
            obj.name = point.completeLocation.name
            obj.locode = point.completeLocation.locode
            // obj.name = point.location
          }
          else {
            obj.locode = point.completeLocation.locode
            obj.name = point.description;
          }
        }
        result.push(obj);
      }
      //remove from array if name is '-',time is null and date is null
      result = result.filter((x) => x.name != "-" || x.time || x.date);
      console.log('results ', result);
      return result;

    },
  },
};
</script>