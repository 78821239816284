<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" class="ml-0 pl-0 mx-0 px-0">
            <v-list class="ml-0 pl-0 mx-0 px-0" dense>
              <div v-if="selectedSchedule.stackDate">
                <v-subheader style="font-size: 16px">Stack Dates</v-subheader>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Reefer Stacks</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-avatar
                        size="16"
                        :color="
                          selectedSchedule.stackDate.reeferStackStart &&
                          selectedSchedule.stackDate.reeferStackStartConfirmed
                            ? 'success'
                            : selectedSchedule.stackDate.reeferStackStart
                            ? 'orange'
                            : 'grey'
                        "
                      ></v-avatar>
                      {{
                        formatDateTime(
                          selectedSchedule.stackDate.reeferStackStart
                        ) ?? "NONE"
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-avatar
                        size="16"
                        :color="
                          selectedSchedule.stackDate.reeferStackFinish &&
                          selectedSchedule.stackDate.reeferStackFinishConfirmed
                            ? 'success'
                            : selectedSchedule.stackDate.reeferStackFinish
                            ? 'orange'
                            : 'grey'
                        "
                      ></v-avatar>
                      {{
                        formatDateTime(
                          selectedSchedule.stackDate.reeferStackFinish
                        ) ?? "NONE"
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Dry Stacks</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-avatar
                        size="16"
                        :color="
                          selectedSchedule.stackDate.dryStackStart &&
                          selectedSchedule.stackDate.dryStackStartConfirmed
                            ? 'success'
                            : selectedSchedule.stackDate.dryStackStart
                            ? 'orange'
                            : 'grey'
                        "
                      ></v-avatar>
                      {{
                        formatDateTime(
                          selectedSchedule.stackDate.dryStackStart
                        ) ?? "NONE"
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-avatar
                        size="16"
                        :color="
                          selectedSchedule.stackDate.dryStackFinish &&
                          selectedSchedule.stackDate.dryStackFinishConfirmed
                            ? 'success'
                            : selectedSchedule.stackDate.dryStackFinish
                            ? 'orange'
                            : 'grey'
                        "
                      ></v-avatar>
                      {{
                        formatDateTime(
                          selectedSchedule.stackDate.dryStackFinish
                        ) ?? "NONE"
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>FIS Stacks</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-avatar
                        size="16"
                        :color="
                          selectedSchedule.stackDate.fisStart &&
                          selectedSchedule.stackDate.fisStartConfirmed
                            ? 'success'
                            : selectedSchedule.stackDate.fisStart
                            ? 'orange'
                            : 'grey'
                        "
                      ></v-avatar>
                      {{
                        formatDateTime(selectedSchedule.stackDate.fisStart) ??
                        "NONE"
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-avatar
                        size="16"
                        :color="
                          selectedSchedule.stackDate.fisFinish &&
                          selectedSchedule.stackDate.fisFinishConfirmed
                            ? 'success'
                            : selectedSchedule.stackDate.fisFinish
                            ? 'orange'
                            : 'grey'
                        "
                      ></v-avatar>
                      {{
                        formatDateTime(selectedSchedule.stackDate.fisFinish) ??
                        "NONE"
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-divider v-if="selectedSchedule.stackDate"></v-divider>
              <v-subheader style="font-size: 16px">Transport Legs</v-subheader>
              <v-divider></v-divider>
              <v-list-item
                v-for="leg in selectedSchedule.sailingScheduleLegs"
                :key="leg.id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip small class="mr-1">{{ leg.leg }}</v-chip>
                    <v-chip small outlined style="border: none">
                      <v-avatar size="20" left>
                        <v-img
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfLoadValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ leg.portOfLoadValue }}
                      <v-icon small class="mx-1">arrow_forward</v-icon>
                      <v-avatar size="20" class="ml-1" left>
                        <v-img
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfDischargeValue
                            .substring(0, 2)
                            .toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                      {{ leg.portOfDischargeValue }}
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon small color="blue" class="mr-2">schedule</v-icon>
                    {{ formatDateTime(leg.etd) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-icon small color="green" class="mr-2">schedule</v-icon>
                    {{ formatDateTime(leg.eta) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="leg.vesselDescription">
                    <v-icon small color="grey" class="mr-2"
                      >directions_boat</v-icon
                    >
                    {{ leg.vesselDescription }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="leg.voyage">
                    <v-icon small color="grey" class="mr-2">tag</v-icon>
                    {{ leg.voyage }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="8">
            <gmap-map
              :zoom.sync="zoom"
              class="ma-0 pa-0"
              id="map"
              :options="{
                styles: mapStyle,
                disableDefaultUI: true,
              }"
              :center.sync="mapCenter"
              ref="mapRef"
              style="height: 500px; width: 100%; border-radius: 20px"
            >
              <gmap-custom-marker
                :offsetY="-18"

                alignment="center"
                :marker="{
                  lat:x.latitude,
                  lng:x.longitude
                }"
              >
                <div>
                  <div
                    style="
                      border-radius: 50%;
                      background-color: var(--v-primary-base) !important;
                      border: 3px solid black;
                    "
                  >
                    <v-avatar size="32" color="green">
                 <v-icon>sailing</v-icon>
               
                    </v-avatar>
                  </div>
                  <div class="map-marker"></div>
                </div>
              </gmap-custom-marker>
            </gmap-map>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import { getGoogleMapsAPI } from "gmap-vue";
export default {
  props: ["selectedSchedule","selectedVessel"],
  components: {
    GmapCustomMarker,
  },
  watch: {
    "selectedSchedule.id": {
      immediate: true,
      handler(val) {
        this.getSailingScheduleLegs();
      },
    },
    "selectedVessel.id": {
      immediate: true,
      handler(val) {
        this.getVessels();
      },
    },
  },
  data: () => ({
    loadingScheduleLegs: false,
    mapStyle: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#c1d6e0",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#9cb0ba",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
    ],
    mapCenter: {
      lng: 0,
      lat: 0,
    },
    points: [],
    vesselPoints:[],
    x:'',
    zoom: 1.8,
  }),
  computed: {
    google: getGoogleMapsAPI,
  },
  methods: {
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },

    async getVessels(){
      this.x = this.selectedVessel   
    },
    async getSailingScheduleLegs() {
      let item = this.selectedSchedule;
      if (!item.sailingScheduleLegs) {
        this.loadingLegs.push(item.id);
        item.loading = true;
        this.loadingScheduleLegs = true;
        item.sailingScheduleLegs = await this.$API.getSailingScheduleLegs(
          item.id
        );
      }
console.log(item.sailingScheduleLegs)
      let uniqueCodes = [
        ...new Set([
          ...item.sailingScheduleLegs.map((x) => x.portOfLoadValue),
          ...item.sailingScheduleLegs.map((x) => x.portOfDischargeValue),
        ]),
      ];
      let points = [];
      for (let i = 0; i < uniqueCodes.length; i++) {
        let obj = {
          leg: i + 1,
          locode: uniqueCodes[i],
        };
        let find = item.sailingScheduleLegs.find(
          (x) => x.portOfLoadValue == uniqueCodes[i]
        );
        if (!find) {
          find = item.sailingScheduleLegs.find(
            (x) => x.portOfDischargeValue == uniqueCodes[i]
          );
          obj.latitude = parseFloat(find.destinationLatitude);
          obj.longitude = parseFloat(find.destinationLongitude);
        } else {
          obj.latitude = parseFloat(find.originLatitude);
          obj.longitude = parseFloat(find.originLongitude);
        }
        if (obj.latitude && obj.longitude) {
          obj.marker = {
            lat: obj.latitude,
            lng: obj.longitude,
          };
        }
        points.push(obj);
      }
      points = points.filter((x) => x.marker);
      if (points.length > 0) {
        this.mapCenter = points[0].marker;
      }
      this.points = points;
      this.selectedSchedule = item;
      if (item.sailingScheduleLegs.length > 3) {
        item.firstSailingScheduleLegs = item.sailingScheduleLegs.slice(0, 3);
        item.secondSailingScheduleLegs = item.sailingScheduleLegs.slice(3);
      }
      this.loadingScheduleLegs = false;
      item.loading = false;
    },
  },
};
</script>