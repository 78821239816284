<template>
  <v-dialog v-model="show" fullscreen>
    <v-card rounded="0" flat>
      <div v-if="dialogCloseControl" class="d-flex justify-end pa-2">
        <v-icon @click="close">close</v-icon>
      </div> 
      <div>
        <component :is="componentName" :regimeCode="regimeCode" :referenceData="referenceData" @close="close"></component>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import HLContainerTracking from '@/components/Integrations/ContainerTracking/HL/HLContainerTracking.vue';
import { CONTAINER_TRACKING_INTEGRATION_CODES } from '@/utils/constants'

const INTEGRATION_CODE_MAP = {
  [CONTAINER_TRACKING_INTEGRATION_CODES.HL]: "HLContainerTracking"
}

export default {
  components: {
    HLContainerTracking
  },

  props: {
    intergrationCode: {
      type: String
    },

    regimeCode: {
      type: String
    },

    dialogCloseControl: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      show: false,
      referenceData: null
    }
  },

  computed: {
    componentName() {
      return INTEGRATION_CODE_MAP[this.intergrationCode];
    }
  },

  methods: {
    open(referenceData) {
      if(referenceData) this.referenceData = referenceData
      console.log(referenceData)
      this.show = true;
      this.$emit("opened");
    },

    close() {
      this.show = false;
      this.$emit("closed");
    }
  }
}
</script>

<style>

</style>