<template>
  <v-card width="94vw" v-if="container && booking" style="contain: content">
    <v-toolbar flat style="background-color: var(--v-toolbar-lighten1) !important">
      <v-toolbar-title>
        <v-chip small class="mx-2" :color="containerStatusColor(container)">
          <div>{{ container.status ? container.status : "No Status" }}</div>
        </v-chip>
        <b>{{ title }}</b></v-toolbar-title>
      <v-chip class="ml-2" small v-if="container.booking.user">Created By: <v-icon class="ml-1" left small>person</v-icon>
        {{ container.booking.user.firstname }}
        {{ container.booking.user.surname }}</v-chip>
      <v-spacer></v-spacer>

      <v-btn class="ml-2" icon color="secondary" :loading="savingChange"
        @click="updateContainer()" ><v-icon>save</v-icon></v-btn>
      <v-btn icon @click="closeDialog(),$emit('refreshContainers')" color="redPop"><v-icon>close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="pt-3">
      <v-row>
        <v-col cols="12" sm="8">
          <v-row>
            <v-col cols="12" sm="6">
              <v-card width="100%" style="
                  background-color: var(--v-toolbar-lighten1) !important;
                  margin: 1vh 0 2vh 0;
                ">
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2" color="secondary"> tag </v-icon>
                  Reference Numbers
                </v-subheader>
                <v-row>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="container.shipmentFile">
                          {{ container.shipmentFile.fileNumber }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          File Number
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <!-- <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="container.customerContainerRef"
                        >
                          {{ container.customerContainerRef }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Customer Ref
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item> -->
                    <v-text-field outlined dense rounded label="Customer Ref."
                      v-model="container.customerContainerRef"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                   <div v-if="booking.serviceType == 'BREAKBULK'">

                   </div>

                   <div v-else>
                    <v-text-field outlined dense rounded label="Container No" v-model="container.containerNo"
                    :rules="[rules.containerNo]" @input="setChange"></v-text-field>
                   </div>
                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-text-field outlined dense rounded label="Seal No" v-model="container.sealNo"
                      @input="setChange"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" class="my-0 py-0" v-if="container.type === 'CONTAINER'">
                    <!-- <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-if="container.containerType">
                              {{ container.containerType }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Container Type
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item> -->
                    <v-autocomplete rounded outlined dense clearable label="Container Type"
                      v-model="container.containerTypeCode" :items="containerTypes" @change="updateContainerDescription()"
                      item-text="shortDescription" item-value="typeCode">
                    </v-autocomplete>

                  </v-col>
                  <v-col cols="12" sm="6" class="my-0 py-0">
                    <v-select outlined dense rounded v-model="container.verificationMethod"
                      :items="['Method 1', 'Method 2']" label="Verification Method"></v-select>
                    <!-- <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-if="container.verificationMethod"
                            >
                              {{ container.verificationMethod }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Verification Method
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item> -->
                  </v-col>

                  <v-col cols="12" sm="12" class="pl-1">
                    <v-subheader style="font-size: 16px">
                      <v-icon class="mr-2" color="secondary">
                        scale
                      </v-icon>
                      Weights
                      <v-spacer></v-spacer>
                      <v-tooltip top v-if="container.verificationMethod === 'Method 1' && container.vgmWeight && container.vgmWeight > 0">
                        <template v-slot:activator="{ on }">
                          <v-btn :loading="updatingPallets" text flat icon @click="updatePalletWeights" v-on="on">
                            <v-icon color="primary">auto_mode</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Override Pallet Gross Weights</span>
                      </v-tooltip>
                    </v-subheader>
                    <v-card-text class="my-0 py-0">
                      <v-row no-gutters>
                        <v-col cols="12" md="6">
                          <v-text-field prefix="Nett" hide-details class="mt-1" type="number" step="0.001" suffix="KG"
                            v-model="container.nettWeight" outlined dense rounded></v-text-field>
                          <v-text-field prefix="Gross" hide-details class="mt-1" type="number" step="0.001" suffix="KG"
                            v-model="container.grossWeight" outlined dense rounded></v-text-field>
                          <v-switch label="Load Out Weight" v-model="container.loadOutReceived"></v-switch>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field prefix="VGM" class="mt-1" hide-details suffix="KG" type="number" :step="0.001"
                            v-model="container.vgmWeight" @input="setChange" outlined disabled dense
                            rounded></v-text-field>
                          <v-text-field prefix="Tare" hide-details class="mt-1" type="number" :step="0.001" suffix="KG"
                            v-model="container.tareWeight" @input="setChange" outlined disabled dense
                            rounded></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" class="pl-1">
                      <v-subheader style="font-size: 16px" >
                        <v-icon class="mr-2" color="secondary"> router </v-icon>
                        Services
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list dense>
                          <v-list-item v-if="item.preAdviseRequired">
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 14px">
                              Preadvised
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                              <v-switch color="blue" v-model="container.preadvised" @change="setChange"></v-switch>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="container.sensorDeviceType">
                          <v-list-item-icon class="mr-2">
                            <v-icon> router </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 14px">
                              <b>{{
                                container.sensorDeviceType
                                  ? container.sensorDeviceType
                                  : ""
                              }}</b>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{
                                container.sensorService
                                  ? container.sensorService
                                  : ""
                              }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle style="font-size: 12px">
                              {{ container.serviceType }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="12" sm="6" class="pl-1">
                      <v-subheader style="font-size: 16px">
                        <v-icon class="mr-2" color="secondary">
                          description
                        </v-icon>
                        Documents
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-progress-linear
                        v-if="loadingDetails"
                        indeterminate
                      ></v-progress-linear>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="!container.ctoDocument">-</span>
                            <span v-else
                              >Version: {{ container.ctoDocument.version }}</span
                            >
                          </v-list-item-title>
                          <v-list-item-subtitle> CTO </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-row justify="center">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  @click="generateCTO(container.id)"
                                  :loading="loadingCto"
                                  v-on="on"
                                >
                                  <v-icon color="teal">post_add</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px"
                                >Generate new CTO</span
                              >
                            </v-tooltip>
                            <v-tooltip top v-if="container.ctoDocument">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  @click="viewCTO(container.ctoDocument)"
                                  v-on="on"
                                >
                                  <v-icon color="blue">launch</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px">View Document</span>
                            </v-tooltip>
                            <v-tooltip top v-if="container.ctoDocument">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  icon
                                  @click="downloadDocument(container.ctoDocument)"
                                  v-on="on"
                                >
                                  <v-icon>download</v-icon>
                                </v-btn>
                              </template>
                              <span style="font-size: 12px">Download CTO</span>
                            </v-tooltip>
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col> -->

                  <v-col cols="12" class="my-0 py-0">
                    <v-subheader style="font-size: 16px">
                      <v-icon class="mr-2" color="secondary"> ac_unit </v-icon>
                      Regime <v-spacer></v-spacer>
                      <v-chip small v-if="booking.regime &&
                        booking.regime.probeCount &&
                        booking.regime.probeType
                        " class="mx-1">
                        <span>{{ booking.regime.probeCount }} x
                          {{ booking.regime.probeType }}</span>
                      </v-chip>
                      <v-chip v-if="booking.regime && booking.regime.steri" outlined style="border: none"
                        color="blue">STERI
                        <v-icon right color="blue">ac_unit</v-icon></v-chip>
                    </v-subheader>
                    <v-list-item v-if="booking.regime">
                      <v-list-item-action class="mr-0 pr-0">
                        <v-btn text rounded> {{
                          booking.regime.code
                        }}</v-btn>
                      </v-list-item-action>
                      <v-list-item-content class="ml-0 pl-0">
                        <v-list-item-subtitle>
                          <v-icon color="#007d5c" small class="mr-2">thermostat</v-icon> Set Temp:
                          {{ `${booking.regime.setPointTemp}°C` }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="booking.regime.protocol">
                          <v-icon color="#9d904a" small class="mr-2">thermostat</v-icon> Protocol:
                          {{ `${booking.regime.protocol}°C` }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <!-- <v-list-item-content style="max-width: 180px" v-if="!booking.caProduct || (booking.caProduct && booking.caProduct== 'MAXTEND')">
                          <v-select  hide-details dense outlined rounded v-model="booking.vents" :suffix="booking.vents != 'Closed' && booking.vents != 'Fully Open' && booking.vents != 'MA' && booking.vents != 'CA' && booking.vents != 'Auto'?'CBM':''" label="Vents" :items="ventSettings" @change="$emit('updateVents')"></v-select>
                        </v-list-item-content> -->
                      <!-- <v-list-item-action v-else>
                          <v-chip>Closed Vents</v-chip>
                        </v-list-item-action> -->
                    </v-list-item>
                    <v-list-item v-else>
                      <v-list-item-content class="text-center">
                        <v-list-item-title style="color: grey">
                          No Regime Code
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="mt-2 px-2">
                      <v-col cols="12" sm="4" class="my-0 py-0">
                        <v-select v-if="booking.serviceType!='BREAKBULK'" label="Vents" outlined dense hide-details class="pb-1" v-model="container.vents"
                          :items="ventSettings"></v-select>
                      </v-col>
                      <v-col cols="12" sm="4" class="my-0 py-0">
                        <v-text-field prepend-inner-icon="thermostat" label="Set Temp" hide-details
                          v-model="container.setPointTemp" outlined dense class="pb-1" suffix="°C"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" class="my-0 py-0">
                        <v-text-field label="Humidity" v-model="container.humidity" outlined hide-details dense
                          type="number" suffix="%"></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" sm="4">
                        <v-text-field
                          label="Oxygen"
                          v-model="container.oxygen"
                          outlined
                          dense
                          v-if="booking.caProduct"
                          hide-details
                          class="pb-1"
                          type="number"
                          suffix="%"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          label="Carbon Dioxide"
                          v-model="container.carbonDioxide"
                          v-if="booking.caProduct"
                          hide-details
                          class="pb-1"
                          outlined
                          type="number"
                          dense
                          suffix="%"
                        ></v-text-field>
                      </v-col> -->

                    </v-row>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-textarea class="px-2" label="Notes" v-model="container.containerComments" outlined rows="4"></v-textarea>
                  </v-col> -->

                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card flat class="my-3"
                style="background-color: var(--v-toolbar-lighten1) !important; max-height: 75vh; overflow-y: auto;">
                <v-card-text>
                  <v-col cols="12" sm="12" class="pt-0">
                    <v-list dense subheader>
                      <v-subheader style="font-size: 16px" class="pl-2">
                        <v-icon class="mr-2" color="secondary"> widgets </v-icon>
                        Cargo <v-spacer></v-spacer>
                        <v-btn icon @click="addContainerProduct(container)"
                          color="primary"><v-icon>add_circle_outline</v-icon></v-btn>
                      </v-subheader>
                      <v-list-item v-for="cargo in container.containerProducts" :key="cargo.id" @click="
                        editContainerProduct(container, cargo)
                        ">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            <b>{{ cargo.product.name }}</b>
                            <span style="font-size: 12px" v-if="cargo.hsCode">
                              - {{ cargo.hsCode }}</span>
                            <span style="font-size: 12px" v-if="cargo.quantity">
                              - {{ cargo.quantity }} {{ cargo.quantityType }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="cargo.description" style="font-size: 12px">
                            {{ cargo.description }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle style="font-size: 12px" v-if="cargo.nettWeight || cargo.grossWeight">
                            NETT {{ cargo.nettWeight }} KG - GROSS
                            {{ cargo.grossWeight }} KG
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-container>
                            <v-row justify="center" align="center">
                              <v-tooltip top v-if="cargo.organic">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="green" class="mx-1">
                                    spa
                                  </v-icon>
                                </template>
                                <span style="font-size: 12px">Organic</span>
                              </v-tooltip>

                              <v-tooltip top v-if="cargo.industrial">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="blue-grey" class="mx-1">
                                    factory
                                  </v-icon>
                                </template>
                                <span style="font-size: 12px">Industrial Use</span>
                              </v-tooltip>

                              <v-tooltip top v-if="cargo.hazardous">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="orange" class="mx-1">
                                    warning
                                  </v-icon>
                                </template>
                                <span style="font-size: 12px">Hazardous</span>
                              </v-tooltip>
                            </v-row>
                          </v-container>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-subheader style="font-size:16px">
                    <v-icon class="mr-2" color="secondary"> description </v-icon>
                    Products & Services <v-spacer></v-spacer>
                    <v-btn icon @click="editTemporaryProducts(container)" color="primary"><v-icon>edit</v-icon></v-btn>

                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item v-if="mapTemporaryProducts(
                    container.temporaryProducts
                  ).length > 0">
                    <div v-for="(product, index) in mapTemporaryProducts(
                          container.temporaryProducts
                        )" :key="index">
                      <v-btn icon outlined small :color="product.color" class="my-2 mx-1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon small v-on="on">{{
                              product.icon
                            }}</v-icon>
                          </template>
                          <span style="white-space:pre-line;">{{
                            product.product.replace(/_/g, " ")

                          }}
                          </span>
                        </v-tooltip>
                      </v-btn>
                    </div>
                  </v-list-item>
                  <v-list-item v-if="mapTemporaryProducts(
                    container.temporaryProducts
                  ).length == 0">
                    <v-list-item-content>
                      <span style="color: grey; font-size: 12px">No additional products/services</span>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- DATA SOURCES -->
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px" :key="dataSourceKey">
                    <v-icon color="secondary" class="mr-2">bar_chart</v-icon>
                    Data Source(s)
                    <v-spacer></v-spacer>
                    <!-- <v-btn :disabled="availableSources.length === 0" :loading="savingDataSource" @click="updateDataSource()" color="primary" icon>
                      <v-icon>save</v-icon>
                    </v-btn> -->
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-progress-linear v-if="loadingDataSource" color="primary" indeterminate></v-progress-linear>
                  <v-list-item v-for="source in availableSources" :key="source.id"
                    style="border-bottom: 0.5px solid grey">
                    <v-list-item-content @click="viewSource(source)" style="cursor: pointer">
                      <v-list-item-title>
                        {{ source.type }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="source.locationCode">
                        <v-icon class="mr-2" color="grey" small>location_on</v-icon>
                        {{ source.locationCode }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="source.transactionTime">
                        <v-icon class="mr-2" color="grey" small>schedule</v-icon>
                        {{ source.transactionTime }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-wrap">
                        <v-icon class="mr-2" color="grey" small>description</v-icon>{{ source.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch color="success" v-model="source.active" @change="checkDataSourceChanges()"></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="!loadingDataSource && availableSources.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px">No data sources found</span>
                    </v-list-item-content>
                  </v-list-item>

                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="secondary"> chat </v-icon>
                    Comments
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-card height="40vh" width="inherit" style="
                      overflow-y: auto;
                      background-color: transparent !important;
                    " flat :loading="loadingComments" id="messagesContainer">
                    <v-list dense>
                      <v-list-item v-if="containerComments && containerComments.length == 0
                        ">
                        <v-list-item-content class="text-center">
                          <span style="font-size: 12px; color: grey">
                            No comments.
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                      <div v-if="containerComments">
                        <v-list-item v-for="(comment, index) in containerComments" :key="comment.id"
                          :id="'comment_' + index" class="py-0 my-1">
                          <v-list-item-avatar v-if="comment.userId != $store.state.user.id">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="36"
                                  class="mt-0 pt-0">
                                  <span v-if="!comment.user.avatar" class="primaryText--text text-h5">{{
                                   $Format.avatarAbbreviation(
                                      comment.user.firstname +
                                      " " +
                                      comment.user.surname
                                    )
                                  }}</span>
                                  <img v-else :src="comment.user.avatar" referrerpolicy="no-referrer" />
                                </v-avatar>
                              </template>
                              <span style="font-size: 12px">{{ comment.user.firstname }}
                                {{ comment.user.surname }}</span>
                            </v-tooltip>
                          </v-list-item-avatar>
                          <v-list-item-content v-if="comment.userId == $store.state.user.id && comment.isDeleted == false">

                          <div style="display: flex;flex-direction: row;justify-content: start;">
          
   <v-tooltip top>
     <template v-slot:activator="{ on: tooltip }">

      <v-btn  v-on="{ ...tooltip }" text @click="openDialog(comment)"><v-icon color="blue">edit</v-icon></v-btn>

     </template>
     <span>Edit Comment</span>
   </v-tooltip>

   <v-tooltip top>
     <template v-slot:activator="{ on: tooltip }">

        <v-btn :loading="loadingDeleteComment"   v-on="{ ...tooltip }" text @click="deleteContainerComment(comment.id)">
          <v-icon color="red">delete</v-icon>

       </v-btn>

     </template>
     <span>Remove Comment</span>
   </v-tooltip>
                          </div>
                          </v-list-item-content>
                          <v-list-item-content v-if="comment.userId == $store.state.user.id && comment.isDeleted == true">
                          <div></div>
                          
                          </v-list-item-content>
                          <v-list-item-content :class="comment.userId == $store.state.user.id
                            ? 'text-right'
                            : 'text-left'
                            ">
                            <v-list-item-title class="text-wrap" v-if="comment.isDeleted" style="text-decoration: line-through;color: gray">
                              {{ comment.comment }}
                            </v-list-item-title>
                            <v-list-item-title class="text-wrap" v-else>
                              {{ comment.comment }}
                            </v-list-item-title>
                            
                            <v-list-item-subtitle>
                              <i style="font-size: 12px; color: grey">{{
                                formatDate(comment.createdAt).dateTime
                              }}</i>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-avatar v-if="comment.userId == $store.state.user.id">
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar v-bind="attrs" v-on="on" style="cursor: pointer" color="secondary" size="36"
                                  class="mt-0 pt-0">
                                  <span v-if="!comment.user.avatar" class="primaryText--text text-h5">{{
                                   $Format.avatarAbbreviation(
                                      comment.user.firstname +
                                      " " +
                                      comment.user.surname
                                    )
                                  }}</span>
                                  <img v-else :src="comment.user.avatar" referrerpolicy="no-referrer" />
                                </v-avatar>
                              </template>
                              <span style="font-size: 12px">{{ comment.user.firstname }}
                                {{ comment.user.surname }}</span>
                            </v-tooltip>
                          </v-list-item-avatar>
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-card>
                  <v-card flat height="7vh" width="100%" class="mx-0 px-0" style="
                    background-color: var(--v-toolbar-lighten1) !important;
                    display: flex;
                    align-items: center;
                    overflow-y: hidden;
                  ">
                    <v-textarea :key="chatContainerUpdateKey" persistent-placeholder placeholder="Type a Message"
                      @keydown.enter="commentContainer()" class="ml-2" no-resize hide-details outlined height="6vh"
                      style="width: 80%; contain: content; border-radius: 20px;" v-model="containerChatArea"
                      :value="containerChatArea"></v-textarea>
                    <v-btn :loading="sendingMessage" icon class="ml-3 mr-2"
                      @click="commentContainer()"><v-icon>send</v-icon></v-btn>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card width="100%" height="75vh" class="my-3" :loading="loadingDetails" style="
              background-color: var(--v-toolbar-lighten1) !important;
              max-height: 75vh;
              overflow-y: auto;
            ">
            <v-list style="max-height: 50vh; overflow-y: auto" dense subheader>
              <v-subheader style="font-size: 16px">Transport Type</v-subheader>
              <v-divider></v-divider>
              <v-list-item v-for="haulier in hauliers" :key="haulier.index" @click="setHaulier(haulier)">
                <v-list-item-action>
                  <v-avatar color="white" size="36">
                    <v-img v-if="haulier.logo" :src="haulier.logo" contain></v-img>
                    <v-icon v-else color="black">{{ haulier.icon }}</v-icon>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ haulier.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    haulier.subtitle
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="success" v-if="container[haulier.flag]">check_circle_outline</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-subheader style="font-size: 16px">
              <v-icon class="mr-2" color="secondary"> local_shipping </v-icon>
              Transport Coordinator
            </v-subheader>
            <v-select :items="selectableParties" :disabled="!container.isPrivateHaulage" :placeholder="container.isPrivateHaulage
              ? 'Select Coordinator'
              : 'Not Applicable'
              " item-text="name" item-value="id" rounded style="background-color: #2f2f2f; margin: 1vh 1vw 0.5vh 1vw"
              hide-details outlined return-object v-model="container.transportCoordinator" @change="setTransporter"
              clearable dense>
              <template v-slot:selection="data">
                <v-row align="center" v-if="container.isPrivateHaulage">
                  <v-avatar size="32" class="mr-2" v-if="data.item">
                    <v-img v-if="data.item.logo" contain :src="data.item.logo"></v-img>
                  </v-avatar>
                  <span v-if="data.item">
                    {{ data.item.name }}
                  </span>
                </v-row>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img contain v-if="data.item.logo" :src="data.item.logo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>

            <v-list-item dense v-if="container.isPrivateHaulage &&
              booking.movementType == 'EXPORT'
              " class="mt-2">
              <v-list-item-action>
                <v-icon color="secondary">article</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Pre-Advise </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action :key="preadviseKey">
                <v-switch color="success" v-model="container.preAdviseRequired"></v-switch>
              </v-list-item-action>
            </v-list-item>

            <v-list-item dense v-if="(container.isPrivateHaulage ||
              container.isCarrierHaulage) &&
              booking.movementType == 'EXPORT'
              " class="mt-2">
              <v-list-item-action>
                <v-icon color="secondary">electrical_services</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Plugged-In </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action :key="pluggedInKey">
                <v-switch color="success" v-model="container.pluggedInRequired"
                  :disabled="container.transportCoordinatorId === $store.state.currentOrg.id"></v-switch>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>

            <v-subheader style="font-size: 16px" v-if="booking.movementType == 'EXPORT'">
              <v-icon class="mr-2" color="secondary">location_on</v-icon>
              Loading Points
            </v-subheader>
            <v-subheader style="font-size: 16px" v-else>
              <v-icon class="mr-2" color="secondary">location_on</v-icon>
              Delivery Points
            </v-subheader>
            <v-card-text>
              <v-list dense class="pa-0" :key="milestoneKey">
                <!-- <v-divider></v-divider> -->
                <v-list-item v-for="(milestone, index) in availableMilestones.filter(
                      (x) => x.type == booking.movementType &&
                        (x.value !== 'GASSING' ||
                          (x.value === 'GASSING' && booking.caProduct && !avoBox))
                    )" :key="index" @click="addMilestone(container, milestone.value)">
                  <v-list-item-action class="mr-0 pr-0">
                    <v-avatar size="10" v-if="container[milestone.value]" :color="container[milestone.value].isActual
                      ? 'success'
                      : 'secondary'
                      "></v-avatar>
                    <v-icon v-else color="grey" small>warning</v-icon>
                  </v-list-item-action>
                  <v-list-item-content v-if="container[milestone.value]">
                    <v-list-item-title style="font-size: 14px">{{
                      container[milestone.value].description
                    }}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px">{{
                      formatDateTime(container[milestone.value].date, container[milestone.value].time)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-subtitle style="font-size: 14px">{{
                      milestone.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader style="font-size: 16px">
                  <v-icon class="mr-2" color="secondary">article</v-icon>
                  Documents
                </v-subheader>
                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!container.ctoDocument">-</span>
                      <span v-else>Version: {{ container.ctoDocument.version }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle> CTO </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="ctoKey">
                    <v-row justify="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="generateCTO(container.id, container)" :loading="loadingCto" v-on="on">
                            <v-icon color="teal">post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Generate new CTO</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.ctoDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="viewCTO(container.ctoDocument)" v-on="on">
                            <v-icon color="blue">launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.ctoDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="downloadDocument(container.ctoDocument)" v-on="on">
                            <v-icon>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Download CTO</span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!container.larDocument">-</span>
                      <span v-else>Version: {{ container.larDocument.version }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle> LAR </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="larKey">
                    <v-row justify="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="larForm(container)" :loading="loadingLAR" v-on="on">
                            <v-icon color="teal">post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Generate new LAR</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.larDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="viewCTO(container.larDocument)" v-on="on">
                            <v-icon color="blue">launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.larDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="downloadDocument(container.larDocument)" v-on="on">
                            <v-icon>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Download LAR</span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item v-if="booking.customerId === 3892">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!container.packingListDocument">-</span>
                      <span v-else>Version: {{ container.packingListDocument.version }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle> Packing List </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="packingListKey">
                    <v-row justify="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="specialInstructionsModal = true"  v-on="on" :loading="loadingPackingList">
                            <v-icon color="teal">post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Generate new Packing List</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.packingListDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="viewPackingList(container.packingListDocument)" v-on="on">
                            <v-icon color="blue">launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.packingListDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="downloadDocument(container.packingListDocument)" v-on="on">
                            <v-icon>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Download Packing List</span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-if="!container.earlyArrivalDocument">-</span>
                      <span v-else>Version: {{ container.earlyArrivalDocument.version }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle> Early Arrival </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action :key="larKey">
                    <v-row justify="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="earlyArrivalForm(container)" :loading="loadingLAR" v-on="on">
                            <v-icon color="teal">post_add</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Generate new Early Arrival</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.earlyArrivalDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="viewCTO(container.earlyArrivalDocument)" v-on="on">
                            <v-icon color="blue">launch</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">View Document</span>
                      </v-tooltip>
                      <v-tooltip top v-if="container.earlyArrivalDocument">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click="downloadDocument(container.earlyArrivalDocument)" v-on="on">
                            <v-icon>download</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-size: 12px">Download Early Arrival</span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>

              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="specialInstructionsModal" width="550px"  :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Packing List
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="generatePackingList(container.id), (specialInstructionsModal = false)" icon ><v-icon
              color="teal">download</v-icon></v-btn> -->

          <v-btn icon text @click="(specialInstructionsModal = false)" color="red">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea v-model="specialInstructions" label="Special Instrcutions" outlined rows="4"></v-textarea>
          <v-row justify="center">
                   <v-btn @click="generatePackingList(container.id), (specialInstructionsModal = false)" small rounded
              color="blue" class="mx-1" > Generate </v-btn>
            </v-row>  
        </v-card-text>
      </v-card>
      </v-dialog>
    <v-dialog v-model="larFormModal" width="550px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            LAR Arrival Form
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="generateLAR(container.id)" icon :loading="loadingLAR"><v-icon
              color="teal">download</v-icon></v-btn>

          <v-btn icon text @click="(larFormModal = false), (item = {})" color="red">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-model="reasonForLAR" label="Reason for LAR"></v-text-field>
          <v-row>
            <v-col cols="7">
              <v-date-picker v-model="arrivalDate" label="Arrival Date" :style="{ marginBottom: '20px' }"></v-date-picker>
              <v-autocomplete hide-details dense :items="timeOptions" v-model="arrivalTime" outlined rounded label="Time"
                prepend-inner-icon="schedule"></v-autocomplete>

            </v-col>
            <v-col cols="5">
              <v-radio-group v-model="containerSize">
                <v-subheader>Container Size</v-subheader>
                <v-radio label="6 METRE" value="6 METRE"></v-radio>
                <v-radio label="12 METRE" value="12 METRE"></v-radio>
                <v-radio label="13.7 METRE" value="13.7 METRE"></v-radio>
              </v-radio-group>
              <v-radio-group v-model="containerType">
                <v-subheader>Container Type</v-subheader>
                <v-radio label="GP" value="GP"></v-radio>
                <v-radio label="REEFER" value="REEFER"></v-radio>
                <v-radio label="HAZARDOUS" value="HAZARDOUS"></v-radio>
                <v-radio label="O.O.G CONTAINER" value="O.O.G CONTAINER"></v-radio>
                <v-radio label="HIGH CUBE" value="HIGH CUBE"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="earlyArrivalModal" width="550px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Early Arrival Form
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="generateEarlyArrival(container.id)" icon :loading="loadingEAR"><v-icon
              color="teal">download</v-icon></v-btn>

          <v-btn icon text @click="(earlyArrivalModal = false), (item = {})" color="red">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-model="reasonForEarlyArrival" label="Reason for Early Arrival"></v-text-field>
          <v-row>
            <v-col cols="7">
              <v-date-picker v-model="arrivalDate" label="Arrival Date" :style="{ marginBottom: '20px' }"></v-date-picker>
              <v-autocomplete hide-details dense :items="timeOptions" v-model="arrivalTime" outlined rounded label="Time"
                prepend-inner-icon="schedule"></v-autocomplete>

            </v-col>
            <v-col cols="5">
              <v-radio-group v-model="containerSize">
                <v-subheader>Container Size</v-subheader>
                <v-radio label="6 METRE" value="6 METRE"></v-radio>
                <v-radio label="12 METRE" value="12 METRE"></v-radio>
                <v-radio label="13.7 METRE" value="13.7 METRE"></v-radio>
              </v-radio-group>
              <v-radio-group v-model="containerType">
                <v-subheader>Container Type</v-subheader>
                <v-radio label="GP" value="GP"></v-radio>
                <v-radio label="REEFER" value="REEFER"></v-radio>
                <v-radio label="HAZARDOUS" value="HAZARDOUS"></v-radio>
                <v-radio label="O.O.G CONTAINER" value="O.O.G CONTAINER"></v-radio>
                <v-radio label="HIGH CUBE" value="HIGH CUBE"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewModal" width="1200px" :fullscreen="fullscreen || $vuetify.breakpoint.mobile">
      <v-card v-if="item && item.name">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ item.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(item)" icon><v-icon>download</v-icon></v-btn>
          <v-btn @click="fullscreen = !fullscreen" icon><v-icon v-if="!fullscreen">fullscreen</v-icon><v-icon
              v-else>fullscreen_exit</v-icon></v-btn>
          <v-btn icon text @click="(previewModal = false), (item = {})">X</v-btn>
        </v-toolbar>
        <v-card-text :style="{
          'max-height': fullscreen ? '90vh' : '75vh',
          'overflow-y': 'auto',
        }">
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="item.type && item.type.includes('image/')">
                <v-img :src="item.url" contain style="max-height: 70vh" @load="imageLoading = false">
                </v-img>
              </div>
              <div v-else-if="item.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="9" class="text-center" v-if="item.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed :width="fullscreen ? 1200 : 900" :source="item.url" @loaded="imageLoading = false" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span>This file is in a format that cannot be previewed.</span>
                    <br /><br />
                    <v-btn color="primary" style="text-transform: none" @click="downloadDocument(item)">
                      <v-icon class="mr-1">download</v-icon> Download</v-btn>
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addMilestoneModal" width="500px" :fullscreen="$vuetify.breakpoint.mobile">
      <ManageContainerMilestone :item="milestoneItem" :bookingType="booking.movementType" :key="milestoneKey"
        @close="addMilestoneModal = false" @save="milestoneUpdate" />
    </v-dialog>

    <v-dialog v-model="containerProductModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card v-if="item && item.selectedProduct">
        <Products @close="(containerProductModal = false), (item = null)" :item="item.selectedProduct"
          @deleted="deleteSelectedContainerProduct" @chosenProduct="updateSelectedContainerProduct" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="temporaryProductModal" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>{{
            bulkType == "Add" ? "Add Products" : "Edit Products"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn
            icon
            @click="addProducts()"
            :loading="saveProducts"
            color="green"
          >
            <v-icon>save</v-icon>
          </v-btn> -->
          <v-btn icon @click="saveTemporaryProducts(container)" color="secondary"
            :disabled = "(availableProducts.monitoringService === 'TempCheck' || availableProducts.monitoringService === 'TempCheckPLUS') && !availableDevices.some(device => device.count>0) "
            :loading="saveProducts"><v-icon>save</v-icon></v-btn>
          <v-btn icon @click="closeTemporaryProductModal()" color="primary">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <!-- Left Side with List Items -->
            <v-col cols="4">
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>bolt</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Genset Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="gensetToggleKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Genset')" :color="availableProducts.Genset === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Genset')" :color="availableProducts.Genset === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <!-- New List Items -->

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>air</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Otflow Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="otflowKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Otflow')" :color="availableProducts.Otflow === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Otflow')" :color="availableProducts.Otflow === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>sanitizer</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Zoono Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="zoonoKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Zoono')" :color="availableProducts.Zoono === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Zoono')" :color="availableProducts.Zoono === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>power</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    Void Plugs Required                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="voidPlugKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Void_Plugs')" :color="availableProducts.Void_Plugs === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Void_Plugs')" :color="availableProducts.Void_Plugs === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>


              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>watch_later</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Early Arrival Required</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="earlyArrivalKeyA">
                    <v-btn x-small @click="toggleProducts('NO', 'Early_Arrival')" :color="availableProducts.Early_Arrival === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Early_Arrival')" :color="availableProducts.Early_Arrival === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense v-if="availableProducts.Early_Arrival === true">
                <v-select label="LAR description (Early Arrival)" placeholder="Select a LAR description" persistent-placeholder
                  hide-details outlined dense class="py-1 mt-2 mb-2" :items="[
                    'Pending',
                    'Approved',
                    'Approved (No Charge)',
                    'Rejected',
                    'Approved (Not Used)',
                    'Not Applicable',
                  ]" v-model="earlyDescription"></v-select>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>watch_later</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Late Arrival Required</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="lateArrivalKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Late_Arrival')" :color="availableProducts.Late_Arrival === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Late_Arrival')" :color="availableProducts.Late_Arrival === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense v-if="availableProducts.Late_Arrival === true">
                <v-select label="LAR description (Late Arrival)" placeholder="Select a LAR description" persistent-placeholder
                  hide-details outlined dense class="py-1 mt-2 mb-2" :items="[
                    'Pending',
                    'Approved',
                    'Approved (No Charge)',
                    'Rejected',
                    'Approved (Not Used)',
                    'Not Applicable',
                  ]" v-model="lateDescription"></v-select>
              </v-list-item>

             


              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>cleaning_services</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Fumigation Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'Fumigation')" :color="availableProducts.Fumigation === false
                      ? 'primary'
                      : 'grey'
                      ">
                      NO
                    </v-btn>
                    <v-btn x-small @click="toggleProducts('YES', 'Fumigation')" :color="availableProducts.Fumigation === true
                      ? 'tertiary'
                      : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>settings_overscan</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title> Container Liners Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'ContainerLiners')" :color="availableProducts.ContainerLiners === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'ContainerLiners')" :color="availableProducts.ContainerLiners === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                  <!-- <v-switch v-model="bulkEdit.gensetRequired" color="success"></v-switch> -->
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>emergency</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>Steri Insurance Required </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'SteriInsurance')" :color="availableProducts.SteriInsurance === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'SteriInsurance')" :color="availableProducts.SteriInsurance === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>

              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>smart_screen</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>Smart Fresh </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'SmartFresh')" :color="availableProducts.SmartFresh === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'SmartFresh')" :color="availableProducts.SmartFresh === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>


              <v-list-item dense>
                <v-list-item-action>
                  <v-icon>scale</v-icon>
                </v-list-item-action>
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>Heavy Load </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn-toggle :key="portMonitoringKey">
                    <v-btn x-small @click="toggleProducts('NO', 'HeavyLoad')" :color="availableProducts.HeavyLoad === false ? 'primary' : 'grey'
                      ">
                      NO
                    </v-btn>

                    <v-btn x-small @click="toggleProducts('YES', 'HeavyLoad')" :color="availableProducts.HeavyLoad === true ? 'tertiary' : 'grey'
                      ">
                      YES
                    </v-btn>
                  </v-btn-toggle>
                </v-list-item-action>
              </v-list-item>
            </v-col>

            <!-- Right Side with Text Fields -->
            <v-col cols="4">
              <v-card outlined flat>
                <v-row no-gutters>
                  <v-col cols="11">
                    <!-- Adjust "cols" as needed -->
                    <v-list-item dense>
                      <v-select label="Monitoring Service" placeholder="Select a Monitoring Service"
                        persistent-placeholder hide-details outlined dense item-text="text" item-value="value"
                        class="py-1 mt-2 mb-2" :items="[
                          {
                            text:'iCheck',
                            value: 'iCheck',
                          },
                          {
                            text: 'TempCheck',
                            value: 'TempCheck',
                          },
                          {
                            text: 'TempCheckPLUS',
                            value: 'TempCheckPLUS',
                          },
                          // {
                          //   text: 'TempCheckLite',
                          //   value: 'TempCheckLite',
                          // },
                          {
                            text: 'Port Monitoring',
                            value: 'Port Monitoring',
                          },
                          {
                            text: 'N/A',
                            value: ''
                          }
                        ]" v-model="availableProducts.monitoringService"></v-select>
                    </v-list-item>
                  </v-col>
                  <v-col cols="1">
                    <!-- Adjust "cols" as needed -->
                    <v-btn icon color="primary" @click="addServices()" class="mt-3">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- <v-card-title>
                  TelemPlus
                  <v-spacer></v-spacer>
                  <v-btn icon color="primary" class="ml-3"
                                                            @click="addServices()"><v-icon>add_circle_outline</v-icon></v-btn>
                </v-card-title> -->
                <v-card-text>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item v-for="(product, index) in availableDevices.filter(
                      (option) =>
                        option.count > 0 && option.product == 'TelemPlus'
                    )" :key="index">
                      <v-text-field type="number" v-model="product.count" :min="0" class="small-text-field"
                        style="text-align: center" :label="product.variation + ' ' + 'Template'"
                        @input="updateProductCount(product)" />
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="4">
              <v-card flat outlined>
                <v-card-title>
                  Additional products <v-spacer></v-spacer> <v-btn icon color="primary"
                    @click="addAdditionalProduct = true" class="mt-3">
                    <v-icon>add_circle_outline</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item v-for="(product, index) in uniqueProducts.filter(
                      (option) =>
                        option.count > 0 && option.variation == 'Standard'
                    )" :key="index">
                      <v-list-item-content>
                        <v-list-item-title> {{ product.product }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <el-input-number v-model="product.count" :min="0" size="mini"
                          @change="updateProductCount(product)"></el-input-number>
                      </v-list-item-action>
                      <!-- <v-text-field
                          type="number"
                          v-model="product.count"
                          :min="0"
                          class="small-text-field"
                          style="text-align: center"
                          :label="product.variation + ' ' + 'Template'"
                          @input="updateProductCount(product)"
                        /> -->
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <!-- <v-card
                outlined
                flat
                v-for="uniqueProduct in uniqueProducts.filter((p) =>
                  ['Void Plugs', 'Blast Freezing'].includes(p)
                )"
                :key="uniqueProduct"
              >
                <v-card-title>
                  {{ uniqueProduct }}
                  <v-spacer></v-spacer>
                  <v-list dense class="ma-0 pa-0">
                    <v-list-item
                      v-for="(product, index) in availableDevices.filter(
                        (d) => d.product === uniqueProduct
                      )"
                      :key="index"
                    >
                      <el-input-number
                        v-model="product.count"
                        :min="0"
                        controls-position="right"
                        size="mini"
                        @change="updateProductCount(product)"
                      ></el-input-number>
                      <v-text-field
                        type="number"
                        v-model="product.count"
                        :min="0"
                        class="small-text-field"
                        style="text-align: center"
                        @input="updateProductCount(product)"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                </v-card-title>
                <v-card-text> </v-card-text>
              </v-card> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addAdditionalProduct" max-width="400px" persistent>
      <v-card>
        <v-card-title>Additional Products <v-spacer></v-spacer>
          <!-- <v-btn icon @click="addProducts" color="primary"><v-icon>add_circle_outline</v-icon></v-btn> -->
          <v-btn icon @click="addAdditionalProduct = false"
            color="secondary"><v-icon>close</v-icon></v-btn></v-card-title>
        <v-list dense>
          <v-list-item v-for="(product, index) in uniqueProducts.filter(
            (x) => x.variation === 'Standard'
          )" :key="index">
            <v-checkbox :label="product.product" v-model="product.checked" @change="toggleCheckbox(product)" />
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addServiceModal" max-width="400px" persistent>
      <v-card>
        <v-card-title>TelemPlus Products <v-spacer></v-spacer>
          <!-- <v-btn icon @click="addProducts" color="primary"><v-icon>add_circle_outline</v-icon></v-btn> -->
          <v-btn icon @click="addServiceModal = false" color="secondary"><v-icon>close</v-icon></v-btn></v-card-title>
        <v-list>
          <v-list-item v-for="(product, index) in availableDevices.filter(
            (x) => x.product == 'TelemPlus'
          )" :key="index">
            <v-checkbox :label="product.variation" v-model="product.checked" @change="toggleCheckbox(product)" />
          </v-list-item>
        </v-list>
      </v-card> 
    </v-dialog>

<v-dialog v-model="editButton"  max-width="500px">



  <v-card>

   <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: baseline;">
    <v-card-title>Edit Comment</v-card-title>
    <v-card-actions>
      <v-btn :loading="loadingEditComment" text @click="updateContainerComment(newEditedComment.id)"><v-icon color="blue">save</v-icon></v-btn>
      <v-btn  text @click="closeEditDialog()">X</v-btn>

    </v-card-actions>
   </div>
<div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
  <v-textarea v-model="newEditedComment.comment" style="width: 80%; contain: content; border-radius: 20px;"  outlined rows="4">
</v-textarea>
</div>
 
  </v-card>
</v-dialog>
    <!-- Data Source Modal -->
    <v-dialog v-model="sourceModal" fullscreen persistent>
      <v-card v-if="sourceModal">
        <v-card-title>
          Manage Data Source
          <v-spacer></v-spacer>
          <v-btn text @click="(sourceModal = false), (selectedContainer = null)">X</v-btn>
        </v-card-title>
        <v-card-subtitle>
          {{ sourceItem.name }}
          <br />
          <v-icon class="mr-2" small color="grey">label</v-icon>
          {{ sourceItem.type }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <!-- <v-col cols="12">
            </v-col> -->
            <v-col cols="12" v-if="sourceItem.sourceType == 'stockFile'">
              <v-data-table :items="sourceItem.contents" :headers="importHeaders" height="82vh" fixed-header dense
                disable-pagination hide-default-footer></v-data-table>
            </v-col>
            <v-col cols="12" v-else>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-list dense>
                    <v-subheader style="font-size: 16px">Containers</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-for="container in sourceItem.stockFileContainers" :key="container.id"
                      @click="selectedContainer = container" :style="{
                        'border-left':
                          selectedContainer &&
                            selectedContainer.id == container.id
                            ? '3px solid var(--v-primary-base)'
                            : '',
                      }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ container.containerNo }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="10">
                  <v-data-table v-if="selectedContainer" :items="selectedContainer.contents" :headers="importHeaders"
                    height="82vh" fixed-header dense disable-pagination hide-default-footer></v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
  
<script>
import axios from "axios";
import dateFormat from "dateformat";
// import GmapCustomMarker from "vue2-gmap-custom-marker";
import ManageContainerMilestone from "./ManageContainerMilestone.vue";
import Products from './ProductsDialog.vue'
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import isContainer from "../../../utils/containerNo";
export default {
  props: ["booking", "container", "containerTypes"],
  components: {
    // GmapCustomMarker,
    ManageContainerMilestone,
    Products,
    VuePdfEmbed,
  },
  data: () => ({
    updatingPallets: false,
    loadingLAR: false,
    loadingEAR: false,
    addAdditionalProduct: false,
    addMilestoneModal: false,
    availableMilestones: [
      { value: "EMPTY_PICKUP", type: "EXPORT", name: "Empty Pickup" },
      { value: "LOADING_POINT_1", type: "EXPORT", name: "Loading Point 1" },
      { value: "LOADING_POINT_2", type: "EXPORT", name: "Loading Point 2" },
      { value: "GASSING", type: 'EXPORT', name: "Gassing" },
      { value: "WEIGH_BRIDGE", type: "EXPORT", name: "Weigh Bridge" },
      { value: "FULL_DROP_OFF", type: "EXPORT", name: "Port Holding" },

      { value: "FULL_PICKUP", type: "IMPORT", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "IMPORT", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "IMPORT", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "IMPORT", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "IMPORT", name: "Empty Drop Off" },

      { value: "FULL_PICKUP", type: "LOCAL", name: "Full Pickup" },
      { value: "DELIVERY_POINT_1", type: "LOCAL", name: "Delivery Point 1" },
      { value: "DELIVERY_POINT_2", type: "LOCAL", name: "Delivery Point 2" },
      { value: "DELIVERY_POINT_3", type: "LOCAL", name: "Delivery Point 3" },
      { value: "EMPTY_DROP_OFF", type: "LOCAL", name: "Empty Drop Off" },
    ],
    center: { lat: 0.0, lng: 0.0 },
    change: false,
    contractors: [],
    containerStatus: [
      { name: "Unallocated", icon: "warning", color: "orange darken-2" },
      { name: "Ghost", icon: "category", color: "blue-grey" },
      { name: "Moved", icon: "exit_to_app", color: "blue" },
      { name: "Allocated", icon: "task_alt", color: "success" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Claim", icon: "attach_money", color: "indigo" },
    ],
    containerComments: [],
    containerProductModal: false,
    ctoKey: 550,
    regime: null,
    loadingCto: false,
    editButton:false,
    milestones: [],
    fullscreen: false,
    loadingContractors: false,
    loadingDetails: true,
    loadingComments: true,
    loadingEditComment:false,
    loadingDeleteComment:false,
    sendingMessage: false,
    newEditedComment: "",
    containerChatArea: "",
    chatContainerUpdateKey: 0,
    imageLoading: true,
    item: {},
    legs: [],
    loadedDistance: 0,
    haulierKey: 750,
    key: 50,
    milestoneItem: {},
    milestoneKey: 100,
    reasonForLAR: "",
    reasonForEarlyArrival: "",
    arrivalDate: null,
    containerSize: "12 METRE",
    timezone: '',
    containerType: "REEFER",
    mapStyle: {
      scrollwheel: true,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#283a54" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#283a54" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#212121" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
    },
    packingListKey: 5000,
    specialInstructions: "",
    milestoneTypes: [
      {
        name: "Empty Pickup",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "EMPTY_PICKUP",
      },
      {
        name: "Stuff Date",
        eventType: "EQUIPMENT",
        modeOfTransport: "TRUCK",
        value: "STUFF_DATE",
      },
      {
        name: "Loading Point 1",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_1",
      },
      {
        name: "Loading Point 2",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "LOADING_POINT_2",
      },
      {
        name: "Weigh Bridge",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "WEIGH_BRIDGE",
      },
      {
        name: "Plugged In",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_IN",
      },
      {
        name: "Unplugged",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "PLUG_OUT",
      },
      {
        name: "Drop Off Point",
        eventType: "TRANSPORT",
        modeOfTransport: "TRUCK",
        value: "FULL_DROP_OFF",
      },
    ],
    loadingPackingList: false,
    pluggedInKey: 120,
    milestoneContainer: {},
    previewModal: false,
    productView: false,
    statuses: [
      { id: 4, state: "AT", color: "#006bc3", location: null },
      { id: 3, state: "ASSIGNED", color: "#0094db", location: null },
      { id: 8, state: "COMPLETED", color: "#008a61", location: null },
      { id: 6, state: "DONE LOADING", color: "#2cc194", location: null },
      { id: 5, state: "EN ROUTE TO", color: "#ccb50a", location: null },
      { id: 7, state: "STOPPED", color: "#e55000", location: null },
      { id: 9, state: "CANCELLED", color: "#ff6666", location: null },
      { id: 10, state: "ISSUE", color: "#7f0000", location: null },
      { id: 2, state: "PENDING", color: "#657583", location: null },
      { id: 1, state: "TO BE ALLOCATED", color: "#b4bbc1", location: null },
    ],
    statusLocations: [
      { name: "LOADING POINT 1", value: "LP1" },
      { name: "LOADING POINT 2", value: "LP2" },
      { name: "EMPTY DEPOT", value: "EMPTY" },
      { name: "PORT OF LOADING", value: "POL" },
      { name: "DROP OFF", value: "DROPOFF" },
      { name: "YARD", value: "YARD" },
      { name: "FUEL STATION", value: "FUEL STATION" },
    ],
    arrivalTime: null,
    preadviseKey: 850,
    savingChange: false,
    statusFilters: {
      at: false,
      assigned: false,
      completed: false,
      "done Loading": false,
      "en Route To": false,
      stopped: false,
      cancelled: false,
      issue: false,
      pending: false,
      "to Be Allocated": false,
    },
    temporaryProductsView: [
      { icon: "bolt", color: "#D6D46D", name: "Genset" },
      { icon: "watch_later", color: "#BB2525", name: "Late Arrival" },
      { icon: "watch_later", color: "orange", name: "Early Arrival" },
      { icon: "router", color: "#18e00d", name: "TelemPlus" },
      { icon: "shopping_bag", color: "#F1DBBF", name: "Dunnage Bags" },
      { icon: "air", color: "#6A9C89", name: "Otflow" },
      { icon: "sanitizer", color: "#5b93c7", name: "Zoono" },
      { icon: "cleaning_services", color: "#BB2525", name: "Fumigation" },
      { icon: "power", color: "#D6D46D", name: "Void Plugs" },
      { icon: "ac_unit", color: "#13a7d4", name: "Blast Freezing" },
      { icon: "settings_overscan", color: "#EEF296", name: 'ContainerLiners' },

      { icon: "pivot_table_chart", color: "#508D69", name: "Latches" },
      { icon: "settings_input_component", color: "#EA906C", name: "Ratches" },
      {icon:'filter_alt',color:"#EE12CA",name:"Ethylene Filters"},

      { icon: "video_stable", color: "#5F6F52", name: 'Stabling bars' },
      { icon: "note", color: "#C5E898", name: "Kraft paper" },
      { icon: "backpack", color: "#DCBFFF", name: 'Absorb Bags' },
      { icon: "emergency", color: "#FFBFBF", name: 'SteriInsurance' },
      {icon:"smart_screen", color:"#FFBFFF", name:'SmartFresh'},
    {icon:"scale", color:"#000072", name:'HeavyLoad'}
      // { icon: "monitor_heart", color: "#F1DBBF", name: "Port Monitoring" },
    ],
    portMonitoringKey: 0,
    lateArrivalKey: 0,
    earlyArrivalKeyA:0,
    blastFreezingKey: 0,
    voidPlugKey: 0,
    otflowKey: 0,
    zoonoKey: 0,
    industrialKey: 0,
    gensetToggleKey: 0,
    temporaryProductModal: false,
    bulkType: null,
    availableDevices: [
 
      {
        product: "TelemPlus",
        variation: "2G GEO",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
             monitoringService: "",
            checked: false,
          },
      // {
      //   product: "TelemPlus",
      //   variation: "3G GEO",
      //   count: 0,
      //   monitoringService: "",
      //   checked: false,
      // },
      {
        product: "TelemPlus",
        variation: "T4",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "RF",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "4G GEO",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      // {
      //   product: "TelemPlus",
      //   variation: "GEO EAGLE",
      //   count: 0,
      //   monitoringService: "",
      //   checked: false,
      // },
      {
        product: "TelemPlus",
        variation: "MOST",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "2G TIVE",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "5G TIVE",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        monitoringService: "",
        checked: false,
      },
      {
        product: "Dunnage Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,

      },
      {
        product: "Blast Freezing",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Kraft paper",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Absorb Bags",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Stabling bars",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Latches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
        product: "Ratches",
        variation: "Standard",
        count: 0,
        // monitoringService: "",
        checked: false,
      },
      {
          product:'Ethylene Filters',
          variation:'Standard',
          count:0,
          checked:false
          
        }
    ],
    settingShipmentProfile: false,
    specialInstructionsModal: false,
    availableProducts: {
      Genset: false,
      Otflow: false,
      Zoono: false,
      // Dunnage_Bags: false,
      Void_Plugs: false,
      Late_Arrival: false,
      Early_Arrival: false,
      Fumigation: false,
      monitoringService: null,
      ContainerLiners: false,
      SteriInsurance: false,
      SmartFresh: false,
      HeavyLoad: false,

    },
    timeOptions: [],
    lateDescription: null,
    earlyDescription: null,
    saveProducts: false,
    addServiceModal: false,
    larKey: 200,
    earlyArrivalKey:8000,
    larFormModal: false,
    transportStatuses: [
      {
        text: "Pending",
        value: "PENDING",
        color: "orange",
      },
      // {
      //     text: 'Booking Confirmed',
      //     value: 'BOOKING_CONFIRMED',
      //     color: 'green lighten-1'
      // },
      {
        text: "Pre-Allocated",
        value: "PRE_ALLOCATED",
        color: "green darken-2",
      },
      {
        text: "Allocated",
        value: "ALLOCATED",
        color: "lime darken-3",
      },
      {
        text: "Instr. Sent",
        value: "INSTRUCTION_SENT",
        color: "blue-grey",
      },
      {
        text: "Empty Out",
        value: "EMPTY_PICKUP",
        color: "teal",
      },
      {
        text: "En route LP-1",
        value: "EN_ROUTE_LP1",
        color: "cyan darken-2",
      },
      {
        text: "At LP-1",
        value: "AT_LP1",
        color: "cyan darken-4",
      },
      {
        text: "En route LP-2",
        value: "EN_ROUTE_LP2",
        color: "purple darken-2",
      },
      {
        text: "At LP-2",
        value: "AT_LP2",
        color: "purple darken-4",
      },
      {
        text: "En route WB",
        value: "EN_ROUTE_WB",
        color: "blue",
      },
      {
        text: "AT WB",
        value: "AT_WB",
        color: "blue lighten-1",
      },
      {
        text: "En route Depot",
        value: "EN_ROUTE_DEPOT",
        color: "blue darken-4",
      },
      {
        text: "At Depot",
        value: "AT_DEPOT",
        color: "blue darken-5",
      },
      {
        text: "En route Port",
        value: "EN_ROUTE_PORT",
        color: "blue darken-1",
      },
      {
        text: "At Port",
        value: "AT_PORT",
        color: "blue darken-2",
      },
      {
        text: "Gate In",
        value: "FULL_DROP_OFF",
        color: "success",
      },
    ],
    rules: {
      containerNo(value) {
        if (/\b[A-Z]{4}\d{7}\b/.test(value)) {
          return true;
        }
        return "Container number needs to be in the format: ABCD1234567";
      },
    },
    selectableParties: [],
    params: {
      search: "",
      filter: {},
    },
    ventSettings: [
      "Closed",
      "5",
      "10",
      "15",
      "20",
      "25",
      "28",
      "30",
      "35",
      "40",
      "50",
      "Fully Open",
      "CA",
      "MA",
      "Auto",
    ],
    activeSources: [],
    availableSources: [],
    dataSourceChange: false,
    dataSourceKey: 4000,
    savingDataSource: false,
    sourceItem: {},
    sourceModal: false,
    loadingDataSource: false,
    earlyArrivalModal:false
  }),
  async created() {
    this.timeOptions = this.getTimeOptions()

  },
  watch: {
    'container.pluggedInRequired': function (newVal) {
      if (newVal) {
        this.container.transportStatus = 'Arrange Plugged In';
      }
      else {
        this.container.transportStatus = 'Unallocated';
      }

    },
    "container.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getVentSettings()
          this.getContainerDetails();
          this.getContainerComments(val);
          this.getAvailableSources();
        }
      },
    },
    'container.temporaryProducts': {
      handler(newVal) {
        if (newVal) {
          this.updateAvailableDevices(newVal);
          this.updateAvailibleProducts(newVal);
          this.updateLarDescription(newVal);
          this.updateMonitoringService(newVal)
        }

      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.setSelectableParties();
  },
  computed: {
    avoBox() {
      let containerProducts = this.container.containerProducts;
      console.log('containerProducts', containerProducts)
      let productIds = [...new Set(containerProducts.map(p => p.product.id))];
      //check if any productId === 226
      if (productIds.includes(226)) {
        return true
      }
      else {
        return false
      }
    },
    title() {
      if (this.container.customerContainerRef) {
        return `${this.container.ctoNo} (${this.container.customerContainerRef})`;
      }
      return this.container.ctoNo;
    },
    hauliers() {
      let result = [
        // {
        //     index: 1,
        //     type: 'Organisation',
        //     name: this.$store.state.currentOrg.name,
        //     logo: this.$store.state.currentOrg.logo,
        //     id: this.$store.state.currentOrg.id,
        //     icon: null,
        // },
        {
          index: 2,
          type: "Carrier Haulage",
          name: "Carrier Haulage",
          logo: null,
          icon: "directions_boat",
          flag: "isCarrierHaulage",
        },
        {
          index: 4,
          type: "Merchant Haulage",
          name: "Merchant Haulage",
          icon: "local_shipping",
          logo: null,
          flag: "isPrivateHaulage",
        },
        {
          index: 3,
          type: "Railage",
          name: "Rail",
          icon: "train",
          logo: null,
          flag: "isRailage",
        },
      ];
      // if (this.booking.shippingLine) {
      //     result[1].name = this.booking.shippingLine.name
      //     result[1].logo = this.booking.shippingLine.logo
      // }
      return result;
    },
    hasProductsCount() {
      return Object.values(this.availableProducts).some((x) => x === true);
    },
    hasDevicesWithCount() {
      return this.availableDevices.some((device) => device.count > 0);
    },
    hasDevices() {
      let devices = this.availableDevices.filter((device) => device.count > 0);
      return devices;
    },
    hasProducts() {
      let finalProducts = [];
      let products = Object.keys(this.availableProducts).filter(
        (x) => this.availableProducts[x] === true
      );
      products = products.map((x) => x.replace(/_/g, " "));
      products.forEach((product) => {
        let productObject = {
          product: product,
          selected: true,
        };
        finalProducts.push(productObject);
      });
      return finalProducts;
    },
    uniqueProducts() {
      const unique = new Set();
      const result = [];
      for (const device of this.availableDevices) {
        // console.log('device',device)
        if (!unique.has(device.product)) {
          unique.add(device);
          result.push(device);
        }
      }
      return result;
    },
  },
  methods: {
    openDialog(comment) {
      this.loadingEditComment = false;
      this.newEditedComment = comment
      console.log(comment)
      this.editButton = true;
   

    },
    closeEditDialog() {
      this.editButton = false;
     
    },
    getTimeOptions() {
      let start = new Date()
      start.setHours(0, 0, 0, 0)
      let end = new Date()
      end.setHours(23, 45, 0, 0)
      let timeOptions = []
      while (start <= end) {
        let hour = start.getHours()
        let minute = start.getMinutes()
        if (hour < 10) {
          hour = '0' + hour
        }
        if (minute < 10) {
          minute = '0' + minute
        }
        timeOptions.push(hour + ':' + minute)
        start.setMinutes(start.getMinutes() + 15)
      }
      return timeOptions
    },
    toggleCheckbox(product) {
      product.count = product.checked ? 1 : 0;
    },
    addServices() {
      this.addServiceModal = true;
    },
    updateProductCount(product) {
      //check the devices for the product and varaition
      let count = parseInt(product.count);
      this.availableDevices.forEach((x) => {
        if (x.product == product.name && x.variation == product.variation) {
          x.count = count;
        }
        if (x.count < 1) {
          x.checked = 0
        }
      });

    },
    saveTemporaryProducts() {

      this.saveProducts = true
      let newTemporaryProducts = []
      this.availableDevices.forEach(device => {
        if (device.count > 0) {
          // let variation = device.variation.split(' ')[0]
          newTemporaryProducts.push({
            product: device.product,
            variation: device.variation,
            count: device.count,
            //check if this.availableProducts.monitoringService is not null. availableProducts is an object
            monitoringService: this.availableProducts.monitoringService ? this.availableProducts.monitoringService : ''
          })
        }
      })

      Object.keys(this.availableProducts).forEach(product => {
        if (this.availableProducts[product] === true) {
          product = product.replace(/_/g, " ")
          newTemporaryProducts.push({
            product: product,
            variation: 'Standard',
            count: 1,
            monitoringService: ''
          })
        }
      })
      // console.log('this.availableProducts.monitoringService',this.availableProducts.monitoringService)
      // if(this.availableProducts.monitoringService == 'Port Monitoring'){
      //   newTemporaryProducts.push({
      //     product: 'Port Monitoring',
      //     variation: 'Standard',
      //     count: 1,
      //     monitoringService: this.availableProducts.monitoringService
      //   })
      // }
      let monitoringService = this.availableProducts.monitoringService;

      if (monitoringService) {
        newTemporaryProducts.push({
          product: "Monitoring Service",
          variation: monitoringService,
          count: 1,
          monitoringService: monitoringService,
        });
      }
      if (newTemporaryProducts.find(product => (product.product === 'Late Arrival'))) {



        newTemporaryProducts.find(product => (product.product === 'Late Arrival')).variation = this.lateDescription
        
    
      }

      if (newTemporaryProducts.find(product =>(product.product === 'Early Arrival'))) {


newTemporaryProducts.find(product => (product.product === 'Early Arrival')).variation = this.earlyDescription


}
      this.container.temporaryProducts = [...newTemporaryProducts]
      this.saveProducts = false
      this.temporaryProductModal = false
    },
    updateAvailableDevices(temporaryProducts) {
      temporaryProducts.forEach(tempProduct => {
        const devices = this.availableDevices.find(device =>
          device.product === tempProduct.product &&
          device.variation === tempProduct.variation
        );

        if (devices) {
          devices.count = tempProduct.count;
          devices.checked = true;
          devices.monitoringService = tempProduct.monitoringService
        }

        if (tempProduct.monitoringService) {
          this.availableProducts.monitoringService = tempProduct.monitoringService
        }
      });
    },
    updateAvailibleProducts(temporaryProduct) {
      temporaryProduct.forEach(tempProduct => {
        const products = Object.keys(this.availableProducts).find(product =>
          product.replace(/_/g, " ") === tempProduct.product
        )
        if (products) {
          this.availableProducts[products] = true
        }
      })
    },
    updateMonitoringService(temporaryProduct) {
      console.log("Updating monitoring service", temporaryProduct)
      temporaryProduct.forEach(tempProduct => {
        const products = tempProduct.product === "Monitoring Service"
        if (products) {
          this.availableProducts.monitoringService = tempProduct.variation
        }
      })
    },

    updateLarDescription(temporaryProduct) {
      temporaryProduct.forEach(tempProduct => {
        const early = tempProduct.product === 'Early Arrival'
        const late = tempProduct.product === 'Late Arrival'
        if (late) {
          this.lateDescription = tempProduct.variation
    
        }

        else if(early){
          this.earlyDescription = tempProduct.variation
        }
      })
    },
    toggleProducts(choice, type) {
      // console.log("chouice", choice);
      // console.log("type", type);
      // console.log("this.availableProducts start", this.availableProducts);

      //change all spaces in my string to underscores
      type = type.replace(/ /g, "_");
      if (choice === "YES") {
        if (
          !Object.hasOwn(this.availableProducts, type) ||
          this.availableProducts[type] === false
        ) {
          this.availableProducts[type] = true;
        } else {
          delete this.availableProducts[type];
        }
        this.gensetToggleKey++;
        this.otflowKey++;
        this.zoonoKey++;
        this.industrialKey++;
        this.portMonitoringKey++;
        this.voidPlugKey++;
        this.lateArrivalKey++;
        this.earlyArrivalKeyA++;
     
      }
      if (choice === "NO") {
        // console.log('inside no')
        if (
          !Object.hasOwn(this.availableProducts, type) ||
          this.availableProducts[type] === true
        ) {
          this.availableProducts[type] = false;
        } else {
          delete this.availableProducts[type];
        }
        // console.log('this.availableProducts end', this.availableProducts)
        this.gensetToggleKey++;
        this.otflowKey++;
        this.zoonoKey++;
        this.industrialKey++;
        this.portMonitoringKey++;
        this.voidPlugKey++;
        this.lateArrivalKey++;
        this.earlyArrivalKeyA++;
      }
    },
    closeTemporaryProductModal() {
      this.temporaryProductModal = false;
    },
    mapTemporaryProducts(temporaryProduct) {
      let devicesList = 'Devices Required:\n'
      return temporaryProduct.reduce((acc, product) => {
        const existingProduct = acc.find(
          (accProduct) => accProduct.product === product.product
        );
        if (!existingProduct) {
          const view = this.temporaryProductsView.find(
            (view) => view.name === product.product
          );
          if (view) {
            if (view.name == 'TelemPlus') {
              devicesList += '• ' + product.count + 'X' + product.variation + '\n'
              // const existingDevice = acc.find(
              //   (accProduct) => accProduct.product === 'Devices Required'
              // );
              // if(!existingDevice){
              // acc.push({
              //   product: 'Devices Required',
              //   variation: product.variation,
              //   icon: view.icon,
              //   color: view.color,
              // })
              // }
            }
            else if (view.name === 'SteriInsurance') {
              acc.push({
                product: 'Steri Insurance' + ' ' + 'Required',
                icon: view.icon,
                color: view.color,

              })
            }
            else if (view.name === 'SmartFresh') {
              acc.push({
                product: 'Smart Fresh' + ' ' + 'Required',
                icon: view.icon,
                color: view.color,

              })
            }
            else if (view.name === 'ContainerLiners') {
              acc.push({
                product: 'Container Liners' + ' ' + 'Required',
                icon: view.icon,
                color: view.color,

              })
            }
            else if (view.name == 'Early Arrival') {
              acc.push({
                product: product.product + ' ' + 'Required' + ' : ' + product.variation,
                icon: view.icon,
                color: view.color,
              })
            }
            else if (view.name == 'Late Arrival') {
              acc.push({
                product: product.product + ' ' + 'Required' + ' : ' + product.variation,
                icon: view.icon,
                color: view.color,
              })
            }
            else {
              acc.push({
                product: product.product + ' ' + 'Required',
                icon: view.icon,
                color: view.color,
              });
            }
          }

        }

        //check if there is a monitoring service against one of our TelemPlus products
        if (product.product === 'Monitoring Service' && product.variation) {

          acc.push({
            product: product.variation + ' ' + 'Service',
            variation: product.monitoringService,
            icon: "desktop_windows",
            color: "#99B080"
          })
        }
        if (devicesList.length > 18) {
          //inside my acc check if there is entry with varaition none
          const existingDevice = acc.find(
            (accProduct) => accProduct.variation == 'None')
          if (!existingDevice) {
            acc.push({
              product: devicesList,
              variation: 'None',
              icon: "router",
              color: "green",
            })
          }
          else {
            //remove the existing entry and replace with new one
            acc.splice(acc.indexOf(existingDevice), 1)
            acc.push({
              product: devicesList,
              variation: 'None',
              icon: "router",
              color: "green",
            })
          }
        }
        return acc;
      }, []);
    },
    editTemporaryProducts(container) {
      console.log('container', container.temporaryProducts)

      this.temporaryProductModal = true;
    },
    addMilestone(item, type = null, autosave = true) {
      if (type && item[type]) {
        this.milestoneItem = item[type];
        this.milestoneItem.autosave = autosave;
        this.milestoneContainer = item;
      } else {
        this.milestoneItem = {
          type,
          bookingContainerId: item.id,
          pol: this.booking.portOfLoadValue,
          // date: new Date(),
          autosave,
          isActual: false,
        };
        this.milestoneContainer = item;
      }

      this.addMilestoneModal = true;
      this.$nextTick(() => {
        this.milestoneKey++;
      });
    },
    addContainerProduct(item) {
      this.item = item;
      this.item.selectedProduct = {};
      let findContainerType = this.containerTypes.find(
        (x) => x.typeCode == this.container.containerTypeCode
      );
      if(findContainerType){
        this.item.selectedProduct.volume = findContainerType.teu * 25;
      }

      this.containerProductModal = true;
    },
    async larForm() {
      try {
        this.larFormModal = true;
      }
      catch (e) {
        this.$message.error("An error ocurred", e);
      }

    },
    async earlyArrivalForm() {
      try {
        this.earlyArrivalModal = true;
      }
      catch (e) {
        this.$message.error("An error ocurred", e);
      }

    },
    async generateLAR(id) {
      try {
        this.loadingLAR = true;
        let obj = {
          containerId: id,
          reasonForLAR: this.reasonForLAR ? this.reasonForLAR : '',
          arrivalDate: this.arrivalDate ? this.arrivalDate + ' ' + this.arrivalTime : '',
          containerSize: this.containerSize,
          containerType: this.containerType,
          // movementType:this.container
        }
        let result = await this.$API.generateLAR(obj);
        this.container.larDocument = result;
        this.container.larDocumenId = result.id;

        this.larKey++
        this.larFormModal = false
        this.loadingLAR = false;
      }
      catch (e) {
        this.$message.error("An error ocurred", e);
        this.loadingLAR = false;
      }
    },
    async generateEarlyArrival(id) {
      try {
        this.loadingEAR = true;
        let obj = {
          containerId: id,
          reasonForEAR: this.reasonForEarlyArrival ? this.reasonForEarlyArrival : '',
          arrivalDate: this.arrivalDate ? this.arrivalDate + ' ' + this.arrivalTime : '',
          containerSize: this.containerSize,
          containerType: this.containerType,
        }
        let result = await this.$API.generateEarlyArrival(obj);
        this.container.earlyArrivalDocument = result;
        this.container.earlyArrivalDocumentId = result.id;

        this.earlyArrivalKey++
        this.earlyArrivalModal = false
        this.loadingEAR = false;
      }
      catch (e) {
        this.$message.error("An error ocurred", e);
        this.loadingLAR = false;
      }
    },
    
    async generatePackingList(id) {
      try {
        this.loadingPackingList = true
        // console.log('packing list container', id);
        let obj = {
          containerIds: [id],
          movementType: this.booking.movementType,
          specialInstructions: this.specialInstructions,
        };
        // console.log('obj', obj)
        let result = await this.$API.generatePackingList(obj);

        // console.log(result);

        if(result && result.length === 1){
          this.container.packingListDocument.url = result[0].url;
          this.container.packingListDocument.name = result[0].name;
          this.container.packingListDocument.version++;
          this.container.packingListDocumentId = result[0].id;
        }

        this.specialInstructions = '';
        this.loadingPackingList = false;
        this.packingListKey++
      } catch (e) {
        this.$message.error("An error ocurred :(");
        this.loadingCto = false;
      }
    },
    async generateCTO(id) {
      try {
        console.log('contauiner', this.booking)
        this.loadingCto = true;
        let obj = {
          containerId: id,
          movementType: this.booking.movementType
        };
        let result = await this.$API.generateCTO(obj);
        if(result.error){
          this.$message.error(result.error)
          this.loadingCto = false;
          this.ctoKey++

          return
        }
        else{
          this.container.ctoDocument = result;
          this.container.ctoDocumentId = result.id;
          this.loadingCto = false;
          this.ctoKey++

        }
      } catch (e) {
        this.$message.error("An error ocurred :(");
        this.loadingCto = false;
      }
    },
    editContainerProduct(item, product) {
      this.item = item;
      this.item.selectedProduct = product;
      this.containerProductModal = true;
    },
    async getContainerDetails() {
      this.loadingDetails = true;
      let body = {
        regimeCode: this.booking.regimeCode,
        containerId: this.container.id,
        bookingId: this.booking.id,
        regimeId: this.booking.regimeId,
      };
      let result = await this.$API.getContainer(body.containerId);
      if (result) {
        this.regime = result.regime;
        this.milestones = result.containerMilestones;
        this.legs = result.legs ?? [];
        if (this.legs.length > 0) {
          this.center = this.legs[0].start;
          let findLoad = this.legs.findIndex(
            (leg) => leg.start.type == "LOADING_POINT_1"
          );
          let endLoad = this.legs.findIndex(
            (leg) => leg.start.type == "FULL_DROP_OFF"
          );
          if (findLoad > -1 && endLoad > -1) {
            let loadedLegs = this.legs.slice(findLoad, endLoad + 1);
            this.loadedDistance = 0;
            loadedLegs.forEach((leg) => {
              this.loadedDistance += parseFloat(leg.distance);
            });
            this.loadedDistance = this.loadedDistance.toFixed(2);
          }
        }
      }
      this.key++;
      this.loadingDetails = false;
    },
    async getContainerComments(containerId) {
      this.loadingComments = true;
      let result = await this.$API.getContainerComments(containerId);
      if (result) {
        this.containerComments = result;

        this.loadingComments = false;
        this.$nextTick(() => {
          let objDiv = document.getElementById("messagesContainer");
          if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        });
      }
    },
    async getVentSettings() {
      if (this.booking.regimeId) {
        let ventSettings = await this.$API.getRegimeVent(this.booking.regimeId)
        if (ventSettings.length > 0) {
          this.ventSettings = [
            "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ].filter(x => ventSettings.map(y => y.ventCode).includes(x))
        } else {
          this.ventSettings = [
            "Closed",
            "5",
            "10",
            "15",
            "20",
            "25",
            "28",
            "30",
            "35",
            "40",
            "50",
            "Fully Open",
            "CA",
            "MA",
            "Auto",
          ]
        }
      } else {
        this.ventSettings = [
          "Closed",
          "5",
          "10",
          "15",
          "20",
          "25",
          "28",
          "30",
          "35",
          "40",
          "50",
          "Fully Open",
          "CA",
          "MA",
          "Auto",
        ]
      }
    },
    async deleteContainerComment(id){
    this.loadingDeleteComment = true;
     let result =  await this.$API.deleteContainerComment({
          id: id,
          isDeleted: true,
          isActive: false,
          bookingContainerId : this.container.id
        });

        if(result){
          this.loadingDeleteComment = false;
          this.getContainerComments(this.container.id)
          this.containerComments = result
        }
    },
    async updateContainerComment(id){
      this.loadingEditComment = true;
     let result =  await this.$API.updateContainerComment({
          id: id,
          isDeleted: true,
          isActive: false,
          bookingContainerId : this.container.id,
          organisationId: this.newEditedComment.organisationId,
          userId : this.newEditedComment.userId,
          comment:this.newEditedComment.comment
        });

        if(result){
          this.loadingEditComment = false;
          this.containerComments = result;
          this.closeEditDialog();
          this.getContainerComments(this.container.id)
        }
    },
    async commentContainer() {
      if (this.containerChatArea != "") {
        this.containerChatArea.trim();
        this.chatContainerUpdateKey++;
        // check if last 2 characters are not spaces or new line characters
        this.sendingMessage = true;
        let obj = {
          comment: this.containerChatArea,
          bookingContainerId: this.container.id,
          user: this.$store.state.user,
        };
        let result = await this.$API.commentOnContainer(obj);
        if (result) {
          result.user = this.$store.state.user;
          this.containerComments.push(result);
          this.containerChatArea = "";
          this.sendingMessage = false;
        } else {
          this.$message({
            type: "error",
            message: `Message could not go through. Please check your connection and try again.`,
          });
        }
      }
    },
    formatDate(date) {
      let isoFormat = null;
      let fullDate = null;
      let dateTime = null;
      let dayOfWeek = null;
      let shorterDate = null;
      let time = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "dd-mm-yyyy");
        fullDate = dateFormat(new Date(date), "dddd, mmmm dS, yyyy");
        dateTime = dateFormat(new Date(date), "dddd, mmmm dS, yyyy HH:MM");
        time = dateFormat(new Date(date), "HH:MM");
        dayOfWeek = dateFormat(new Date(date), "ddd");
        shorterDate = dateFormat(new Date(date), "mmm dS, yyyy");
      }
      return { isoFormat, fullDate, dateTime, dayOfWeek, shorterDate, time };
    },
    formatDateTime(date, time) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
        if (time) {
          result += ' ' + time
        }
      }
      return result;
    },
    milestoneType(type) {
      let find = this.milestoneTypes.find((x) => x.value == type);
      return find ? find.name : null;
    },
    checkContainer(value) {
      if(isContainer(value)){
        return true
      }
      else{
        return false
      }
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async getContractors() {
      this.loadingContractors = true;
      let result = await this.$API.getContractors();
      if (result) {
        this.contractors = result;
        this.loadingContractors = false;
      }
    },
    getStatusColor(status) {
      let find = this.transportStatuses.find((x) => x.text == status);
      return find ? find.color : "grey";
    },
    containerStatusColor(item) {
      if (item.isDeleted) {
        return "red";
      } else if (item.bookingId && item.bookingId != this.booking.id) {
        return "blue";
      } else if (item.status) {
        let find = this.containerStatus.find(
          (x) => x.name.toLowerCase() == item.status.toLowerCase()
        );
        if (find) {
          return find.color;
        } else {
          return "orange darken-2";
        }
      } else {
        return "orange darken-2";
      }
    },
    matchColor(itemStatus) {
      let hex = "";
      let result = this.statuses.find((status) => status.state === itemStatus);
      if (result) {
        hex = result.color;
      }
      return hex;
    },
    closeDialog() {
      this.change = false;
      this.milestones = [];
      this.legs = [];
      this.regime = null;
      this.availableDevices = [
      {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        monitoringService: "",
        checked: false,
      },
        {
          product: "TelemPlus",
          variation: "2G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            monitoringService: "",
            checked: false,
          },
        // {
        //   product: "TelemPlus",
        //   variation: "3G GEO",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "T4",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "RF",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "GEO EAGLE",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "MOST",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "5G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Dunnage Bags",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Blast Freezing",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Kraft paper",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Absorb Bags",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Stabling bars",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Latches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ratches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product:'Ethylene Filters',
          variation:'Standard',
          count:0,
          checked:false

        }
      ],
        this.availableProducts = {
          Genset: false,
          Otflow: false,
          Zoono: false,
          // Dunnage_Bags: false,
          Void_Plugs: false,
          Late_Arrival: false,
          Early_Arrival: false,
          Fumigation: false,
          ContainerLiners: false,
          monitoringService: null,
          SteriInsurance: false,
          SmartFresh: false,
          HeavyLoad: false,

        },
        this.$emit("close");
    },
    setChange() {
      this.change = true;
    },
    async updateContainer() {
      this.savingChange = true;
      if (this.dataSourceChange) { await this.updateDataSource(); }
      let status = this.container.transportStatus;
      console.log('this.container being updated', this.container)
      await this.$API.updateContainer(this.container);
      this.$message.success("Successfully updated!");
      this.savingChange = false;
      this.availableDevices = [
      {
        product: "TelemPlus",
        variation: "Escavox",
        count: 0,
        monitoringService: "",
        checked: false,
      },
        {
          product: "TelemPlus",
          variation: "2G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
            product: "TelemPlus",
            variation: "4G PROBE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
          {
            product: "TelemPlus",
            variation: "STD PROBE",
            count: 0,
            // monitoringService: "",
            checked: false,
          },
        // {
        //   product: "TelemPlus",
        //   variation: "3G GEO",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "T4",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "RF",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "4G GEO",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        // {
        //   product: "TelemPlus",
        //   variation: "GEO EAGLE",
        //   count: 0,
        //   monitoringService: "",
        //   checked: false,
        // },
        {
          product: "TelemPlus",
          variation: "MOST",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "2G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "TelemPlus",
          variation: "5G TIVE",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Dunnage Bags",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Blast Freezing",
          variation: "Standard",
          count: 0,
          monitoringService: "",
          checked: false,
        },
        {
          product: "Kraft paper",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Absorb Bags",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Stabling bars",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Latches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
        {
          product: "Ratches",
          variation: "Standard",
          count: 0,
          // monitoringService: "",
          checked: false,
        },
      ],
        this.availableProducts = {
          Genset: false,
          Otflow: false,
          Zoono: false,
          // Dunnage_Bags: false,
          Void_Plugs: false,
          Late_Arrival: false,
          Early_Arrival: false,
          Fumigation: false,
          monitoringService: null,
          ContainerLiners: false,
          SteriInsurance: false,
          SmartFresh: false,
          HeavyLoad: false,

        },
        this.closeDialog();
    },
    setSelectableParties() {
      let keys = []
      if (this.booking.movementType == 'EXPORT') {
        keys = [
          "forwarder",
          "shipper",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      }
      else {
        keys = [
          "forwarder",
          "consignee",
          "shipper",
          "buyer",
          "firstNotifyParty",
          "secondNotifyParty",
        ];
      }
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        if (this.booking[keys[i] + "Id"]) {
          result.push(this.booking[keys[i]]);
        }
      }
      if (this.booking.customerId) {
        let findCustomer = result.find((x) => x.id == this.booking.customerId);
        if (!findCustomer) {
          result.unshift(this.booking.customer);
        }
      }
      let findCurrentOrg = result.find(
        (x) => x.id == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        result.unshift(this.$store.state.currentOrg);
      }
      this.selectableParties = result;
    },
    setTransporter() {
      if (this.container.transportCoordinator) {
        this.container.transportCoordinatorId =
          this.container.transportCoordinator.id;
      } else {
        this.container.transportCoordinatorId = null;
      }
      //   if (
      //     this.booking.transportCoordinatorId == this.$store.state.currentOrg.id &&
      //     this.booking.movementType == "EXPORT"
      //   ) {
      //     this.booking.preAdvise = true;
      //   }
    },
    milestoneUpdate(data) {
      this.milestoneContainer.containerMilestones = data;
      console.log("Milestones", data)
      let types = [
        {
          name: 'Empty Pickup',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'EMPTY_PICKUP',
          type: ['EXPORT']
        },
        {
          name: 'Full Pickup',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'FULL_PICKUP',
          type: ['IMPORT']
        },
        {
          name: 'Stuff Date',
          eventType: 'EQUIPMENT',
          modeOfTransport: 'TRUCK',
          value: 'STUFF_DATE',
          type: ['EXPORT']
        },
        {
          name: 'Loading Point 1',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'LOADING_POINT_1',
          type: ['EXPORT']
        },
        {
          name: 'Loading Point 2',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'LOADING_POINT_2',
          type: ['EXPORT']
        },
        {
          name: 'Delivery Point 1',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'DELIVERY_POINT_1',
          type: ['IMPORT']
        },
        {
          name: 'Delivery Point 2',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'DELIVERY_POINT_2',
          type: ['IMPORT']
        },
        {
          name: 'Delivery Point 3',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'DELIVERY_POINT_3',
          type: ['IMPORT']
        },
        {
          name: 'Weigh Bridge',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'WEIGH_BRIDGE',
          type: ['EXPORT']
        },
        {
          name: 'Plugged In',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'PLUG_IN',
          type: ['EXPORT']
        },
        {
          name: 'Unplugged',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'PLUG_OUT',
          type: ['EXPORT']
        },
        {
          name: 'Port Holding',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'FULL_DROP_OFF',
          type: ['EXPORT']
        },
        {
          name: 'Drop Off Empty',
          eventType: 'TRANSPORT',
          modeOfTransport: 'TRUCK',
          value: 'EMPTY_DROP_OFF',
          type: ['IMPORT']
        }
      ]
      for (let point of types) {
        let find = data.find(x => x.type == point.value)
        if (find) {
          this.container[point.value] = find
        } else {
          this.container[point.value] = null
        }
      }
      this.addMilestoneModal = false;
      this.milestoneKey++;
    },
    async deleteSelectedContainerProduct() {
      let find = this.item.containerProducts.findIndex(
        (x) => x.id == this.item.selectedProduct.id
      );
      if (find > -1) {
        this.item.containerProducts.splice(find, 1);
      }
      this.containerProductModal = false;
      this.$message.success("Successfully removed product!");
    },
    async updateSelectedContainerProduct(item) {
      this.containerProductModal = false;
      if (item.hazardous) {
        this.booking.isHazardous = true;
        this.bookingChange = true;
      } else {
        this.booking.isHazardous = this.booking.bookingContainers
          .filter(
            (x) =>
              !x.isDeleted &&
              (x.status == "Allocated" || x.status == null) &&
              x.bookingId == this.booking.id
          )
          .some((x) => x.containerProducts.some((y) => y.hazardous == true));
      }
      if (item.id) {
        await this.$API.updateContainerProduct(item);

        let findIndex = this.container.containerProducts.findIndex(x => x.id == item.id)
        this.container.containerProducts[findIndex] = item
        //get all container.containerProducts.pallets and add together to get bookingContainer.totalPallets
        console.log('container.containerProducts', this.container.containerProducts)
        let totalPallets = 0
        this.container.containerProducts.forEach(product => {
          totalPallets += parseInt(product.pallets)
        })
        this.container.totalPallets = totalPallets
      } else {
        item = {
          ...item,
          bookingId: this.booking.id,
          bookingContainerId: this.item.id,
          productId: item.product.id,
        };
        let result = await this.$API.createContainerProduct(item);
        result = { ...result, ...item }
        this.container.containerProducts.push(result)
        // this.contain
      }
      this.item = null;
      this.$message.success("Successfully updated container product!");
      this.$emit('refreshContainers')
    },

    viewPackingList(item) {
      this.item = item;
      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    viewCTO(item) {
      this.item = item;

      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    setHaulier(haulier) {
      let types = ["isPrivateHaulage", "isCarrierHaulage", "isRailage"];
      if (haulier.flag) {
        let setFalse = types.filter((x) => x != haulier.flag);
        for (let i = 0; i < setFalse.length; i++) {
          this.container[setFalse[i]] = false;
        }
        this.container[haulier.flag] = true;
        this.container.transportCoordinator = null;
        this.container.transportCoordinatorId = null;
      } else {
        for (let i = 0; i < types.length; i++) {
          this.container[types[i]] = false;
        }
        this.container.transportCoordinator = haulier;
        this.container.transportCoordinatorId = haulier.id;
        // this.booking.preAdvise = true
      }
      this.haulierKey++;
      // this.haulierModal = false
    },
    updateContainerDescription() {
      let find = this.containerTypes.find(x => x.typeCode == this.container.containerTypeCode)
      if (find) {
        this.container.containerType = find.shortDescription
      }
    },
    // DATA SOURCES
    async updateDataSource() {
      try {
        this.savingDataSource = true;
        let active = this.availableSources.filter((x) => x.active);
        console.log(active);
        let obj = active.map((x) => ({
          source: x.type,
          type: x.type,
          id: x.id,
          sourceType: x.sourceType,
          contents:
            x.sourceType == "stockFileContainer"
              ? x.stockFileContainers[0].contents
              : x.contents,
        }));
        await this.$API.updateDocumentSource({
          bookingContainerId: this.container.id,
          source: obj,
        });
        this.dataSourceChange = false;
        this.savingDataSource = false;
        // this.$emit("refreshContainers");
        // this.load();
        this.$message.success("Successfully updated data source!");
      } catch (e) {
        console.log(e);
        this.$message({
          type: "error",
          message: "There was an error changing the document source.",
        });
        this.savingDataSource = false;
      }
      // });
    },
    checkDataSourceChanges() {
      this.dataSourceChange = false;
      for (let i = 0; i < this.availableSources.length; i++) {
        let find = this.activeSources.findIndex(
          (x) => x.stockFileId == this.availableSources[i].id
        );
        if (this.availableSources[i].active && find == -1) {
          this.dataSourceChange = true;
          break;
        } else if (!this.availableSources[i].active && find > -1) {
          this.dataSourceChange = true;
          break;
        }
      }
      this.dataSourceKey++;
    },
    async getAvailableSources() {
      this.loadingDataSource = true;
      let result = await this.$API.getAvailableStockSources(this.container.id);
      this.activeSources = await this.$API.getActiveStockSources(this.container.id);
      for (let i = 0; i < result.length; i++) {
        let find = this.activeSources.find(
          (x) => x.source == result[i].type && x.stockFileId == result[i].id
        );
        result[i].active = find ? true : false;
        if (result[i].sourceType == "stockFile") {
          if (typeof result[i].contents == "string") {
            let data = await axios.get(result[i].contents);
            if (data && data.data) {
              result[i].contents = data.data;
            }
          }
        } else if (result[i].sourceType == "stockFileContainer") {
          for (let j = 0; j < result[i].stockFileContainers.length; j++) {
            if (typeof result[i].stockFileContainers[j].contents == "string") {
              let data = await axios.get(
                result[i].stockFileContainers[j].contents
              );
              if (data && data.data) {
                result[i].stockFileContainers[j].contents = data.data;
              }
            }
          }
        }
      }
      this.availableSources = result;
      this.loadingDataSource = false;
    },
    async updatePalletWeights() {
      this.updatingPallets = true
      let res = await this.$API.updatePalletWeights({id: this.container.id, vgmWeight: this.container.vgmWeight, tareWeight: this.container.tareWeight});
      if( res && res.grossWeight ){ this.container.grossWeight = res.grossWeight }
      this.$message.success("Successfully updated weights!");
      this.updatingPallets = false
    }
    // matchLocation(itemStatus) {
    //     let result = this.statusLocations.find(status => status.name === itemStatus).value
    //     return result
    // },
  },
};
</script>
  
<style scoped>
.localClass .v-timeline-item__body {
  --timeline-right-slot: 27vw;
  min-width: var(--timeline-right-slot - 30%);
  max-width: var(--timeline-right-slot);
  width: var(--timeline-right-slot);
}

.localClass.v-timeline--dense ::v-deep .v-timeline-item__opposite {
  --timeline-left-slot: 9vw;
  display: inline-block;
  text-align: end;
  flex: none;
  width: var(--timeline-left-slot);
  min-width: var(--timeline-left-slot);
  max-width: var(--timeline-left-slot);
}

.v-application--is-ltr .localClass.v-timeline--dense:not(.v-timeline--reverse)::before,
.v-application--is-rtl .v-timeline--reverse.v-timeline--dense::before {
  left: calc(9vw + ((60px - 2px) / 2));
  width: 2px;
  height: 200%;
  right: auto;
}

.localClass ::v-deep .v-timeline-item__divider {
  min-width: 60px;
}

.map-marker::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  /* top: -5px; */
  bottom: -20px;
  left: 10px;
  border: 10px solid transparent;
  border-top: 17px solid black;
}
</style>