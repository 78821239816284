<template>
<div>
    <v-card rounded="0" flat>
        <v-container style="max-width: 100vw">
            <template v-if="loadingData">
                <valhalla-loading height="100vh" />
            </template>
            <template v-else-if="validData">
                <!-- Container Info -->
                <v-sheet class="pa-4" rounded>
                    <v-row>
                        <v-col>
                            <h2>Container Metrics</h2>
                        </v-col>
                        <v-col v-if="debugViewEnabled" cols="auto">
                            <v-chip class="font-weight-bold">{{ entity.containerNo }}</v-chip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="8" align-self="center">
                            <div>
                                <v-chip class="mr-2 pl-1" v-if="latestPositionEvent">
                                    <v-chip small color="blue" class="mr-2">{{ latestPositionEvent.positionEvent }}</v-chip>
                                    {{ latestLocationEventDateFormatted }}
                                </v-chip>
                                <v-chip class="mr-2 pl-1">
                                    <v-chip small color="grey" class="mr-2">Latest</v-chip>
                                    {{ latestEventDateFormatted }}
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" align-self="center">
                            <v-select label="Metrics" :items="['Temperature']" mandatory :value="'Temperature'" chips dense hide-details></v-select>
                        </v-col>
                    </v-row>

                    <!-- Graph -->
                    <v-row>
                        <v-col cols="12">
                            <v-chip class="font-weight-bold" outlined>Graph</v-chip>
                        </v-col>
                        <v-col cols="12">
                            <DynamicSmoothLineChart class="dynamicChart" :data="graphData.series" :key="5000" :labels="graphData.labels" :textColor="'grey'" />
                        </v-col>
                    </v-row>

                    <!-- Table -->
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="8" align-self="end">
                                    <v-chip class="font-weight-bold" outlined>Table</v-chip>
                                </v-col>
                                <v-col v-if="isPositionEventsAvailable" cols="4" class="d-flex justify-end">
                                    <v-switch dense hide-details label="Positions Events Only" v-model="positionEventsOnly"></v-switch>
                                </v-col>
                                <v-col cols="4" class="d-flex justify-end">
                                    <v-btn outlined text @click="downloadDataExcel">Download</v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table :items="tableData" :headers="tableHeaders">
                                <template v-slot:item.positionEvent="{ item }">
                                    <v-chip dark v-if="item.positionEvent">
                                        {{ item.positionEvent }}
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-sheet>
            </template>
            <template v-else>
                <div class="text-center pa-4">No tracking metrics data</div>
                <div class="text-center pa-4">
                    <v-btn outlined @click="viewDebugDialog">View Debug Data</v-btn>
                </div>
            </template>
        </v-container>

        <v-card-actions dark>
            <v-spacer></v-spacer>
            <v-btn text @click="downloadDataExcel">Download</v-btn>
            <v-btn text @click="$emit('close')">Close</v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
import {
    CONTAINER_TRACKING_INTEGRATION_CODES,
    SI_TEST_USERS_IDS
} from '@/utils/constants';
import DynamicSmoothLineChart from '@/components/Charts/DynamicSmoothLineChart.vue'
import moment from "moment";
import * as XLSX from "xlsx";

const EVENT_DATE_FORMAT = "DD MMM YYYY, hh:mm a"

//NOTE: Container tracking events
const CODES = {
    GOMT: 'Gated out Empty',
    GIFU: 'Gated in Full',
    LOFU: 'Loaded on Vessel',
    DIFU: 'Discharged from Vessel',
    GOFU: 'Gated out Full',
    GIMT: 'Gated in Empty'
}

export default {
    components: {
        DynamicSmoothLineChart
    },

    props: {
        referenceData: {
            type: Object,
            default: () => {}
        },

        regimeCode: {
            type: String
        }
    },

    data() {
        return {
            loadingData: false,
            resultData: null,
            debugDialog: false,
            debugViewEnabled: true,
            isPositionEventsAvailable: false,
            isPowerEventsAvailable: false,
            jsonDebugHeight: 400,
            debugTabs: 0,
            positionEventsOnly: false,
            tableHeaders: [{
                    text: 'Time',
                    value: 'dateFormatted'
                },
                {
                    text: 'Power Events',
                    value: 'powerEvents',
                    sortable: false
                },
                {
                    text: 'Supply Air (min|max|avg)',
                    value: 'supplyAirTemperatureDisplay',
                    sortable: false
                },
                {
                    text: 'Return Air (min|max|avg)',
                    value: 'returnAirTemperatureDisplay',
                    sortable: false
                },
                {
                    text: 'Temp Set Point (min|max|avg)',
                    value: 'temperatureSetpointDisplay',
                    sortable: false
                },
                {
                    text: `Booking's Temp Set Point (min|max|avg)`,
                    value: 'bookingTemperatureSetpointDisplay',
                    sortable: false
                },
            ],
            regimeRules: {}
        }
    },

    computed: {
        entity() {
            if (!this.resultData || !this.resultData.containerData || Object.keys(this.resultData.containerData.metrics).length === 0) return null;

            return this.resultData.containerData;
        },

        validData() {
            return (this.resultData && this.entity);
        },

        jsonViewportHeight() {
            return this.jsonDebugHeight;
        },

        latestEventDateFormatted() {
            if (this.tableData.length === 0) return "-";
            const event = this.tableData[this.tableData.length - 1];

            return event.dateFormatted;
        },

        latestLocationEventDateFormatted() {
            if (!this.latestPositionEvent) return "";
            return this.latestPositionEvent.dateFormatted
        },

        latestPositionEvent() {
            const events = this.tableData.filter(x => x.positionEvent);
            if (!events || !events.length) return null;
            return events[events.length - 1];
        },

        tableData() {
            const dataPoints = [];

            const setValue = (value) => value || '-';

            const bookingSetPoint = {
                min: this.regimeRules.minProductTemp,
                max: this.regimeRules.maxProductTemp,
                avg: ((this.regimeRules.minProductTemp + this.regimeRules.maxProductTemp) / 2).toFixed(2)
            }

            if (this.entity.metrics.reeferCargoContainerEvents.length === 0) {
                let bookingTemperatureSetpointDisplay = `${setValue(bookingSetPoint.min)} | ${setValue(bookingSetPoint.max)} | ${setValue(bookingSetPoint.avg)}`
                dataPoints.push({
                    timestamp: "2024-02-01 05:32:07.000000000",
                    dateFormatted: moment("2024-02-01 05:32:07.000000000").format(EVENT_DATE_FORMAT),
                    powerEvents: "-",
                    returnAirTemperatureDisplay: setValue(null),
                    supplyAirTemperatureDisplay: setValue(null),
                    temperatureSetpointDisplay: setValue(null),
                    bookingTemperatureSetpointDisplay: `${setValue(bookingSetPoint.min)} | ${setValue(bookingSetPoint.max)} | ${setValue(bookingSetPoint.avg)}`,
                    supplyAirTemperature: {
                        min: 0.0,
                        max: 0.0,
                        avg: 0.0
                    },
                    returnAirTemperature: {
                        min: 0.0,
                        max: 0.0,
                        avg: 0.0
                    },
                    temperatureSetpoint: {
                        min: 0.0,
                        max: 0.0,
                        avg: 0.0
                    },
                    bookingTemperatureSetpoint: bookingSetPoint,
                });
            }

            for (const point of this.entity.metrics.reeferCargoContainerEvents) {
                let powerEvents = point.power.length > 0 ? point.power.join(', ') : '-';
                let returnAirTemperatureDisplay = `${setValue(point.returnAir.min)} | ${setValue(point.returnAir.max)} | ${setValue(point.returnAir.avg)}`
                let supplyAirTemperatureDisplay = `${setValue(point.supplyAir.min)} | ${setValue(point.supplyAir.max)} | ${setValue(point.supplyAir.avg)}`
                let temperatureSetpointDisplay = `${setValue(point.setPointTemperature.min)} | ${setValue(point.setPointTemperature.max)} | ${setValue(point.setPointTemperature.avg)}`
                let bookingTemperatureSetpointDisplay = `${setValue(bookingSetPoint.min)} | ${setValue(bookingSetPoint.max)} | ${setValue(bookingSetPoint.avg)}`

                dataPoints.push({
                    timestamp: point.timestamp,
                    dateFormatted: moment(point.timestamp).format(EVENT_DATE_FORMAT),
                    powerEvents,
                    returnAirTemperatureDisplay,
                    supplyAirTemperatureDisplay,
                    temperatureSetpointDisplay,
                    bookingTemperatureSetpointDisplay,
                    supplyAirTemperature: point.supplyAir,
                    returnAirTemperature: point.returnAir,
                    temperatureSetpoint: point.setPointTemperature,
                    bookingTemperatureSetpoint: bookingSetPoint,
                });
            }

            return dataPoints;
        },

        graphData() {
            const data = this.tableData;
            const labels = [];

            const series = {
                supplyAir: {
                    data: [],
                    type: 'line',
                    showInLegend: true,
                    color: '#2c9de8',
                    name: 'Supply Air',
                },
                returnAir: {
                    data: [],
                    type: 'line',
                    showInLegend: true,
                    color: '#8fcc16',
                    name: 'Return Air',
                    smooth: true
                },
                setPoint: {
                    data: [],
                    type: 'line',
                    showInLegend: true,
                    color: '#e86123',
                    name: 'Set Point'
                },
                bookingSetPoint: {
                    data: [],
                    type: 'line',
                    showInLegend: true,
                    color: '#ae0606',
                    name: `Booking's Set Point`,
                    markArea: {
                        data: [
                            [{
                                    name: `Booking's Set Point Range`,
                                    yAxis: -0.5,
                                    itemStyle: {
                                        color: '#44749c',
                                        opacity: 0.2
                                    },
                                },
                                {
                                    name: `Booking's Set Point Range`,
                                    yAxis: -0.1
                                }
                            ],
                        ]
                    },
                },
                positionEvents: {
                    data: [],
                    type: 'line',
                    silent: true,
                    label: {
                        show: false
                    },
                    showInLegend: false,
                    tooltip: {
                        show: false
                    },
                    markLine: {
                        symbol: ['none', 'none'],
                        data: [],
                        label: {
                            backgroundColor: "#555555",
                            padding: [4, 8],
                            borderRadius: 10,
                            color: "#ffffff",
                            borderWidth: 1,
                            borderColor: "#555555"
                        }
                    }
                },
            }

            for (const item of data) {
                labels.push(item.dateFormatted)
                series.positionEvents.data.push(0)
                if (item.positionEvent) {
                    series.positionEvents.markLine.data.push({
                        name: item.positionEvent,
                        xAxis: item.dateFormatted,
                        label: {
                            formatter: () => item.positionEvent,
                        }
                    })
                } else {
                    series.setPoint.data.push(+item.temperatureSetpoint.avg)
                    series.returnAir.data.push(+item.returnAirTemperature.avg)
                    series.supplyAir.data.push(+item.supplyAirTemperature.avg)
                    series.bookingSetPoint.data.push(+item.bookingTemperatureSetpoint.avg)
                }
            }

            return {
                series: Object.values(series),
                labels
            }
        }
    },

    methods: {
        async initData() {
            await this.loadContainerData();
        },

        async loadContainerData() {
            try {
                this.loadingData = true;

                const containerReference = this.referenceData.containerReference;
                const bookingId = this.referenceData.bookingId;

                const result = await this.$API.getTelemetryBookingContainer(containerReference, bookingId, [CONTAINER_TRACKING_INTEGRATION_CODES.HL]);

                const integrationData = result[CONTAINER_TRACKING_INTEGRATION_CODES.HL];

                this.regimeRules = await this.$API.getRegimeByCode(this.regimeCode)

                this.resultData = {
                    containerData: {
                        containerNo: containerReference,
                        metrics: {
                            reeferCargoContainerEvents: integrationData
                        }
                    }
                }

            } catch (e) {
                this.$message.error(e.message || e);
            } finally {
                this.loadingData = false;
            }
        },

        randomIntFromInterval(min, max) { // min and max included
            //TODO: Move to utils file
            return Math.floor(Math.random() * (max - min + 1) + min);
        },

        downloadDataExcel() {
            const data = this.processedTableDataForExport()
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);

            const containerReference = this.referenceData.containerReference;
            const bookingId = this.referenceData.bookingId;
            XLSX.utils.book_append_sheet(workbook, worksheet, containerReference);

            XLSX.writeFileXLSX(workbook, `${bookingId}-${containerReference}.xlsx`);
        },

        processedTableDataForExport() {
            return this.tableData.map(x => ({
                date: x.timestamp,
                dateFormatted: x.dateFormatted,
                returnAirMin: x.returnAirTemperature.min,
                returnAirMax: x.returnAirTemperature.max,
                returnAirAvg: x.returnAirTemperature.avg,
                supplyAirMin: x.supplyAirTemperature.min,
                supplyAirMax: x.supplyAirTemperature.max,
                supplyAirAvg: x.supplyAirTemperature.avg,
                setPointMin: x.temperatureSetpoint.avg,
                setPointMax: x.temperatureSetpoint.avg,
                setPointAvg: x.temperatureSetpoint.avg,
            }));
        },

        viewDebugDialog() {
            this.setJsonDebugHeight();
            this.debugDialog = true;
        },

        setJsonDebugHeight() {
            this.jsonDebugHeight = document.body.clientHeight - 110;
        }
    },

    mounted() {
        this.initData();
    },

    watch: {
        "$vuetify.breakpoint.width"() {
            this.setJsonDebugHeight();
        },

        'referenceData.containerReference'() {
            this.initData();
        }
    }
}
</script>

<style>
.dynamicChart {
    width: 100%
}
</style>
