<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="8">
            <v-row>
              <v-col cols="12" sm="6">
                <v-card
                  outlined
                  style="
                    border-radius: 20px;
                    background-color: var(--v-toolbar-lighten1) !important;
                  "
                >
                  <v-card-text class="my-0 py-0">
                    <v-row>
                      <v-col cols="12" sm="6" class="mx-0 px-0">
                        <v-list dense subheader class="mx-0">
                          <v-subheader style="font-size: 16px">
                            <v-icon class="mr-2" color="grey">insights</v-icon>
                            Actions
                          </v-subheader>
                          <v-divider></v-divider>
                          <!-- <v-list-item @click="$emit('createFile')">
                            <v-list-item-action>
                              <v-icon color="teal">post_add</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Create File
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item> -->
                          <v-list-item
                            style="
                              border-top: 0.5px solid grey;
                              border-bottom: 0.5px solid grey;
                            "
                            v-if="
                              !booking.integrationReference &&
                              (booking.type == 'LOGISTICS' ||
                                booking.type == 'FREIGHT') &&
                              booking.state != 'Cancelled' &&
                              booking.modeOfTransport == 'OCEAN'
                            "
                            @click="$emit('submitInttra')"
                          >
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="grey" small>send</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="ml-0">
                              <v-list-item-title>
                                Submit to Inttra
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            style="
                              border-top: 0.5px solid grey;
                              border-bottom: 0.5px solid grey;
                            "
                            v-if="
                              booking.integrationChange &&
                              booking.integrationReference &&
                              (booking.type == 'LOGISTICS' ||
                                booking.type == 'FREIGHT') &&
                              booking.state != 'Cancelled' &&
                              booking.modeOfTransport == 'OCEAN'
                            "
                            @click="$emit('updateInttra')"
                          >
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="grey" small>send</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="ml-0">
                              <v-list-item-title>
                                Update Inttra Booking
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            @click="$emit('cancelBooking')"
                            style="
                              border-top: 0.5px solid grey;
                              border-bottom: 0.5px solid grey;
                            "
                          >
                            <v-list-item-action class="mx-0 px-0">
                              <v-icon color="red" small>cancel</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="ml-0">
                              <v-list-item-title>
                                Cancel Booking
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                            @click="!booking.q67 ? viewQ67() : null"
                            style="
                              border-top: 0.5px solid grey;
                              border-bottom: 0.5px solid grey;
                            "
                            :key="exportNotificationKey"
                          >
                            <!-- <v-list-item-action class="mx-0 px-0">
                              <v-icon color="blue-grey" small>article</v-icon>
                            </v-list-item-action> -->
                            <v-list-item-content class="text-left ml-0">
                              <v-list-item-title v-if="!booking.q67">
                                Generate Q67
                              </v-list-item-title>
                              <v-row v-else>
                                <v-col cols="12" class="mb-0 pb-0 mt-1 pt-1">
                                  <v-list-item-title>
                                    Q67: Version {{ booking.q67.version }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle
                                    v-if="booking.exportNotificationStatus"
                                  >
                                    <v-chip
                                      x-small
                                      :color="
                                        booking.exportNotificationStatus ==
                                        'PENDING'
                                          ? 'orange'
                                          : booking.exportNotificationStatus ==
                                            'INPROGRESS'
                                          ? 'blue'
                                          : booking.exportNotificationStatus ==
                                            'CONFIRMED'
                                          ? 'success'
                                          : 'red'
                                      "
                                    >
                                      {{ booking.exportNotificationStatus }}
                                    </v-chip>
                                    <v-chip
                                      class="ml-1"
                                      v-if="booking.exportNotificationReference"
                                      x-small
                                      @click="
                                        copyContent(
                                          booking.exportNotificationReference
                                        )
                                      "
                                    >
                                      <v-icon x-small left>content_copy</v-icon>
                                      Ref:
                                      {{ booking.exportNotificationReference }}
                                    </v-chip>
                                  </v-list-item-subtitle>
                                </v-col>
                                <v-col
                                  cols="12"
                                  class="mt-0 px-1 mx-0"
                                  v-if="booking.q67"
                                >
                                  <v-row justify="start" class="ml-1 py-1">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          @click="viewQ67()"
                                          :loading="generatingQ67"
                                          v-on="on"
                                          small
                                        >
                                          <v-icon color="teal" small
                                            >post_add</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span style="font-size: 12px"
                                        >Generate new Q67</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip top v-if="booking.q67">
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          small
                                          @click="viewDocument(booking.q67)"
                                          v-on="on"
                                        >
                                          <v-icon color="blue" small
                                            >launch</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span style="font-size: 12px"
                                        >View Document</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip
                                      top
                                      v-if="booking.exportNotificationData"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          small
                                          @click="
                                            viewExportNotificationData(
                                              booking.exportNotificationData
                                            )
                                          "
                                          v-on="on"
                                        >
                                          <v-icon color="blue-grey" small
                                            >data_object</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span style="font-size: 12px"
                                        >View PPECB Export Notification
                                        Data</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip top v-if="booking.q67">
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          small
                                          @click="downloadDocument(booking.q67)"
                                          v-on="on"
                                        >
                                          <v-icon small>download</v-icon>
                                        </v-btn>
                                      </template>
                                      <span style="font-size: 12px"
                                        >Download Q67</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip top v-if="booking.q67Excel">
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          small
                                          @click="
                                            downloadDocument(booking.q67Excel)
                                          "
                                          v-on="on"
                                          color="green"
                                        >
                                          <v-icon small>browser_updated</v-icon>
                                        </v-btn>
                                      </template>
                                      <span style="font-size: 12px"
                                        >Download Excel Q67</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip
                                      top
                                      v-if="booking.exportNotificationReference"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          icon
                                          small
                                          @click="
                                            confirmExportNotificationItems()
                                          "
                                          v-on="on"
                                        >
                                          <v-icon color="blue" small
                                            >verified</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span style="font-size: 12px"
                                        >Confirm Export Notification Items</span
                                      >
                                    </v-tooltip>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                          <v-subheader style="font-size: 14px">
                            <v-icon class="mr-2" color="grey"
                              >description</v-icon
                            >
                            Files
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              color="primary"
                              @click="$emit('createFile')"
                              :disabled="
                                booking.state !== 'Confirmed' &&
                                booking.state !== 'Futile'
                              "
                            >
                              <v-icon>add_circle_outline</v-icon></v-btn
                            >
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-progress-linear
                            v-if="loadingContainers"
                            indeterminate
                            color="primary"
                          ></v-progress-linear>
                          <v-list
                            dense
                            style="max-height: 35vh; overflow-y: auto"
                          >
                            <v-list-item v-if="linkedFiles.length == 0">
                              <v-list-item-content class="text-center">
                                <span style="color: grey">No linked files</span>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                              v-for="file in linkedFiles"
                              :key="file.id"
                              @click="viewShipmentFile(file)"
                            >
                              <v-list-item-action class="mx-0">
                                <v-icon
                                  v-if="file.status == 'Processed'"
                                  small
                                  color="success"
                                  >task_alt</v-icon
                                >
                                <v-icon
                                  v-else-if="file.status == 'Ready'"
                                  small
                                  color="blue"
                                  >verified</v-icon
                                >
                                <v-icon
                                  v-else-if="file.status == 'Cancelled'"
                                  color="red"
                                  small
                                  >cancel</v-icon
                                >
                                <v-icon v-else color="orange" small
                                  >edit_note</v-icon
                                >
                              </v-list-item-action>
                              <v-list-item-content class="ml-0">
                                <v-list-item-title>
                                  {{ file.fileNumber }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-list>
                      </v-col>
                      <v-col cols="12" sm="6" class="mx-0 px-0">
                        <v-list subheader dense>
                          <v-subheader style="font-size: 16px">
                            <v-icon color="grey" class="mr-2">tag</v-icon>
                            References
                          </v-subheader>
                          <v-divider></v-divider>
                          <v-list-item
                            @click="qxReferenceModal = true"
                            :disabled="true"
                            ><v-list-item-content>
                              <v-list-item-title>
                                <span v-if="booking.qxReference">{{
                                  booking.qxReference
                                }}</span>
                                <span v-else>-</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                QX Reference
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item
                          >
                          <!-- <v-list-item @click="ucrNumberModal = true"><v-list-item-content>
                              <v-list-item-title>
                                <span v-if="booking.ucrNo">{{
                                  booking.ucrNo
                                }}</span>
                                <span v-else>-</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                UCR Number
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item> -->
                          <v-list-item v-if="booking.integrationReference"
                            ><v-list-item-content>
                              <v-list-item-title>
                                {{ booking.integrationReference }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Integration Ref No
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item
                          >
                          <v-list-item
                            @click="
                              editReference(
                                'carrierReferenceNumber',
                                'Carrier Reference'
                              )
                            "
                            ><v-list-item-content>
                              <v-list-item-title>
                                {{ booking.carrierReferenceNumber ?? "-" }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Carrier Reference No
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item
                          >
                          <v-list-item
                            class="mt-0 pt-0"
                            @click="
                              editReference(
                                'shipperReferenceNo',
                                'Shipper Reference'
                              )
                            "
                            ><v-list-item-content>
                              <v-list-item-title>
                                {{ booking.shipperReferenceNo ?? "-" }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Shipper Ref No
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item
                          >
                          <v-list-item
                            class="mt-0 pt-0"
                            @click="
                              editReference(
                                'consigneeReferenceNo',
                                'Consignee Reference'
                              )
                            "
                            ><v-list-item-content>
                              <v-list-item-title>
                                {{ booking.consigneeReferenceNo ?? "-" }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                Consignee Ref No
                              </v-list-item-subtitle>
                            </v-list-item-content></v-list-item
                          >
                        </v-list>
                      </v-col>
                    </v-row>
                    <v-list dense subheader>
                      <v-divider></v-divider>

                      <v-subheader style="font-size: 16px">
                        <!-- <v-icon class="mr-2" color="grey">location_on</v-icon> -->
                        Locations</v-subheader
                      >
                      <v-divider></v-divider>
                      <v-list-item
                        @click="(countryType = 'Origin'), (countryModal = true)"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              !booking.originCountry ? 'secondary' : 'grey'
                            "
                            >public</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.originCountry">
                            {{ booking.originCountry.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle>
                            Origin Country
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-avatar size="32">
                            <v-img
                              contain
                              v-if="booking.originCountry"
                              :src="`https://cdn.loglive.io/flags/4x3/${booking.originCountry.iso2.toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item @click="polModal = true">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              !booking.portOfLoadValue ? 'secondary' : 'grey'
                            "
                            >anchor</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.portOfLoadCity">
                            {{ booking.portOfLoadCity }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle> POL </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip
                            v-if="booking.portOfLoadValue"
                            pill
                            small
                            outlined
                          >
                            <v-avatar size="24" left>
                              <v-img
                                contain
                                v-if="booking.portOfLoadValue"
                                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ booking.portOfLoadValue }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>

                      <!--Transhipment port-->
                      <v-list-item
                        :disabled="true"
                        v-if="
                          booking.sailingSchedule &&
                          booking.sailingSchedule.legs > 1
                        "
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              !booking.sailingSchedule ? 'secondary' : 'grey'
                            "
                            >anchor</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            v-if="
                              booking.sailingSchedule &&
                              booking.sailingSchedule.sailingScheduleLegs &&
                              booking.sailingSchedule.transhipmentCityName
                            "
                          >
                            {{ booking.sailingSchedule.transhipmentCityName }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle>
                            Transhipment Port
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip
                            v-if="
                              booking.sailingSchedule &&
                              booking.sailingSchedule.sailingScheduleLegs[0]
                                .portOfDischargeValue
                            "
                            pill
                            small
                            outlined
                          >
                            <v-avatar size="24" left>
                              <v-img
                                contain
                                v-if="
                                  booking.sailingSchedule &&
                                  booking.sailingSchedule.sailingScheduleLegs[0]
                                    .portOfDischargeValue
                                "
                                :src="`https://cdn.loglive.io/flags/4x3/${booking.sailingSchedule.sailingScheduleLegs[0].portOfDischargeValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{
                              booking.sailingSchedule.sailingScheduleLegs[0]
                                .portOfDischargeValue
                            }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item @click="podModal = true">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              !booking.portOfDischargeValue
                                ? 'secondary'
                                : 'grey'
                            "
                            >anchor</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.portOfDischargeCity">
                            {{ booking.portOfDischargeCity }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle> POD </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip
                            v-if="booking.portOfDischargeValue"
                            pill
                            small
                            outlined
                          >
                            <v-avatar size="24" left>
                              <v-img
                                contain
                                v-if="booking.portOfDischargeValue"
                                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ booking.portOfDischargeValue }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item @click="fdModal = true">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              !booking.finalDestinationValue
                                ? 'secondary'
                                : 'grey'
                            "
                            >location_on</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            v-if="booking.finalDestinationCity"
                          >
                            {{ booking.finalDestinationCity }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle>
                            Final Destination
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip
                            v-if="booking.finalDestinationValue"
                            pill
                            small
                            outlined
                          >
                            <v-avatar size="24" left>
                              <v-img
                                contain
                                v-if="booking.finalDestinationValue"
                                :src="`https://cdn.loglive.io/flags/4x3/${booking.finalDestinationValue
                                  .substring(0, 2)
                                  .toLowerCase()}.svg`"
                              ></v-img>
                            </v-avatar>
                            {{ booking.finalDestinationValue }}
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item
                        @click="
                          (countryType = 'Destination'), (countryModal = true)
                        "
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              !booking.destinationCountry ? 'secondary' : 'grey'
                            "
                            >public</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.destinationCountry">
                            {{ booking.destinationCountry.name }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle>
                            Destination Country
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-avatar size="32">
                            <v-img
                              contain
                              v-if="booking.destinationCountry"
                              :src="`https://cdn.loglive.io/flags/4x3/${booking.destinationCountry.iso2.toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon :color="'grey'">not_listed_location</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-subtitle>
                            On Carriage
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-switch
                            v-model="booking.onCarriage"
                            color="blue"
                            @change="$emit('bookingChange')"
                          ></v-switch>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item @click="bookingOfficeModal = true">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              !booking.bookingOffice ? 'secondary' : 'grey'
                            "
                            >business</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.bookingOffice">
                            {{ booking.bookingOfficeName }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle>
                            Carrier Booking Office
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card
                  outlined
                  style="
                    border-radius: 20px;
                    background-color: var(--v-toolbar-lighten1) !important;
                  "
                >
                  <v-card-text class="my-0 py-0">
                    <v-list subheader dense>
                      <!-- <v-divider></v-divider> -->
                      <v-subheader style="font-size: 16px"
                        >Regime <v-spacer></v-spacer>
                        <v-chip
                          small
                          v-if="
                            booking.regime &&
                            booking.regime.probeCount &&
                            booking.regime.probeType
                          "
                          class="mx-1"
                        >
                          <span
                            >{{ booking.regime.probeCount }} x
                            {{ booking.regime.probeType }}</span
                          >
                        </v-chip>
                        <v-chip
                          v-if="booking.regime && booking.regime.steri"
                          outlined
                          style="border: none"
                          color="blue"
                          >STERI
                          <v-icon right color="blue">ac_unit</v-icon></v-chip
                        ></v-subheader
                      >
                      <v-divider></v-divider>
                      <v-list-item :key="regimeKey" v-if="booking.regime">
                        <v-list-item-action class="mr-0 pr-0">
                          <v-btn text @click="getRegimeCodes(true)" rounded>
                            {{ booking.regime.code }}</v-btn
                          >
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-subtitle>
                            <v-icon color="#007d5c" small class="mr-2"
                              >thermostat</v-icon
                            >
                            Set Temp: {{ `${booking.regime.setPointTemp}°C` }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="booking.regime.protocol">
                            <v-icon color="#9d904a" small class="mr-2"
                              >thermostat</v-icon
                            >
                            Protocol: {{ `${booking.regime.protocol}°C` }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content
                          style="max-width: 180px"
                          v-if="
                            booking.serviceType != 'BREAKBULK' &&
                            (!booking.caProduct ||
                              (booking.caProduct &&
                                booking.caProduct != 'MAXTEND'))
                          "
                        >
                          <v-select
                            hide-details
                            dense
                            outlined
                            rounded
                            v-model="booking.vents"
                            :suffix="
                              booking.vents != 'Closed' &&
                              booking.vents != 'Fully Open' &&
                              booking.vents != 'MA' &&
                              booking.vents != 'CA' &&
                              booking.vents != 'Auto'
                                ? 'CBM'
                                : ''
                            "
                            label="Vents"
                            :items="ventSettings"
                            @change="$emit('updateVents')"
                          ></v-select>
                        </v-list-item-content>
                        <v-list-item-action
                          v-else-if="
                            booking.serviceType != 'BREAKBULK' &&
                            booking.caProduct &&
                            booking.caProduct == 'MAXTEND'
                          "
                        >
                          <v-chip>MAXTEND Vents</v-chip>
                        </v-list-item-action>
                        <v-list-item-action
                          v-else-if="booking.serviceType != 'BREAKBULK'"
                        >
                          <v-chip>Closed Vents</v-chip>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                icon
                                color="danger"
                                v-on="on"
                                @click="removeRegime"
                              >
                                <v-icon> delete </v-icon>
                              </v-btn>
                            </template>
                            <span>Remove Regime</span>
                          </v-tooltip>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-else @click="getRegimeCodes(true)">
                        <v-list-item-content class="text-center">
                          <v-list-item-title style="color: grey">
                            No Regime Code
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="caProductModal = true">
                        <v-list-item-action>
                          <v-icon
                            :color="!booking.caProduct ? 'secondary' : 'grey'"
                            >thermostat_auto</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="booking.caProduct">
                            {{ booking.caProduct }}
                            <v-chip
                              class="ml-2"
                              v-if="booking.oxygen"
                              small
                              outlined
                              style="border: none"
                            >
                              <span style="font-weight: bold" class="mr-2"
                                >O²</span
                              >
                              {{ booking.oxygen }}%
                            </v-chip>
                            <v-chip
                              class="ml-2"
                              small
                              outlined
                              style="border: none"
                              v-if="booking.carbonDioxide"
                            >
                              <span style="font-weight: bold" class="mr-2"
                                >CO²</span
                              >
                              {{ booking.carbonDioxide }}%
                            </v-chip>
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle>
                            Controlled Atmosphere
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list dense>
                        <v-divider></v-divider>

                        <v-subheader style="font-size: 16px"
                          >Cargo Summary</v-subheader
                        >
                        <v-divider></v-divider>
                        <v-list-item v-if="cargoSummary().length == 0">
                          <v-list-item-content class="text-center">
                            <span style="color: grey; font-size: 14px"
                              >No cargo</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="(cargo, index) in cargoSummary()"
                          :key="index"
                        >
                          <v-list-item-action>
                            <v-chip>{{ cargo.count }}</v-chip>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title
                              style="font-size: 14px; font-weight: normal"
                            >
                              {{ cargo.products }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              v-if="booking.serviceType == 'BREAKBULK'"
                            >
                              Pallets: {{ cargo.pallets }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                              <span class="mr-1" v-if="cargo.containerType">
                                {{ cargo.containerType }}
                              </span>
                              <span v-if="booking.movementType == 'EXPORT'">
                                - {{ cargo.method }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle>
                              <!-- <span class="mr-1">Transport: </span>  -->
                              <div v-if="cargo.transportType == 'Merchant'">
                                <v-icon small color="grey" class="mr-1">
                                  local_shipping
                                </v-icon>
                                <span v-if="cargo.transportCoordinator">
                                  Transport Coordinator:
                                  <span v-if="cargo.transportCoordinator.alias">
                                    {{ cargo.transportCoordinator.alias }}
                                  </span>
                                  <span v-else>
                                    {{ cargo.transportCoordinator.name }}
                                  </span>
                                </span>
                                <span v-else>No Transport Coordinator</span>
                              </div>
                              <div v-else-if="cargo.transportType == 'Carrier'">
                                <v-icon color="grey" small class="mr-1"
                                  >directions_boat</v-icon
                                >
                                Carrier Haulage
                              </div>
                              <div v-else-if="cargo.transportType == 'Rail'">
                                <v-icon color="grey" small class="mr-1"
                                  >train</v-icon
                                >
                                <span v-if="cargo.transportCoordinator">
                                  Transport Coordinator:
                                  <span v-if="cargo.transportCoordinator.alias">
                                    {{ cargo.transportCoordinator.alias }}
                                  </span>
                                  <span v-else>
                                    {{ cargo.transportCoordinator.name }}
                                  </span>
                                </span>
                              </div>
                              <div v-else>
                                <v-icon color="grey" small class="mr-1"
                                  >help</v-icon
                                >
                                Transport not specified
                              </div>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle style="font-size: 11px">
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action style="max-width: 50%">
                            <v-container>
                              <v-row align="center">
                                <div v-for="x in container" :key="x">
                                  <v-tooltip
                                    top
                                    v-if="
                                      x.containerMilestones.some(
                                        (product) => product.type === 'GASSING'
                                      )
                                    "
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        icon
                                        outlined
                                        small
                                        class="mx-1"
                                        v-on="on"
                                        color="#EF783C"
                                        ><v-icon small
                                          >propane_tank</v-icon
                                        ></v-btn
                                      >
                                    </template>
                                    <span>Gassing</span>
                                  </v-tooltip>
                                </div>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) => product.product === 'Genset'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#D6D46D"
                                      ><v-icon small>bolt</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Genset Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'HeavyLoad'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#000072"
                                      ><v-icon small>scale</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Heavy Load </span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product ===
                                          'Monitoring Service' &&
                                        product.variation
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#99B080"
                                      ><v-icon small
                                        >desktop_windows</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>
                                    {{
                                      cargo.services.find(
                                        (product) =>
                                          product.product ===
                                          "Monitoring Service"
                                      ).variation +
                                      " " +
                                      "Service" +
                                      " " +
                                      "Required"
                                    }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'SteriInsurance'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#D6CC99"
                                      ><v-icon small>emergency</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Steri Insurance Required</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'SmartFresh'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#FFBFFF"
                                      ><v-icon small
                                        >smart_screen</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Smart Fresh Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Fumigation'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#F1DBBF"
                                      ><v-icon small
                                        >cleaning_services</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Fumigations Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Void Plugs'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#e05b0d"
                                      ><v-icon small>power</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Void Plugs</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Blast Freezing'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#13a7d4"
                                      ><v-icon small>ac_unit</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Blast Freezing</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'TelemPlus'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#18e00d"
                                      ><v-icon small>router</v-icon></v-btn
                                    >
                                  </template>
                                  <span>
                                    Devices Required:
                                    <template
                                      v-if="
                                        cargo.services.some(
                                          (product) =>
                                            product.product === 'TelemPlus'
                                        )
                                      "
                                    >
                                      <ul>
                                        <li
                                          v-for="(
                                            [variation, count], index
                                          ) in Object.entries(
                                            cargo.services
                                              .filter(
                                                (product) =>
                                                  product.product ===
                                                  'TelemPlus'
                                              )
                                              .reduce((acc, product) => {
                                                if (!acc[product.variation]) {
                                                  acc[product.variation] = 0;
                                                }
                                                acc[product.variation] +=
                                                  product.count;
                                                return acc;
                                              }, {})
                                          )"
                                          :key="index"
                                        >
                                          {{ variation }}: {{ count }}
                                        </li>
                                      </ul>
                                    </template>
                                  </span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) => product.product === 'Otflow'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#6A9C89"
                                      ><v-icon small>air</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Otflow Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) => product.product === 'Zoono'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#5b93c7"
                                      ><v-icon small>sanitizer</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Zoono Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Dunnage Bags'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#F1DBBF"
                                      ><v-icon small
                                        >shopping_bag</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Dunnage Bags Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Late Arrival'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#BB2525"
                                      ><v-icon small>watch_later</v-icon></v-btn
                                    >
                                  </template>
                                  <span>
                                    Late Arrival Required :
                                    {{
                                      cargo.services.find(
                                        (product) =>
                                          product.product === "Late Arrival"
                                      ).variation
                                    }}
                                  </span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Early Arrival'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="orange"
                                      ><v-icon small>watch_later</v-icon></v-btn
                                    >
                                  </template>
                                  <span>
                                    Early Arrival Required :
                                    {{
                                      cargo.services.find(
                                        (product) =>
                                          product.product === "Early Arrival"
                                      ).variation
                                    }}
                                  </span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) => product.product === 'Latches'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#508D69"
                                      ><v-icon small
                                        >pivot_table_chart</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Latches Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) => product.product === 'Ratches'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#EA906C"
                                      ><v-icon small
                                        >settings_input_component</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Ratches Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Stabling bars'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#5F6F52"
                                      ><v-icon small
                                        >video_stable</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Stabling bars Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Kraft paper'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#C5E898"
                                      ><v-icon small>note</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Kraft paper Required</span>
                                </v-tooltip>

                                <v-tooltip
                                  top
                                  v-if="
                                    cargo.services.some(
                                      (product) =>
                                        product.product === 'Absorb Bags'
                                    )
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      icon
                                      outlined
                                      small
                                      class="mx-1"
                                      v-on="on"
                                      color="#DCBFFF"
                                      ><v-icon small>backpack</v-icon></v-btn
                                    >
                                  </template>
                                  <span>Absorb Bags Required</span>
                                </v-tooltip>
                              </v-row>
                            </v-container>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card
              outlined
              :loading="loadingComment"
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
            >
              <v-card-text class="my-0 py-0 mx-0 px-0">
                <v-list dense subheader v-if="booking.stackDate">
                  <v-subheader style="font-size: 16px">
                    <!-- <v-icon class="mr-2" color="grey"
                              >calendar_month</v-icon
                            > -->
                    Important Dates</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-list-item v-if="booking.fullContainerCutoffDate">
                        <v-list-item-content>
                          <v-list-item-title>
                            Container Drop Off Due
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              formatDate(booking.fullContainerCutoffDate)
                                .isoFormat
                            }}
                            {{
                              formatDate(booking.fullContainerCutoffDate).time
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-list-item v-if="booking.vgmCutoffDate">
                        <v-list-item-content>
                          <v-list-item-title> VGM Due Date </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ formatDate(booking.vgmCutoffDate).isoFormat }}
                            {{ formatDate(booking.vgmCutoffDate).time }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-list-item v-if="booking.stackDate">
                    <v-list-item-content>
                      <v-list-item-title> Stack Dates </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="booking.stackDate.reeferStackStart"
                        class="mt-1"
                      >
                        Reefer: <v-spacer></v-spacer>
                        <v-chip small outlined style="border: none">
                          <v-icon
                            left
                            :color="
                              booking.stackDate.reeferStackStartConfirmed
                                ? 'success'
                                : 'orange'
                            "
                            >fiber_manual_record</v-icon
                          >
                          {{ formatDate(booking.stackDate.reeferStackStart) }}
                        </v-chip>
                        <v-chip small outlined style="border: none">
                          <v-icon
                            left
                            :color="
                              booking.stackDate.reeferStackFinishConfirmed
                                ? 'success'
                                : 'orange'
                            "
                            >fiber_manual_record</v-icon
                          >
                          {{ formatDate(booking.stackDate.reeferStackFinish) }}
                        </v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="booking.stackDate.dryStackStart"
                        class="mt-1"
                      >
                        Dry: <v-spacer></v-spacer
                        ><v-chip small outlined style="border: none">
                          <v-icon
                            left
                            :color="
                              booking.stackDate.dryStackStartConfirmed
                                ? 'success'
                                : 'orange'
                            "
                            >fiber_manual_record</v-icon
                          >
                          {{ formatDate(booking.stackDate.dryStackStart) }}
                        </v-chip>
                        <v-chip small outlined style="border: none">
                          <v-icon
                            left
                            :color="
                              booking.stackDate.dryStackFinishConfirmed
                                ? 'success'
                                : 'orange'
                            "
                            >fiber_manual_record</v-icon
                          >
                          {{ formatDate(booking.stackDate.dryStackFinish) }}
                        </v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="booking.stackDate.fisStart"
                        class="mt-1"
                      >
                        FIS: <v-spacer></v-spacer
                        ><v-chip small outlined style="border: none">
                          <v-icon
                            left
                            :color="
                              booking.stackDate.fisStartConfirmed
                                ? 'success'
                                : 'orange'
                            "
                            >fiber_manual_record</v-icon
                          >
                          {{ formatDate(booking.stackDate.fisStart) }}
                        </v-chip>
                        <v-chip small outlined style="border: none">
                          <v-icon
                            left
                            :color="
                              booking.stackDate.fisFinishConfirmed
                                ? 'success'
                                : 'orange'
                            "
                            >fiber_manual_record</v-icon
                          >
                          {{ formatDate(booking.stackDate.fisFinish) }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list dense subheader v-if="booking.movementType !== 'LOCAL'">
                  <v-divider v-if="booking.stackDate"></v-divider>

                  <v-subheader style="font-size: 16px"
                    >Transport
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-chip
                          pill
                          outlined
                          v-on="on"
                          :color="
                            getIntegrationStatusColor(booking.integrationStatus)
                          "
                        >
                          <v-avatar size="45" left class="mr-1">
                            <v-img
                              contain
                              :src="require('@/assets/project44_logo.png')"
                            ></v-img>
                          </v-avatar>
                          <span style="font-size: 12px">{{
                            booking.integrationStatus
                          }}</span>
                        </v-chip>
                      </template>
                      <span style="font-size: 12px">Project 44 Tracking</span>
                    </v-tooltip>
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-action v-if="!booking.shippingLine">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="white"
                        size="36"
                        v-if="booking.shippingLine"
                      >
                        <v-img
                          v-if="
                            booking.shippingLine && booking.shippingLine.logo
                          "
                          :src="booking.shippingLine.logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="booking.vessel">
                      <v-list-item-title>
                        {{ booking.vessel.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <u
                          style="cursor: pointer"
                          @click="voyageNumberModal = true"
                          v-if="booking.mainCarriageConveyanceNumber"
                          >Voyage No:
                          {{ booking.mainCarriageConveyanceNumber }}</u
                        >
                        <u
                          style="cursor: pointer"
                          @click="voyageNumberModal = true"
                          v-else
                          >No Voyage No</u
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>Vessel/Voyage</v-list-item-subtitle>
                      <!-- <v-list-item-subtitle
                        v-if="booking.shippingLine"
                        @click="shippingLineModal = true"
                        style="cursor: pointer"
                      >
                        <span v-if="booking.shippingLine.friendlyName">{{
                          booking.shippingLine.friendlyName
                        }}</span
                        ><span v-else>{{ booking.shippingLine.name }}</span>
                      </v-list-item-subtitle> -->
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <span style="font-size: 16px">Add Vessel</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row justify="center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="teal"
                              icon
                              @click="openSailingSchedules()"
                            >
                              <v-icon>event</v-icon></v-btn
                            >
                          </template>
                          <span style="font-size: 12px"
                            >Search Sailing Schedules</span
                          >
                        </v-tooltip>
                        <!-- <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="blue"
                              icon
                              @click="vesselModal = true"
                            >
                              <v-icon>manage_search</v-icon></v-btn
                            >
                          </template>
                          <span style="font-size: 12px">Search Vessels</span>
                        </v-tooltip> -->
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item @click="shippingLineModal = true">
                    <v-list-item-action v-if="!booking.shippingLine">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="white"
                        size="36"
                        v-if="booking.shippingLine"
                      >
                        <v-img
                          v-if="
                            booking.shippingLine && booking.shippingLine.logo
                          "
                          :src="booking.shippingLine.logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.shippingLine">{{
                        booking.shippingLine.name
                      }}</v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <div v-if="booking.shippingLineId"> -->
                  <v-list-item @click="vesselOperatorModal = true">
                    <v-list-item-action v-if="!booking.vesselOperator">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="white"
                        size="36"
                        v-if="booking.vesselOperator"
                      >
                        <v-img
                          v-if="
                            booking.vesselOperator &&
                            booking.vesselOperator.logo
                          "
                          :src="booking.vesselOperator.logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.vesselOperator">{{
                        booking.vesselOperator.name
                      }}</v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle
                        >Vessel Operator</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <!-- </div> -->
                  <!-- <div v-else></div> -->
                  <v-list-item @click="editContractNumber()">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          booking.contractNumber ? 'success' : 'secondary'
                        "
                        >description</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.contractNumber">
                        {{ booking.contractNumber }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        No Contract Selected
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="suggestedContracts.length > 0"
                      >
                        {{ suggestedContracts.length }} Suggested Contract(s)
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="
                          booking.contractOwner &&
                          getContractParty(booking.contractOwner)
                        "
                      >
                        <v-icon class="mr-2" small color="grey">groups</v-icon>
                        {{ getContractParty(booking.contractOwner) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Selected Contract
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-row v-if="booking.vessel">
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-action>
                              <v-icon
                                :color="booking.etd ? 'success' : 'secondary'"
                                >today</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-if="booking.etd">
                                {{ formatDateOnly(booking.etd) }}
                              </v-list-item-title>
                              <v-list-item-title v-else> - </v-list-item-title>
                              <v-list-item-subtitle> ETD </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-date-picker
                          v-model="booking.etd"
                          no-title
                          scrollable
                          @change="$emit('bookingChange')"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon :color="booking.atd ? 'success' : 'secondary'"
                            >today</v-icon
                          >
                        </v-list-item-action>
                        <v-menu
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-list-item-content
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <v-list-item-title v-if="booking.atd">
                                {{ formatDateOnly(booking.atd) }}
                              </v-list-item-title>
                              <v-list-item-title v-else> - </v-list-item-title>
                              <v-list-item-subtitle> ATD </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                          <v-date-picker
                            v-model="booking.atd"
                            no-title
                            scrollable
                            @change="$emit('bookingChange')"
                          >
                          </v-date-picker>
                        </v-menu>
                        <v-list-item-action>
                          <v-btn
                            v-if="booking.atd"
                            icon
                            color="redPop"
                            @click="
                              (booking.atd = null), $emit('bookingChange')
                            "
                          >
                            <v-icon small>close</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-action>
                              <v-icon
                                :color="booking.eta ? 'success' : 'secondary'"
                                >today</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-if="booking.eta">
                                {{ formatDateOnly(booking.eta) }}
                              </v-list-item-title>
                              <v-list-item-title v-else> - </v-list-item-title>
                              <v-list-item-subtitle> ETA </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-date-picker
                          v-model="booking.eta"
                          no-title
                          scrollable
                          @change="$emit('bookingChange')"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" class="my-0 py-0">
                      <v-list-item>
                        <v-list-item-action>
                          <v-icon :color="booking.ata ? 'success' : 'secondary'"
                            >today</v-icon
                          >
                        </v-list-item-action>
                        <v-menu
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-list-item-content
                              v-on="on"
                              style="cursor: pointer"
                            >
                              <v-list-item-title v-if="booking.ata">
                                {{ formatDateOnly(booking.ata) }}
                              </v-list-item-title>
                              <v-list-item-title v-else> - </v-list-item-title>
                              <v-list-item-subtitle> ATA </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                          <v-date-picker
                            v-model="booking.ata"
                            no-title
                            scrollable
                            @change="$emit('bookingChange')"
                          >
                          </v-date-picker>
                        </v-menu>
                        <v-list-item-action>
                          <v-btn
                            v-if="booking.ata"
                            icon
                            color="redPop"
                            @click="
                              (booking.ata = null), $emit('bookingChange')
                            "
                          >
                            <v-icon small>close</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <!-- <v-list-item
  @click="setSelectableParties(), (haulierModal = true)"
>
  <v-list-item-action>
    <v-avatar color="white" size="32" v-if="activeHaulier">
      <v-img
        v-if="activeHaulier.logo"
        :src="activeHaulier.logo"
        contain
      ></v-img>
      <v-icon small v-else color="black">{{
        activeHaulier.icon
      }}</v-icon>
    </v-avatar>
    <v-icon v-else color="secondary">local_shipping</v-icon>
  </v-list-item-action>
  <v-list-item-content v-if="activeHaulier">
    <v-list-item-title v-if="activeHaulier">
      {{ activeHaulier.name }}
    </v-list-item-title>
    <v-list-item-title v-else>-</v-list-item-title>
    <v-list-item-subtitle
      v-if="activeHaulier && booking.preAdvise"
      ><v-chip color="blue" small
        >Preadvise Required</v-chip
      ></v-list-item-subtitle
    >
    <v-list-item-subtitle
      >Transport Coordinator</v-list-item-subtitle
    >
  </v-list-item-content>
  <v-list-item-content v-else>
    <span style="font-size: 16px"
      >Transport Coordinator
      <i style="font-size: 12px">(Optional)</i>
    </span>
  </v-list-item-content>
</v-list-item> -->
                </v-list>
                <v-list dense subheader v-else>
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">Transport </v-subheader>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-action>
                              <v-icon
                                :color="booking.etd ? 'success' : 'secondary'"
                                >today</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-if="booking.etd">
                                {{ formatDateOnly(booking.etd) }}
                              </v-list-item-title>
                              <v-list-item-title v-else> - </v-list-item-title>
                              <v-list-item-subtitle> ETD </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-date-picker
                          v-model="booking.etd"
                          no-title
                          scrollable
                          @change="$emit('bookingChange')"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item v-on="on">
                            <v-list-item-action>
                              <v-icon
                                :color="booking.eta ? 'success' : 'secondary'"
                                >today</v-icon
                              >
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-if="booking.eta">
                                {{ formatDateOnly(booking.eta) }}
                              </v-list-item-title>
                              <v-list-item-title v-else> - </v-list-item-title>
                              <v-list-item-subtitle> ETA </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-date-picker
                          v-model="booking.eta"
                          no-title
                          scrollable
                          @change="$emit('bookingChange')"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-list-item @click="editContractNumber()">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          booking.contractNumber ? 'success' : 'secondary'
                        "
                        >description</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="booking.contractNumber">
                        {{ booking.contractNumber }}
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        No Contract Selected
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="suggestedContracts.length > 0"
                      >
                        {{ suggestedContracts.length }} Suggested Contract(s)
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="
                          booking.contractOwner &&
                          getContractParty(booking.contractOwner)
                        "
                      >
                        <v-icon class="mr-2" small color="grey">groups</v-icon>
                        {{ getContractParty(booking.contractOwner) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Selected Contract
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <!-- -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="referenceModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="referenceType">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage {{ referenceType.label }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="referenceModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            autofocus
            v-model="booking[referenceType.field]"
            outlined
            dense
            clearable
            @change="$emit('bookingChange')"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="countryModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Select {{ countryType }} Country </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="countryModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            autofocus
            dense
            hide-details
            v-model="searchCountries"
            clearable
            placeholder="Search"
            prepend-inner-icon="search"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="country in filterCountries"
              :key="country.id"
              @click="setCountry(country)"
            >
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No countries found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Contract Modal -->
    <v-dialog
      v-model="contractModal"
      width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Set Contract </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(contractModal = false), $emit('updateBooking')"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text :key="contractKey">
          <v-row justify="center">
            <v-col cols="12" sm="8">
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <v-subheader style="font-size: 16px"
                  >Available Contracts</v-subheader
                >
                <v-divider></v-divider>
                <v-text-field
                  outlined
                  dense
                  prepend-inner-icon="search"
                  v-model="searchContract"
                  clearable
                ></v-text-field>
                <v-list dense style="max-height: 50vh">
                  <v-list-item v-if="filterSuggestedContracts.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No contracts found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list-item
                  v-for="contract in filterSuggestedContracts"
                  :key="contract.id"
                  @click="setContract(contract)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ contract.contracts[0].contractNo }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="contract.contracts[0]">
                      <v-icon color="grey" small class="mr-2">tag</v-icon>
                      {{ contract.alias }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="contract.contracts[0]">
                      <v-icon small color="grey" class="mr-2">event</v-icon>
                      <span>{{ contract.contracts[0].startDate }}</span>
                      <v-icon x-small class="mr-1">arrow_forward</v-icon>
                      <span>{{ contract.contracts[0].endDate }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="contract.contractOwner">
                      <v-icon small color="grey" class="mr-2">group</v-icon>
                      <span v-if="contract.contractOwner.alias">{{
                        contract.contractOwner.alias
                      }}</span>
                      <span v-else>{{ contract.contractOwner.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item></v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="4">
              <v-list dense style="max-height: 50vh; overflow-y: auto">
                <!-- <v-text-field label="Contract Owner" outlined dense clearable v-model="booking.contractOwner"></v-text-field> -->
                <v-select
                  outlined
                  dense
                  label="Contract Owner"
                  :items="chargeOptions"
                  v-model="booking.contractOwner"
                  item-text="name"
                  item-value="value"
                  @change="$emit('bookingChange')"
                  style="text-transform: capitalize"
                >
                </v-select>
                <v-text-field
                  label="Contract Number"
                  outlined
                  dense
                  clearable
                  @change="$emit('bookingChange')"
                  v-model="booking.contractNumber"
                ></v-text-field>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POL Modal -->
    <v-dialog
      v-model="polModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POL
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="polModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="booking.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchOrigin"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-else
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              booking.consigneeProfile &&
              !booking.consigneeProfile.allOriginPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in loadingPorts"
                :key="port.id"
                @click="setPort('portOfLoad', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == booking.portOfLoadValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="booking.consigneeProfile">
            <v-progress-linear
              v-if="loadingOriginPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterOriginPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterOriginPort"
                    :key="originPortKey"
                    height="500"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Origin')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'UNMARK', 'Origin')"
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterOriginPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="250"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POD Modal -->
    <v-dialog
      v-model="podModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POD
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="podModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="booking.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchDestination"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            v-else
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              booking.consigneeProfile &&
              !booking.consigneeProfile.allDestinationPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in dischargePorts"
                :key="port.id"
                @click="setPort('portOfDischarge', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == booking.portOfDischargeValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="booking.consigneeProfile">
            <v-progress-linear
              v-if="loadingDestinationPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterDestinationPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterDestinationPort"
                    height="500"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Destination')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="
                              favouritePort(item, 'UNMARK', 'Destination')
                            "
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterDestinationPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 12px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="250"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 12px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 12px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Final Destination Modal -->
    <v-dialog
      v-model="fdModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">location_on</v-icon> Manage
            Final Destination
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="fdModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <template>
            <v-container class="pa-0">
              <!-- Chips Container -->
              <v-chip-group
                v-model="selectedTransportModes"
                active-class="primary--text"
                multiple
              >
                <v-chip
                  v-for="transport in transportModes"
                  :key="transport"
                  :value="transport"
                  filter
                  small
                >
                  {{ transport }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </template>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="loadingPortSearch"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="portResults.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="portResults"
                  height="250"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item
                      :key="item.id"
                      @click="setPort('finalDestination', item)"
                    >
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 12px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Booking Office Modal -->
    <v-dialog
      v-model="bookingOfficeModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">location_on</v-icon> Manage
            Booking Office
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bookingOfficeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="bookingOfficeSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="bookingOfficeLoading"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="bookingOfficePlaces.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="bookingOfficePlaces"
                  height="250"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" @click="setBookingOffice(item)">
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 12px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 12px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="q67CommentModal"
      width="1000px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="generatingQ67">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Q67</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="(q67CommentModal = false), (q67Comment = null)"
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="3">
              <v-switch
                v-model="q67Request.submit"
                label="Submit to Titan"
                dense
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                outlined
                dense
                label="Voyage"
                v-model="q67Request.voyage"
                clearable
                hide-details
                :items="ppecbVoyages"
                item-text="vesselName"
                item-value="voyageNumber"
              >
                <template v-slot:selection="data">
                  <span style="font-size: 12px"
                    >{{ data.item.vesselName }} ({{
                      data.item.voyageNumber
                    }})</span
                  >
                </template>
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.vesselName
                    }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.voyageNumber }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field
                :key="nCodeKey"
                outlined
                dense
                hide-details
                label="PPECB N Code"
                v-model="q67Request.ppecbNcode"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                outlined
                dense
                hide-details
                label="Charge Party"
                v-model="q67Request.ppecbInv"
                :items="availableParties"
                @change="setNCode"
                item-text="name"
                item-value="name"
              ></v-select>
            </v-col>
          </v-row>
          <v-textarea
            v-model="q67Comment"
            outlined
            placeholder="Addtional Comments (optional)"
          ></v-textarea>
          <v-row justify="center">
            <v-btn
              @click="generateQ67()"
              :disabled="!q67Request.ppecbNcode || !q67Request.ppecbInv"
              small
              rounded
              color="blue"
              class="mx-1"
              >Generate Q67</v-btn
            >
            <!-- <v-btn
              @click="generateQ67Excel()"
              small
              rounded
              color="green"
              class="mx-1"
              >Generate Excel Q67</v-btn
            > -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shippingLineModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Shipping Line</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shippingLineModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            dense
            outlined
            rounded
            prepend-inner-icon="search"
            autofocus
            placeholder="Search"
            clearable
            v-model="searchShippingLine"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="carrier in filterShippingLines"
              :key="carrier.id"
              @click="setShippingLine(carrier)"
            >
              <v-list-item-action>
                <v-avatar v-if="carrier.logo" color="white">
                  <v-img :src="carrier.logo" contain></v-img>
                </v-avatar>
                <v-avatar v-else-if="carrier.name" color="secondary">
                  <h3>{{ carrier.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ carrier.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="vesselOperatorModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Vessel Operator</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="vesselOperatorModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            dense
            outlined
            rounded
            prepend-inner-icon="search"
            autofocus
            placeholder="Search"
            clearable
            v-model="searchVesselOperator"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="carrier in filterVesselOperator"
              :key="carrier.id"
              @click="setVesselOperator(carrier)"
              style="height: 45px"
            >
              <v-list-item-action class="mx-0 px-0 mr-2">
                <v-avatar
                  :color="carrier.logo ? 'white' : 'secondary'"
                  size="32"
                >
                  <v-img
                    v-if="carrier.logo"
                    :src="carrier.logo"
                    contain
                  ></v-img>
                  <h3 v-else>{{ carrier.name.charAt(0) }}</h3>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 12px">{{
                  carrier.name
                }}</v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">{{
                  carrier.friendlyName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="caProductModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="savingCAProduct">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Set Controlled Atmosphere</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn
            icon
            color="secondary"
            :loading="savingCAProduct"
            @click="setCAProduct()"
            ><v-icon>save</v-icon></v-btn
          > -->
          <v-btn text @click="caProductModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-autocomplete
            v-model="booking.caProduct"
            :items="caProducts"
            outlined
            dense
            placeholder="Select a CA Product"
            clearable
            item-text="description"
            item-value="name"
            @change="setCAProduct()"
          ></v-autocomplete>
          <v-text-field
            label="O²"
            outlined
            dense
            v-model="booking.oxygen"
            type="number"
            @input="setCASettings()"
            suffix="%"
          ></v-text-field>
          <v-text-field
            label="CO²"
            outlined
            dense
            v-model="booking.carbonDioxide"
            type="number"
            @input="setCASettings()"
            suffix="%"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="voyageNumberModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat dense color="transparent">
          <v-spacer></v-spacer>
          <v-btn text @click="voyageNumberModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            label="Voyage No"
            v-model="booking.mainCarriageConveyanceNumber"
            outlined
            dense
            clearable
            @change="$emit('bookingChange')"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="vesselModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card :loading="vesselLoading">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Search Vessel </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="vesselModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            v-model="searchVessel"
            clearable
            placeholder="Search"
            prepend-inner-icon="search"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="vessel in vessels"
              :key="vessel.id"
              @click="setVessel(vessel)"
            >
              <v-list-item-avatar>
                <v-img
                  contain
                  v-if="vessel.countryCode"
                  :src="`https://cdn.loglive.io/flags/4x3/${vessel.countryCode.toLowerCase()}.svg`"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ vessel.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="vessel.imoNumber"
                  >IMO: {{ vessel.imoNumber }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="vessel.mmsiNumber"
                  >MMSI: {{ vessel.mmsiNumber }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="vessels.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No vessels found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleError"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-text class="text-left mt-3 pt-5">
          <p style="font-size: 16px">
            Please select make sure to select the following before continuing:
          </p>
          <ul>
            <li v-if="!booking.portOfLoadValue">Port of Load (POL)</li>
            <li v-if="!booking.portOfDischargeValue">
              Port of Discharge (POD)
            </li>
          </ul>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="blue"
              class="mx-2"
              text
              small
              @click="sailingScheduleError = false"
              >Okay</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="sailingScheduleModal"
      persistent
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2" color="secondary">directions_boat</v-icon>
            Search Sailing Schedules
          </v-toolbar-title>
          <v-chip
            v-if="booking.portOfLoadValue"
            small
            outlined
            style="border: none"
            class="ml-2"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.portOfLoadValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.portOfLoadValue }}
          </v-chip>
          <v-icon class="mx-3" color="grey" small>east</v-icon>
          <v-chip
            v-if="booking.portOfDischargeValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.portOfDischargeValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.portOfDischargeValue }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn text @click="sailingScheduleModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <ScheduleModal
            :export="booking.movementType == 'EXPORT'"
            :portOfLoadValue="booking.portOfLoadValue"
            :finalDestinationValue="booking.portOfDischargeValue"
            :shippingLines="shippingLines"
            :sailingScheduleId="booking.sailingScheduleId"
            @select="selectSchedule"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="regimeCodeModal"
      width="500px"
      :fullscreen="$vuetify.breakpoint.mobile"
      peristent
    >
      <v-card :loading="regimeCodeLoading">
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Regime Code </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="regimeCodeModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            placeholder="Search"
            autofocus
            prpend-inner-icon="search"
            v-model="regimeCodeSearch"
            outlined
            dense
            clearable
          ></v-text-field>
          <v-virtual-scroll
            v-if="filterRegimeCodes.length > 0"
            class="my-0 py-0"
            :bench="0"
            :items="filterRegimeCodes"
            height="500"
            item-height="85"
          >
            <template v-slot:default="{ item }">
              <v-list-item
                @click="setRegimeCode(item)"
                style="border-bottom: 0.5px solid grey"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.code }}
                    <v-chip class="ml-2" outlined small v-if="item.steri">
                      <v-icon left color="blue" small>ac_unit</v-icon>Steri Code
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon class="mr-2" color="grey" small>thermostat</v-icon
                    >Set Point: {{ item.setPointTemp }}°C
                    <v-icon class="mx-2" color="red" small>thermostat</v-icon
                    >Max: {{ item.maxProductTemp }}°C
                    <v-icon class="mx-2" color="blue" small>thermostat</v-icon
                    >Min {{ item.minProductTemp }}°C
                  </v-list-item-subtitle>
                  <!-- <v-list-item-subtitle v-if="item.steri">
                                        <v-icon class="mr-2" color="blue" small>ac_unit</v-icon>Steri Code
                                    </v-list-item-subtitle> -->
                  <v-list-item-subtitle>
                    {{ item.recipeDescription }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item v-if="filterRegimeCodes.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 12px"
                  >No regime codes found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewModal"
      width="1200px"
      :fullscreen="fullscreen || $vuetify.breakpoint.mobile"
    >
      <v-card v-if="item && item.name">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            {{ item.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadDocument(item)" icon
            ><v-icon>download</v-icon></v-btn
          >
          <v-btn @click="fullscreen = !fullscreen" icon
            ><v-icon v-if="!fullscreen">fullscreen</v-icon
            ><v-icon v-else>fullscreen_exit</v-icon></v-btn
          >
          <v-btn icon text @click="(previewModal = false), (item = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text
          :style="{
            'max-height': fullscreen ? '90vh' : '75vh',
            'overflow-y': 'auto',
          }"
        >
          <v-row>
            <v-col cols="12" :loading="imageLoading">
              <div v-if="item.type && item.type.includes('image/')">
                <v-img
                  :src="item.url"
                  contain
                  style="max-height: 70vh"
                  @load="imageLoading = false"
                >
                </v-img>
              </div>
              <div v-else-if="item.type == 'application/pdf'">
                <v-row justify="center">
                  <v-col cols="12" sm="9" class="text-center" v-if="item.url">
                    <div ref="pdfBox">
                      <VuePdfEmbed
                        :width="fullscreen ? 1200 : 900"
                        :source="item.url"
                        @loaded="imageLoading = false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <v-container v-else fill-height>
                <v-row justify="center" height="100%">
                  <div class="text-center">
                    <span
                      >This file is in a format that cannot be previewed.</span
                    >
                    <br /><br />
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="downloadDocument(item)"
                    >
                      <v-icon class="mr-1">download</v-icon> Download</v-btn
                    >
                  </div>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="qxReferenceModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transaprent">
          <v-toolbar-title>QX Reference</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="(qxReferenceModal = false), $emit('bookingChange')"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            clearable
            v-model="booking.qxReference"
            placeholder="eg M12345"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="ucrNumberModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transaprent">
          <v-toolbar-title>UCR Number</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(ucrNumberModal = false), $emit('bookingChange')"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            clearable
            v-model="booking.ucrNo"
            placeholder="eg 4ZA123456CCUSREF#S"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="previewShipmentFile"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ViewFile
        :key="previewKey"
        v-if="previewShipmentFile && selectedFile && selectedFile.id"
        :fileId="selectedFile.id"
        @close="previewShipmentFile = false"
        @Cancelled="cancelFile"
      />
    </v-dialog>

    <v-dialog
      v-model="viewExportNotification"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <PreviewExportNotification
        :data="booking.exportNotificationData"
        @close="viewExportNotification = false"
      />
    </v-dialog>
    <v-dialog
      v-model="confirmExportNotificationModal"
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card flat :loading="loadingContainers">
        <v-card-text>
          <!-- <span>Select containers for file:</span> -->
          <v-row align="center" justify="center" class="mt-3">
            <v-col cols="6" class="text-center">
              <v-btn
                small
                outlined
                color="green"
                @click="selectAllFileContainers(filterContainers)"
                >Select All</v-btn
              >
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn small outlined color="red" @click="deselectAllContainers()"
                >Deselect All</v-btn
              >
            </v-col>
          </v-row>
          <v-text-field
            class="mt-2"
            placeholder="Search"
            outlined
            dense
            clearable
            prepend-inner-icon="search"
            v-model="searchContainers"
            hide-details
          ></v-text-field>
          <v-list
            dense
            :key="containerKey"
            style="max-height: 50vh; overflow-y: auto"
          >
            <v-list-item
              v-for="container in filterContainers"
              :key="container.id"
              @click="addToContainerSelection(container.id)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ container.ctoNo }}</v-list-item-title>
                <v-list-item-subtitle v-if="container.containerNo">{{
                  container.containerNo
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="container.sealNo"
                  >Seal No: {{ container.sealNo }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="container.LOADING_POINT_1"
                  ><v-icon small color="grey" class="mr-2">location_on</v-icon>
                  LP1: {{ container.LOADING_POINT_1.description }}
                  {{ formatDateOnly(container.LOADING_POINT_1.date) }}
                  {{ container.LOADING_POINT_1.time }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="container.LOADING_POINT_2"
                  ><v-icon small color="grey" class="mr-2">location_on</v-icon>
                  LP2: {{ container.LOADING_POINT_2.description
                  }}{{ formatDateOnly(container.LOADING_POINT_2.date) }}
                  {{ container.LOADING_POINT_2.time }}</v-list-item-subtitle
                >

                <v-list-item-subtitle v-if="container.customerContainerRef">
                  <v-icon small class="pb-1 mr-1" color="grey"
                    >qr_code_2</v-icon
                  >
                  {{ container.customerContainerRef }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  v-if="selectedContainers.includes(container.id)"
                  color="success"
                  >check_circle_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterContainers.length == 0">
              <v-list-item-content class="text-center">
                <span style="font-size: 14px; color: grey"
                  >No containers found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row justify="center" class="my-2">
            <v-btn
              text
              color="primary"
              :disabled="selectedContainers.length == 0"
              :loading="loadingConfirmExportNotification"
              @click="submitConfirmedeQ67ContainerItems()"
              >Submit</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color"
      >{{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          X
        </v-btn>
      </template></v-snackbar
    >

    <v-snackbar
      v-model="errorSnackbar"
      :timeout="-1"
      color="red"
      :multi-line="true"
    >
      <span style="font-size: 16px"
        >There was an error submitting the export booking to Titan
        (PPECB):</span
      >
      <ul>
        <li v-for="(error, i) in errors" :key="i">
          {{ error }}
        </li>
      </ul>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">
          X
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="copySnackbar"
      :timeout="2000"
      absolute
      bottom
      color="success"
      center
      text
      style="z-index: 99999"
    >
      Copied to clipboard!
    </v-snackbar>
  </div>
</template>
<script>
import dateFormat from "dateformat";
import PreviewExportNotification from "../PPECB/PreviewExportNotification.vue";
import ScheduleModal from "./Dialogs/SailingSchedule.vue";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  props: [
    "booking",
    "container",
    "countries",
    "linkedFiles",
    "loadingContainers",
    "suggestedDealTerms",
    "ventSettings",
  ],
  components: {
    PreviewExportNotification,
    ScheduleModal,
    ViewFile,
    VuePdfEmbed,
  },
  data: () => ({
    ucrNumberModal: false,
    allOriginPorts: [],
    allDestinationPorts: [],
    bookingOfficePlaces: [],
    bookingOfficeLoading: false,
    bookingOfficeQuery: undefined,
    bookingOfficeTimeout: null,
    bookingOfficeSearch: null,
    bookingOfficeModal: false,
    caProductModal: false,
    caProducts: [
      {
        shippingLine: "HPL",
        name: "EXTRAFRESH PLUS",
        description: "EXTRAFRESH PLUS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCARE",
        description: "STARCARE",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "STARCOOL",
        description: "STARCOOL",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "MSK",
        name: "LIVENTUS",
        description: "LIVENTUS",
        oxygen: 8,
        carbonDioxide: 15,
      },
      {
        shippingLine: "ONE",
        name: "MAXTEND",
        description: "MAXTEND",
        oxygen: 5,
        carbonDioxide: 10,
      },
      {
        shippingLine: "MSC",
        name: "XTENDFR A",
        description: "XTENDFRESH AVOCADOS",
        oxygen: 4,
        carbonDioxide: 6,
      },
      {
        shippingLine: "HPL",
        name: "EXTRAFR B",
        description: "EXTRAFR BERRIES",
        oxygen: 4,
        carbonDioxide: 12,
      },
    ],
    chargeOptions: [
      { name: "Buyer", value: "buyer" },
      { name: "Forwarder", value: "forwarder" },
      { name: "Shipper", value: "shipper" },
      { name: "Consignee", value: "consignee" },
      { name: "1st Notify Party", value: "firstNotify" },
      { name: "2nd Notify Party", value: "secondNotify" },
      { name: "On Behalf of Shipper", value: "onBehalfShipper" },
      { name: "Customer", value: "customer" },
    ],
    comment: null,
    comments: [],
    copySnackbar: false,
    confirmExportNotificationModal: false,
    contractKey: 200,
    contractModal: false,
    countryModal: false,
    countryType: "Origin",
    containerKey: 800,
    errorSnackbar: false,
    errors: [],
    exportNotificationContainers: [],
    exportNotificationKey: 1700,
    fullscreen: false,
    generatingQ67: false,
    haulierModal: false,
    imageLoading: false,
    integrationForms: [],
    item: {},
    loadingComment: false,
    loadingOriginPorts: false,
    loadingDestinationPorts: false,
    loadingPortSearch: false,
    loadingVoyages: false,
    nCodeKey: 100,
    polModal: false,
    podModal: false,
    ppecbVoyages: [],
    previewShipmentFile: false,
    previewKey: 950,
    fdModal: false,
    destinationPortKey: 4100,
    loadingConfirmExportNotification: false,
    originPortKey: 4000,
    portSearch: null,
    portSearchQuery: undefined,
    portSearchTimeout: null,
    selectedTransportModes: ["Port"],
    transportModes: ["Rail", "Road", "Port", "Airport"],
    portResults: [],
    previewModal: false,
    q67Comment: null,
    q67CommentModal: false,
    q67Request: {},
    qxReferenceModal: false,
    referenceType: {},
    referenceModal: false,
    regimeCodeModal: false,
    regimeCodeSearch: null,
    regimeCodes: [],
    regimeCodeLoading: false,
    sailingScheduleError: false,
    sailingScheduleModal: false,
    savingCAProduct: false,
    searchContainers: null,
    searchCountries: null,
    searchDestination: null,
    searchOrigin: null,
    searchShippingLine: null,
    searchVesselOperator: null,
    searchVessel: null,
    selectedContainers: [],
    selectedFile: {},
    selectableParties: [],
    sendingMessage: false,
    snackbar: {
      show: false,
    },
    shippingLines: [],
    vesselOperators: [],
    shippingLineModal: false,
    vesselOperatorModal: false,
    searchContract: null,
    suggestedContracts: [],
    viewExportNotification: false,
    vesselModal: false,
    vessels: [],
    vesselQuery: undefined,
    vesselTimeout: null,
    vesselLoading: false,
    voyageNumberModal: false,
    regimeKey: 2000,
  }),
  watch: {
    "booking.id": {
      immediate: true,
      handler(val) {
        if (val) {
          // this.getComments();
          this.getBookingFormResponses();
        }
      },
    },
    "booking.consigneeProfileId": {
      immediate: true,
      handler(val) {
        if (this.booking.consigneeProfile) {
          this.getAllPorts(this.booking.consigneeProfile);
        }
      },
    },
    async bookingOfficeSearch(val) {
      if (this.bookingOfficeLoading) {
        clearTimeout(this.bookingOfficeTimeout);
      }
      if (val && val.length > 2) {
        if (this.bookingOfficeLoading && this.bookingOfficeQuery) {
          this.bookingOfficeQuery.abort();
        }
        this.bookingOfficeLoading = true;
        let params = {
          search: val,
          // filter: {
          //     countryCode: this.booking.polCountry.iso2
          // },
        };
        if (this.booking.originCountry) {
          params.filter = {
            countryCode: this.booking.originCountry.iso2,
          };
        }

        this.bookingOfficeTimeout = setTimeout(async () => {
          this.bookingOfficeQuery = new AbortController();
          const signal = this.bookingOfficeQuery.signal;
          this.bookingOfficePlaces = await this.$API.searchTowns({
            params: params,
            signal,
          });
          this.bookingOfficeLoading = false;
        }, 750);
      } else {
        clearTimeout(this.bookingOfficeTimeout);
        this.isLoading = false;
      }
    },
    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: this.selectedTransportModes,
      };
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 750);
    },
    async searchFinalDestination(val) {
      if (this.loadingFinalDestination) {
        clearTimeout(this.finalDestinationPointTimeout);
      }
      if (this.loadingFinalDestination && this.finalDestinationQuery) {
        this.finalDestinationQuery.abort();
      }
      this.loadingFinalDestination = true;
      let params = {
        search: val,
      };
      if (this.transportItem.mode == "MaritimeTransport") {
        params.portType = "port";
      }
      this.portSearchTimeout = setTimeout(async () => {
        this.finalDestinationQuery = new AbortController();
        const signal = this.finalDestinationQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingFinalDestination = false;
      }, 750);
    },

    async searchVessel(val) {
      if (this.vesselLoading && this.vesselTimeout) {
        clearTimeout(this.vesselTimeout);
      }
      if (this.vesselLoading && this.vesselQuery) {
        this.vesselQuery.abort();
      }
      this.vesselLoading = true;
      this.vesselTimeout = setTimeout(async () => {
        this.vesselQuery = new AbortController();
        const signal = this.vesselQuery.signal;
        this.vessels = await this.$API.searchVessels({
          signal,
          params: {
            search: val,
          },
        });
        this.vesselLoading = false;
      }, 750);
    },
  },
  computed: {
    filterContainers() {
      let result = this.exportNotificationContainers ?? [];
      if (this.searchContainers) {
        result = result.filter(
          (x) =>
            (x.containerNo &&
              x.containerNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.sealNo &&
              x.sealNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.LOADING_POINT_1 &&
              x.LOADING_POINT_1.description
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.LOADING_POINT_2 &&
              x.LOADING_POINT_2.description
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase())) ||
            (x.ctoNo &&
              x.ctoNo
                .toLowerCase()
                .includes(this.searchContainers.toLowerCase()))
        );
      }
      return result;
    },
    fullRoute() {
      let result = [];
      let setTypes = [
        { type: "Port of Load", locode: this.booking.portOfLoadValue },
        {
          type: "Port of Discharge",
          locode: this.booking.portOfDischargeValue,
        },
        {
          type: "Final Destination",
          locode: this.booking.finalDestinationValue,
        },
      ];

      if (this.booking.sailingSchedule) {
        for (
          let i = 0;
          i < this.booking.sailingSchedule.sailingScheduleLegs.length;
          i++
        ) {
          let find = setTypes.find(
            (x) =>
              x.locode ==
              this.booking.sailingSchedule.sailingScheduleLegs[i]
                .portOfLoadValue
          );
          if (find) {
            result.push(find);
          } else {
            let findExists = result.find(
              (x) =>
                x.locode ==
                this.booking.sailingSchedule.sailingScheduleLegs[i]
                  .portOfLoadValue
            );
            if (!findExists) {
              result.push({
                type:
                  "TS Port " +
                  this.booking.sailingSchedule.sailingScheduleLegs[i].leg,
                locode:
                  this.booking.sailingSchedule.sailingScheduleLegs[i]
                    .portOfLoadValue,
              });
            }
          }

          let findPOD = setTypes.find(
            (x) =>
              x.locode ==
              this.booking.sailingSchedule.sailingScheduleLegs[i]
                .portOfDischargeValue
          );
          if (findPOD) {
            result.push(findPOD);
          } else {
            let findExists = result.find(
              (x) =>
                x.locode ==
                this.booking.sailingSchedule.sailingScheduleLegs[i]
                  .portOfDischargeValue
            );
            if (!findExists) {
              result.push({
                type:
                  "TS Port " +
                  this.booking.sailingSchedule.sailingScheduleLegs[i].leg,
                locode:
                  this.booking.sailingSchedule.sailingScheduleLegs[i]
                    .portOfDischargeValue,
              });
            }
          }
        }
      } else {
        result = setTypes.filter((x) => x.locode);
        if (
          this.booking.portOfDischargeValue &&
          this.booking.finalDestinationValue &&
          this.booking.portOfDischargeValue ==
            this.booking.finalDestinationValue
        ) {
          result = result.filter((x) => x.type != "Port of Discharge");
        }
      }
      if (this.booking.finalDestinationValue) {
        let find = result.find(
          (x) => x.locode == this.booking.finalDestinationValue
        );
        if (!find) {
          result.push({
            type: "Final Destination",
            locode: this.booking.finalDestinationValue,
          });
        }
      }
      // if(this.booking.portOfDischargeValue == this.booking.finalDestinationValue){
      //   result = result.filter(x=>x.type != 'Port of Discharge')
      // }
      return result;
    },
    activeHaulier() {
      let hauliers = this.hauliers ?? [];
      if (this.booking.transportCoordinatorId) {
        return this.booking.transportCoordinator;
      } else if (this.booking.isCarrierHaulage) {
        return hauliers.find((x) => x.flag == "isCarrierHaulage");
      } else if (this.booking.isPrivateHaulage) {
        return hauliers.find((x) => x.flag == "isPrivateHaulage");
      } else if (this.booking.isRailage) {
        return hauliers.find((x) => x.flag == "isRailage");
      } else return null;
    },
    availableParties() {
      let parties = [
        "forwarder",
        "shipper",
        "onBehalfShipper",
        "onBehalfForwarder",
      ];
      let result = [];
      for (let party of parties) {
        if (this.booking[party]) {
          result.push(this.booking[party]);
        }
      }
      return result;
    },
    filterCountries() {
      let result = this.countries;
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
    filterDestinationPort() {
      let result = this.allDestinationPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase()) ||
            x.locode
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase())
        );
      }
      return result;
    },
    filterOriginPort() {
      let result = this.allOriginPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });

      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchOrigin.toLowerCase()) ||
            x.locode.toLowerCase().includes(this.searchOrigin.toLowerCase())
        );
      }
      return result;
    },
    filterShippingLines() {
      let result = this.shippingLines;
      if (this.searchShippingLine) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchShippingLine.toLowerCase()) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchShippingLine.toLowerCase()))
        );
      }
      return result;
    },
    filterVesselOperator() {
      let result = this.vesselOperators;
      if (this.searchVesselOperator) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchVesselOperator.toLowerCase()) ||
            (x.friendlyName &&
              x.friendlyName
                .toLowerCase()
                .includes(this.searchVesselOperator.toLowerCase()))
        );
      }
      return result;
    },
    filterSuggestedContracts() {
      let result = this.suggestedContracts;
      if (this.searchContract) {
        result = result.filter(
          (x) =>
            (x.contracts[0] &&
              x.contracts[0].contractNo
                .toLowerCase()
                .includes(this.searchContract.toLowerCase())) ||
            (x.contractOwner &&
              x.contractOwner.name
                .toLowerCase()
                .includes(this.searchContract.toLowerCase())) ||
            (x.contractOwner &&
              x.contractOwner.alias
                .toLowerCase()
                .includes(this.searchContract.toLowerCase()))
        );
      }
      return result;
    },
    dischargePorts() {
      let result =
        this.booking.consigneeProfile &&
        this.booking.consigneeProfile.consigneeProfilePorts
          ? this.booking.consigneeProfile.consigneeProfilePorts.filter(
              (x) => x.type == "discharge"
            )
          : [];
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase())) ||
            (x.code &&
              x.code
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    loadingPorts() {
      let result =
        this.booking.consigneeProfile &&
        this.booking.consigneeProfile.consigneeProfilePorts
          ? this.booking.consigneeProfile.consigneeProfilePorts.filter(
              (x) => x.type == "loading"
            )
          : [];
      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name.toLowerCase().includes(this.searchOrigin.toLowerCase())) ||
            (x.code &&
              x.code.toLowerCase().includes(this.searchOrigin.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    filterRegimeCodes() {
      let result = this.regimeCodes;
      if (this.regimeCodeSearch) {
        result = result.filter((x) =>
          x.code.toLowerCase().includes(this.regimeCodeSearch.toLowerCase())
        );
      }
      return result;
    },
  },
  async created() {
    this.shippingLines = await this.$API.getShippingLines();
    this.vesselOperators = await this.$API.getVesselOperators();
  },

  methods: {
    getIntegrationStatusColor(status) {
      if (status === "SUCCESS" || status === "COMPLETED") {
        return "success";
      } else if (status === "ACTIVE" || status === "NEW") {
        return "primary";
      } else if (
        status === "ERROR" ||
        status === "INVALID" ||
        status === "CANCELLED"
      ) {
        return "error";
      } else if (status === "PENDING") {
        return "orange";
      } else {
        return "grey";
      }
    },

    addToContainerSelection(id) {
      if (this.selectedContainers.includes(id)) {
        this.selectedContainers = this.selectedContainers.filter(
          (x) => x != id
        );
      } else {
        this.selectedContainers.push(id);
      }
    },
    cancelFile() {
      let index = this.linkedFiles.findIndex(
        (x) => x.id == this.selectedFile.id
      );
      if (index > -1) {
        this.linkedFiles.splice(index, 1);
      }
      this.$emit("refreshContainers");

      this.previewShipmentFile = false;
    },
    confirmExportNotificationItems() {
      this.selectedContainers = [];
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      // console.log(today)
      // console.log(this.booking.bookingContainers)
      this.exportNotificationContainers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          x.status != "Ghost" &&
          x.status != "Cancelled" &&
          x.bookingId == this.booking.id &&
          x.exportNotificationReference &&
          x.exportNotificationStatus == "PENDING" &&
          x.LOADING_POINT_1 &&
          x.LOADING_POINT_1.date &&
          new Date(x.LOADING_POINT_1.date).setHours(0, 0, 0, 0) > today
      );
      // console.log("All eQ67 Containers", this.exportNotificationContainers);
      this.confirmExportNotificationModal = true;
      this.containerKey++;
    },
    copyContent(data) {
      navigator.clipboard.writeText(data);
      this.copySnackbar = true;
    },
    async downloadDocument(doc) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = doc.url;
      hiddenElement.target = "_blank";
      hiddenElement.download = doc.name;
      hiddenElement.click();
    },
    async editContractNumber() {
      let containers = this.booking.bookingContainers
        ? this.booking.bookingContainers.filter(
            (x) =>
              !x.isDeleted &&
              x.status != "Ghost" &&
              x.status != "Cancelled" &&
              x.bookingId == this.booking.id
          )
        : [];
      let products = [
        ...new Set(
          [].concat
            .apply(
              [],
              containers.map((x) => x.containerProducts)
            )
            .map((x) => x.productId)
        ),
      ];
      let containerTypes = [
        ...new Set(
          [].concat.apply(
            [],
            containers.map((x) => x.containerTypeCode)
          )
        ),
      ];
      this.suggestedContracts = [];
      if (
        this.booking.etd &&
        containerTypes.length > 0 &&
        products.length > 0 &&
        this.booking.portOfLoadValue &&
        this.booking.portOfDischargeValue &&
        this.booking.shippingLineId
      ) {
        let contracts = await this.$API.getBookingContracts({
          params: {
            origin: this.booking.portOfLoadValue,
            originCountry: this.booking.portOfLoadValue
              ? this.booking.portOfLoadValue.substring(0, 2)
              : null,
            destination: this.booking.portOfDischargeValue,
            destinationCountry: this.booking.portOfDischargeValue
              ? this.booking.portOfDischargeValue.substring(0, 2)
              : null,
            contractPartyId: this.booking.shippingLineId,
            date: this.booking.etd,
            containerTypes: containerTypes,
            products: products,
          },
        });
        this.suggestedContracts = contracts;
      }

      this.contractModal = true;
    },
    getContractParty(party) {
      if (this.booking[party]) {
        return this.booking[party].name;
      } else return null;
    },
    cargoSummary() {
      let result = [];
      if (this.booking && this.booking.bookingContainers) {
        let containers = this.booking.bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            (x.status == "Allocated" ||
              x.status == null ||
              x.status == "Short Shipped") &&
            x.bookingId == this.booking.id
        );
        containers.forEach((x) => {
          let containerProducts = x.containerProducts.map((y) => y.productId);
          containerProducts = containerProducts.sort((a, b) => a - b);
          let temporaryProducts = x.temporaryProducts.map(
            (y) => y.product + y.variation
          );
          temporaryProducts = temporaryProducts.sort();
          x.transportType = x.isPrivateHaulage
            ? "Merchant"
            : x.isCarrierHaulage
            ? "Carrier"
            : x.isRailage
            ? "Rail"
            : "Other";
          x.hash =
            x.gensetRequired +
            x.containerTypeCode +
            x.verificationMethod +
            x.transportCoordinatorId +
            x.transportType +
            containerProducts.join("_") +
            temporaryProducts.join("_");
        });
        let uniqueCombinations = [...new Set(containers.map((x) => x.hash))];
        for (let i = 0; i < uniqueCombinations.length; i++) {
          let filtered = containers.filter(
            (x) => x.hash == uniqueCombinations[i]
          );
          let products = [].concat.apply(
            [],
            containers.map((x) => x.containerProducts)
          );
          let detail = filtered[0];
          let obj = {
            count: filtered.length,
            containerType: detail.containerTypeCode,
            products: detail.containerProducts
              .map((x) => this.$Format.capitalizeFirstLetter(x.product.name))
              .join(", "),
            services: detail.temporaryProducts,
            method: detail.verificationMethod,
            pallets: products
              .filter((x) => x.pallets)
              .map((x) => x.pallets)
              .reduce((a, b) => a + b, 0),
            transportCoordinator: detail.transportCoordinator ?? null,
            transportType: detail.isPrivateHaulage
              ? "Merchant"
              : detail.isCarrierHaulage
              ? "Carrier"
              : detail.isRailage
              ? "Rail"
              : "Unknown",
            type: detail.transportType,
            hash: uniqueCombinations[i],
          };
          result.push(obj);
        }
      }
      return result;
    },
    editReference(type, label) {
      this.referenceType = { field: type, label: label };
      this.referenceModal = true;
    },
    async favouritePort(item, type, arrayType) {
      if (arrayType == "Origin") {
        let index = this.allOriginPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      } else {
        let index = this.allDestinationPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      }
    },
    formatDate(date) {
      let isoFormat = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "yyyy-mm-dd HH:MM");
      }
      return isoFormat;
    },
    formatDateOnly(date) {
      let isoFormat = null;
      if (date) {
        isoFormat = dateFormat(new Date(date), "yyyy-mm-dd");
      }
      return isoFormat;
    },
    async generateQ67() {
      this.generatingQ67 = true;
      let result = await this.$API.generateQ67({
        bookingId: this.booking.id,
        comment: this.q67Comment,
        ...this.q67Request,
      });

      console.log("Q67 Result", result);
      let findForm = this.integrationForms.find(
        (x) => x.countryIntegrationId == 9 || x.countryIntegrationId == 11
      );
      console.log("Export notification data", result.exportNotificationData);
      if (
        result &&
        result.exportNotificationData &&
        result.exportNotificationData.response &&
        result.exportNotificationData.response.value
      ) {
        this.errors = result.exportNotificationData.response.value.messages;
        this.errorSnackbar = true;
      }

      if (
        result &&
        result.exportNotificationData &&
        result.exportNotificationData.repsonse &&
        result.exportNotificationData.response.createLineItemsError
      ) {
        this.errors = [
          result.exportNotificationData.response.createLineItemsError,
        ];
        this.errorSnackbar = true;
      }
      if (
        result.exportNotificationData &&
        result.exportNotificationData.response &&
        result.exportNotificationData.response.ppecbBookingReference
      ) {
        this.booking.exportNotificationReference =
          result.exportNotificationData.response.ppecbBookingReference;
        this.booking.exportNotificationStatus =
          result.exportNotificationData.response.status;
        this.exportNotificationKey++;
        let findContainers = this.booking.bookingContainers.filter(
          (x) =>
            !x.isDeleted &&
            x.bookingId == this.booking.id &&
            x.status != "Ghost" &&
            x.status != "Cancelled"
        );
        if (
          result.exportNotificationData.response.lineItems &&
          typeof result.exportNotificationData.response.lineItems != "object"
        ) {
          findContainers.forEach((x) => {
            x.exportNotificationStatus =
              result.exportNotificationData.response.status;
          });
        }
      }
      if (findForm && findForm.answers) {
        let parties = [
          "forwarder",
          "shipper",
          "onBehalfShipper",
          "onBehalfForwarder",
        ];
        let available = [];
        for (let party of parties) {
          if (this.booking[party]) {
            this.booking[party].role = party;
            available.push(this.booking[party]);
          }
        }
        if (this.q67Request.ppecbInv) {
          let findParty = available.find(
            (x) => x.name == this.q67Request.ppecbInv
          );
          if (findParty) {
            findForm.answers["CHARGE_PARTY"] = findParty.role;
          }
        }
        findForm.answers["PPECB_N_CODE"] = this.q67Request.ppecbNcode ?? "";
        findForm.answers["COMMENT"] = this.q67Request.comments ?? "";
        await this.$API.updateBookingForms(findForm);
      }

      if (result) {
        this.booking.q67 = result.q67;
        this.booking.q67Id = result.q67Id;
        this.booking.q67Excel = result.q67Excel;
        this.booking.q67ExcelId = result.q67ExcelId;

        this.q67CommentModal = false;
        this.q67Comment = null;
        this.$message({
          type: "success",
          message: `Q67 Generated Successfully`,
        });
      }
      this.generatingQ67 = false;
      this.$emit("refreshContainers");
    },
    async getAllPorts(profile) {
      if (profile.originCountry) {
        this.allOriginPorts = await this.$API.getPortsByCountry(
          profile.originCountry.iso2
        );
      }
      if (profile.destinationCountry) {
        this.allDestinationPorts = await this.$API.getPortsByCountry(
          profile.destinationCountry.iso2
        );
      }
    },
    async getRegimeCodes(modal = false) {
      this.regimeCodeModal = modal;
      this.regimeCodeLoading = true;
      let params = {};
      if (this.booking.destinationCountry) {
        params.countryCode = this.booking.destinationCountry.iso2;
      }
      if (this.booking.originCountryId) {
        params.originCountryId = this.booking.originCountryId;
      }
      if (
        this.booking.bookingContainers &&
        this.booking.bookingContainers &&
        this.booking.bookingContainers.length > 0
      ) {
        params.productCode = [].concat.apply(
          [],
          this.booking.bookingContainers
            .filter(
              (x) =>
                !x.isDeleted &&
                (x.status == "Allocated" || x.status == null) &&
                x.bookingId == this.booking.id
            )
            .map((x) => x.containerProducts)
        );
        params.productCode = [
          ...new Set(params.productCode.map((x) => x.productId)),
        ];
      } else if (this.containerItem && this.containerItem.bookingCargoItems) {
        params.productCode = [
          ...new Set(
            this.containerItem.bookingCargoItems.map((x) => x.productId)
          ),
        ];
      }
      this.regimeCodes = await this.$API.getRegimeCodes({
        params: params,
      });
      this.regimeCodeLoading = false;
    },
    async getBookingFormResponses() {
      let findBooking = this.integrationForms.find(
        (x) => x.bookingId == this.booking.id
      );
      if (this.integrationForms.length == 0 || !findBooking) {
        this.integrationForms = await this.$API.getBookingForms(
          this.booking.id
        );
      }
    },
    async getPPECBVoyages() {
      this.loadingVoyages = true;
      this.ppecbVoyages = await this.$API.getPPECBVoyages();
      if (this.booking.vessel) {
        let findVessel = this.ppecbVoyages.find(
          (x) =>
            x.vesselName.toLowerCase() == this.booking.vessel.name.toLowerCase()
        );
        if (findVessel) {
          this.q67Request.voyage = findVessel.voyageNumber;
        }
      }
      this.loadingVoyages = false;
    },
    openSailingSchedules() {
      if (this.booking.portOfLoadValue && this.booking.portOfDischargeValue) {
        this.sailingScheduleModal = true;
      } else {
        this.sailingScheduleError = true;
      }
    },
    productSummary() {
      let result = [];
      if (this.booking && this.booking.bookingContainers) {
        let products = [].concat.apply(
          [],
          this.booking.bookingContainers
            .filter(
              (x) =>
                !x.isDeleted &&
                (x.status == "Allocated" || x.status == null) &&
                x.bookingId == this.booking.id
            )
            .map((x) => x.containerProducts)
        );
        products.forEach((x) => {
          x.hash =
            x.productId +
            x.quantityType +
            x.hazardous +
            x.organic +
            x.industrial;
        });
        let uniqueCombinations = [...new Set(products.map((x) => x.hash))];
        for (let i = 0; i < uniqueCombinations.length; i++) {
          let filtered = products.filter(
            (x) => x.hash == uniqueCombinations[i]
          );
          let detail = filtered[0];
          let obj = {
            product: detail.product,
            quantityType: detail.quantityType,
            quantity: filtered
              .filter((x) => x.quantity)
              .map((x) => x.quantity)
              .reduce((a, b) => a + b, 0),
            pallets: filtered
              .filter((x) => x.pallets)
              .map((x) => x.pallets)
              .reduce((a, b) => a + b, 0),
            hazardous: detail.hazardous,
            organic: detail.organic,
            industrial: detail.industrial,
            nettWeight: filtered
              .filter((x) => x.nettWeight)
              .map((x) => x.nettWeight)
              .reduce((a, b) => a + b, 0),
            grossWeight: filtered
              .filter((x) => x.grossWeight)
              .map((x) => x.grossWeight)
              .reduce((a, b) => a + b, 0),
          };
          result.push(obj);
        }
      }
      return result;
    },

    setRegimeCode(regime) {
      this.booking.regimeCode = regime.code;
      this.booking.regime = regime;
      this.booking.regimeId = regime.id;
      this.booking.setPointTemp = regime.setPointTemp;
      this.booking.changeRegime = true;
      // if (regime.regimeVents && regime.regimeVents.length > 0) {
      //   this.ventSettings = [
      //     "Closed",
      //     "15",
      //     "20",
      //     "25",
      //     "28",
      //     "30",
      //     "35",
      //     "40",
      //     "50",
      //     "Fully Open",
      //     "CA",
      //     "MA",
      //     "Auto",
      //   ].filter((x) => regime.regimeVents.map((x) => x.ventCode).includes(x));
      // } else {
      //   this.ventSettings = [
      //     "Closed",
      //     "15",
      //     "20",
      //     "25",
      //     "28",
      //     "30",
      //     "35",
      //     "40",
      //     "50",
      //     "Fully Open",
      //     "CA",
      //     "MA",
      //     "Auto",
      //   ];
      // }
      // if (this.ventSettings.length == 1) {
      //   this.containerItem.airFlowValue = this.ventSettings[0];
      // }
      this.regimeCodeModal = false;
      this.$emit("bookingChange");
      this.$emit("refreshRegimeVents");
    },
    deselectAllFileContainers() {
      this.selectedContainers = [];
    },
    selectAllFileContainers(containers) {
      this.selectedContainers = containers.map((x) => x.id);
    },
    selectSchedule(schedule) {
      this.booking.carrier = schedule.carrier;
      this.booking.vessel = schedule.vessel;
      this.booking.vesselId = schedule.vesselId;
      this.booking.voyage = schedule.voyage;
      this.booking.sailingSchedule = schedule;
      this.booking.sailingScheduleId = schedule.id;
      this.booking.carrierScac = schedule.scac;
      this.booking.serviceName = schedule.serviceName;
      this.booking.eta = schedule.eta;
      this.booking.etd = schedule.etd;
      this.booking.atd = null;
      this.booking.ata = null;
      this.booking.shippingLine = schedule.shippingLine;
      this.booking.shippingLineId = schedule.shippingLineId;
      this.booking.carrierName = schedule.carrier;
      this.booking.mainCarriageVesselName = schedule.vesselDescription;
      this.booking.mainCarriageConveyanceNumber = schedule.voyage;
      this.booking.stackDate = schedule.stackDate;
      this.booking.stackDateId = schedule.stackDateId;
      this.booking.isTransshipment = !schedule.direct;
      this.booking.transportRouting = schedule.via;
      let terms = [];
      if (
        this.booking.consigneeProfile &&
        (!this.booking.contractNumber || this.booking.contractNumber == "")
      ) {
        for (
          let i = 0;
          i < this.booking.consigneeProfile.consigneeProfileIncoTerms.length;
          i++
        ) {
          let term = this.booking.consigneeProfile.consigneeProfileIncoTerms[i];
          let result = term.consigneeProfileShippingContracts
            .filter((x) => x.shippingLine)
            .map((y) => ({
              id: term.id,
              incoTerm: term.incoTerm,
              shippingLine: y.shippingLine,
              scacCode: y.shippingLine.scacCode,
              contractNumber: y.contractNumber,
              contractOwner: y.contractOwner,
            }));
          terms = [...terms, ...result];
        }
      }
      this.suggestedDealTerms = terms.filter(
        (x) => x.scacCode == this.booking.carrierScac
      );
      if (this.suggestedDealTerms.length == 1) {
        this.booking.contractOwner = this.suggestedDealTerms[0].contractOwner;
        this.booking.contractNumber = this.suggestedDealTerms[0].contractNumber;
      }
      this.sailingScheduleModal = false;
      this.$emit("bookingChange");
    },
    async setCASettings() {
      let filteredContainers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          x.status != "Ghost" &&
          x.status != "Cancelled" &&
          x.bookingId == this.booking.id
      );
      filteredContainers.forEach((x) => {
        x.oxygen = this.booking.oxygen;
        x.carbonDioxide = this.booking.carbonDioxide;
      });
      for (let i = 0; i < filteredContainers.length; i++) {
        await this.$API.updateContainer({
          id: filteredContainers[i].id,
          caProduct: filteredContainers[i].caProduct,
          controlledAtmosphere: filteredContainers[i].controlledAtmosphere,
          oxygen: filteredContainers[i].oxygen,
          carbonDioxide: filteredContainers[i].carbonDioxide,
          vents: filteredContainers[i].vents,
        });
      }
      await this.$API.updateBooking({
        id: this.booking.id,
        caProduct: this.booking.caProduct,
        oxygen: this.booking.oxygen,
        carbonDioxide: this.booking.carbonDioxide,
        vents: this.booking.vents,
      });
    },
    async setCAProduct() {
      this.savingCAProduct = true;
      this.booking.vents =
        this.booking.caProduct && this.booking.caProduct != "MAXTEND"
          ? "Closed"
          : this.booking.caProduct == "MAXTEND"
          ? "MAXTEND"
          : this.booking.vents;
      let filteredContainers = this.booking.bookingContainers.filter(
        (x) =>
          !x.isDeleted &&
          x.status != "Ghost" &&
          x.status != "Cancelled" &&
          x.bookingId == this.booking.id
      );
      if (this.booking.caProduct) {
        let findProduct = this.caProducts.find(
          (x) => x.name == this.booking.caProduct
        );
        this.booking.oxygen = findProduct.oxygen;
        this.booking.carbonDioxide = findProduct.carbonDioxide;
      } else {
        this.booking.oxygen = null;
        this.booking.carbonDioxide = null;
      }
      filteredContainers.forEach((x) => {
        if (this.booking.caProduct) {
          if (this.booking.caProduct != "MAXTEND") {
            x.vents = "Closed";
          } else if (this.booking.caProduct != "MAXTEND") {
            x.vents = "MAXTEND";
          }
          x.caProduct = this.booking.caProduct;
          x.controlledAtmosphere = true;
          let findProduct = this.caProducts.find(
            (y) => y.name == this.booking.caProduct
          );
          if (findProduct) {
            x.oxygen = findProduct.oxygen;
            x.carbonDioxide = findProduct.carbonDioxide;
            x.nitrogen = 0;
          }
        } else {
          x.caProduct = null;
          x.controlledAtmosphere = false;
        }
      });
      for (let i = 0; i < filteredContainers.length; i++) {
        await this.$API.updateContainer({
          id: filteredContainers[i].id,
          caProduct: filteredContainers[i].caProduct,
          controlledAtmosphere: filteredContainers[i].controlledAtmosphere,
          oxygen: filteredContainers[i].oxygen,
          carbonDioxide: filteredContainers[i].carbonDioxide,
          vents: filteredContainers[i].vents,
        });
      }
      this.$emit("refreshContainers");
      await this.$API.updateBooking({
        id: this.booking.id,
        caProduct: this.booking.caProduct,
        oxygen: this.booking.oxygen,
        carbonDioxide: this.booking.carbonDioxide,
        vents: this.booking.vents,
      });
      this.savingCAProduct = false;
      // this.caProductModal = false;
    },
    async setContract(contract) {
      let parties = [
        "forwarder",
        "shipper",
        "onBehalfShipper",
        "onBehalfForwarder",
      ];
      let result = [];
      for (let party of parties) {
        if (this.booking[party]) {
          let obj = {
            role: party,
            ...this.booking[party],
          };
          result.push(obj);
        }
      }
      let owner = result.find((x) => x.id == contract.contractOwnerId);
      this.booking.contractId = contract.id;
      this.booking.contractOwner = owner ? owner.role : null;
      this.booking.contractNumber = contract.contracts[0].contractNo;
      this.booking.contractPartyId = contract.contractOwnerId;
      this.contractModal = false;
      this.$emit("bookingChange");
    },
    setBookingOffice(office) {
      this.booking.bookingOffice = office.locode;
      this.booking.bookingOfficeName = office.name;
      this.bookingOfficeModal = false;
      this.$emit("bookingChange");
    },
    setCountry(country) {
      if (this.countryType == "Origin") {
        this.booking.originCountry = country;
        this.booking.originCountryId = country.id;
      } else if (this.countryType == "Destination") {
        this.booking.destinationCountry = country;
        this.booking.destinationCountryId = country.id;
      }
      this.$emit("bookingChange");
      this.countryModal = false;
    },
    setNCode() {
      let find = this.availableParties.find(
        (x) => x.name == this.q67Request.ppecbInv
      );
      if (find && find.nNumber) {
        this.q67Request.ppecbNcode = find.nNumber;
        this.nCodeKey++;
      }
    },
    setPort(type, item) {
      if (type == "portOfLoad") {
        this.booking.portOfLoadValue = item.locode ?? item.code;
        this.booking.portOfLoadCity = item.name;
        if (!this.booking.originCountry) {
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.booking.portOfLoadValue.substring(0, 2)
          );
          if (findCountry) {
            this.booking.originCountry = findCountry;
            this.booking.originCountryId = findCountry.id;
          }
        }
      } else if (type == "portOfDischarge") {
        this.booking.portOfDischargeValue = item.locode ?? item.code;
        this.booking.portOfDischargeCity = item.name;
        if (!this.booking.finalDestinationValue) {
          this.booking.finalDestinationValue = item.locode ?? item.code;
          this.booking.finalDestinationCity = item.name;
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.booking.finalDestinationValue.substring(0, 2)
          );
          if (findCountry) {
            this.booking.destinationCountry = findCountry;
            this.booking.destinationCountryId = findCountry.id;
          }
        }
      } else if (type == "finalDestination") {
        this.booking.finalDestinationValue = item.locode ?? item.code;
        this.booking.finalDestinationCity = item.name;
        let findCountry = this.countries.find(
          (x) => x.iso2 == this.booking.finalDestinationValue.substring(0, 2)
        );
        if (findCountry) {
          this.booking.destinationCountry = findCountry;
          this.booking.destinationCountryId = findCountry.id;
        }
        if (!this.booking.portOfDischargeValue) {
          this.booking.portOfDischargeValue = item.locode ?? item.code;
          this.booking.portOfDischargeCity = item.name;
        }
      }
      this.portSearch = null;
      this.polModal = false;
      this.podModal = false;
      this.fdModal = false;
      this.$emit("bookingChange");
    },

    setVesselOperator(item) {
      this.booking.vesselOperator = item;
      console.log(this.booking.vesselOperator);
      this.booking.vesselOperatorId = item.id;
      this.vesselOperatorModal = false;
      if (!this.booking.mainCarriageConveyanceNumber) {
        this.voyageNumberModal = true;
      }
      this.$emit("bookingChange");
    },
    setShippingLine(item) {
      this.booking.shippingLine = item;
      console.log(this.booking.shippingLine);
      this.booking.shippingLineId = item.id;
      this.shippingLineModal = false;
      if (!this.booking.mainCarriageConveyanceNumber) {
        this.voyageNumberModal = true;
      }
      this.$emit("bookingChange");
    },
    setVessel(vessel) {
      this.booking.vessel = vessel;
      this.booking.vesselId = vessel.id;
      this.booking.mainCarriageVesselName = vessel.name;
      this.vesselModal = false;
      if (!this.booking.shippingLine) {
        this.shippingLineModal = true;
      } else {
        this.voyageNumberModal = true;
      }
      this.$emit("bookingChange");
    },
    async submitConfirmedeQ67ContainerItems() {
      this.loadingConfirmExportNotification = true;
      let result = await this.$API.confirmExportNotificationItems({
        bookingId: this.booking.id,
        containers: this.selectedContainers,
      });
      if (result.status == "Success") {
        this.$message.success("Containers Successfully Confirmed!");
        this.booking.exportNotificationStatus = result.bookingStatus;

        this.exportNotificationKey++;
      } else {
        this.snackbar = {
          text: result.message,
          timeout: -1,
          color: "red",
          show: true,
        };
      }
      this.$emit("refreshContainers");
      this.confirmExportNotificationModal = false;
      this.loadingConfirmExportNotification = false;
      this.selectedContainers = [];
    },
    viewDocument(item) {
      this.item = item;
      if (
        this.item.type &&
        (this.item.type.includes("image/") ||
          this.item.type.includes("application/pdf"))
      ) {
        this.imageLoading = true;
      }
      this.previewModal = true;
    },
    viewShipmentFile(file) {
      this.selectedFile = file;
      this.previewKey++;
      this.previewShipmentFile = true;
    },
    viewQ67() {
      let findForm = this.integrationForms.find(
        (x) => x.countryIntegrationId == 9 || x.countryIntegrationId == 11
      );
      this.q67Request = {};
      if (findForm && findForm.answers) {
        if (findForm.answers["PPECB_N_CODE"]) {
          this.q67Request.ppecbNcode = findForm.answers["PPECB_N_CODE"];
        }
        if (
          findForm.answers["CHARGE_PARTY"] &&
          this.booking[findForm.answers["CHARGE_PARTY"]]
        ) {
          this.q67Request.ppecbInv =
            this.booking[findForm.answers["CHARGE_PARTY"]].name;
          if (this.booking[findForm.answers["CHARGE_PARTY"]].nNumber) {
            this.q67Request.ppecbNcode =
              this.booking[findForm.answers["CHARGE_PARTY"]].nNumber;
          }
        }

        if (findForm.answers["COMMENT"]) {
          this.q67Request.comments = findForm.answers["COMMENT"];
        }
      }
      if (!this.q67Request.ppecbInv) {
        let findOrg = this.availableParties.find(
          (x) => x.id == this.$store.state.currentOrg.id
        );
        if (findOrg) {
          this.q67Request.ppecbInv = findOrg.name;
        }
      }
      this.q67Request.submit = false;
      this.setNCode();
      this.getPPECBVoyages();
      this.q67CommentModal = true;
    },
    viewExportNotificationData(data) {
      this.viewExportNotification = true;
    },
    removeRegime() {
      this.$confirm("Are you sure you want to remove the regime?", "Confirm", {
        center: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "plain",
      }).then(async () => {
        this.booking.regime = null;
        this.booking.regimeCode = null;
        this.booking.regimeId = null;

        this.regimeKey++;
        this.$emit("bookingChange");
      });
    },
  },
};
</script>