var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"ml-0 pl-0 mx-0 px-0",attrs:{"cols":"12","sm":"4"}},[_c('v-list',{staticClass:"ml-0 pl-0 mx-0 px-0",attrs:{"dense":""}},[(_vm.selectedSchedule.stackDate)?_c('div',[_c('v-subheader',{staticStyle:{"font-size":"16px"}},[_vm._v("Stack Dates")]),_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reefer Stacks")]),_c('v-list-item-subtitle',[_c('v-avatar',{attrs:{"size":"16","color":_vm.selectedSchedule.stackDate.reeferStackStart &&
                        _vm.selectedSchedule.stackDate.reeferStackStartConfirmed
                          ? 'success'
                          : _vm.selectedSchedule.stackDate.reeferStackStart
                          ? 'orange'
                          : 'grey'}}),_vm._v(" "+_vm._s(_vm.formatDateTime( _vm.selectedSchedule.stackDate.reeferStackStart ) ?? "NONE")+" ")],1),_c('v-list-item-subtitle',[_c('v-avatar',{attrs:{"size":"16","color":_vm.selectedSchedule.stackDate.reeferStackFinish &&
                        _vm.selectedSchedule.stackDate.reeferStackFinishConfirmed
                          ? 'success'
                          : _vm.selectedSchedule.stackDate.reeferStackFinish
                          ? 'orange'
                          : 'grey'}}),_vm._v(" "+_vm._s(_vm.formatDateTime( _vm.selectedSchedule.stackDate.reeferStackFinish ) ?? "NONE")+" ")],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dry Stacks")]),_c('v-list-item-subtitle',[_c('v-avatar',{attrs:{"size":"16","color":_vm.selectedSchedule.stackDate.dryStackStart &&
                        _vm.selectedSchedule.stackDate.dryStackStartConfirmed
                          ? 'success'
                          : _vm.selectedSchedule.stackDate.dryStackStart
                          ? 'orange'
                          : 'grey'}}),_vm._v(" "+_vm._s(_vm.formatDateTime( _vm.selectedSchedule.stackDate.dryStackStart ) ?? "NONE")+" ")],1),_c('v-list-item-subtitle',[_c('v-avatar',{attrs:{"size":"16","color":_vm.selectedSchedule.stackDate.dryStackFinish &&
                        _vm.selectedSchedule.stackDate.dryStackFinishConfirmed
                          ? 'success'
                          : _vm.selectedSchedule.stackDate.dryStackFinish
                          ? 'orange'
                          : 'grey'}}),_vm._v(" "+_vm._s(_vm.formatDateTime( _vm.selectedSchedule.stackDate.dryStackFinish ) ?? "NONE")+" ")],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("FIS Stacks")]),_c('v-list-item-subtitle',[_c('v-avatar',{attrs:{"size":"16","color":_vm.selectedSchedule.stackDate.fisStart &&
                        _vm.selectedSchedule.stackDate.fisStartConfirmed
                          ? 'success'
                          : _vm.selectedSchedule.stackDate.fisStart
                          ? 'orange'
                          : 'grey'}}),_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.selectedSchedule.stackDate.fisStart) ?? "NONE")+" ")],1),_c('v-list-item-subtitle',[_c('v-avatar',{attrs:{"size":"16","color":_vm.selectedSchedule.stackDate.fisFinish &&
                        _vm.selectedSchedule.stackDate.fisFinishConfirmed
                          ? 'success'
                          : _vm.selectedSchedule.stackDate.fisFinish
                          ? 'orange'
                          : 'grey'}}),_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.selectedSchedule.stackDate.fisFinish) ?? "NONE")+" ")],1)],1)],1)],1):_vm._e(),(_vm.selectedSchedule.stackDate)?_c('v-divider'):_vm._e(),_c('v-subheader',{staticStyle:{"font-size":"16px"}},[_vm._v("Transport Legs")]),_c('v-divider'),_vm._l((_vm.selectedSchedule.sailingScheduleLegs),function(leg){return _c('v-list-item',{key:leg.id},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-chip',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(leg.leg))]),_c('v-chip',{staticStyle:{"border":"none"},attrs:{"small":"","outlined":""}},[_c('v-avatar',{attrs:{"size":"20","left":""}},[_c('v-img',{attrs:{"contain":"","src":`https://cdn.loglive.io/flags/4x3/${leg.portOfLoadValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`}})],1),_vm._v(" "+_vm._s(leg.portOfLoadValue)+" "),_c('v-icon',{staticClass:"mx-1",attrs:{"small":""}},[_vm._v("arrow_forward")]),_c('v-avatar',{staticClass:"ml-1",attrs:{"size":"20","left":""}},[_c('v-img',{attrs:{"contain":"","src":`https://cdn.loglive.io/flags/4x3/${leg.portOfDischargeValue
                          .substring(0, 2)
                          .toLowerCase()}.svg`}})],1),_vm._v(" "+_vm._s(leg.portOfDischargeValue)+" ")],1)],1),_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(_vm.formatDateTime(leg.etd))+" ")],1),_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"}},[_vm._v("schedule")]),_vm._v(" "+_vm._s(_vm.formatDateTime(leg.eta))+" ")],1),(leg.vesselDescription)?_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v("directions_boat")]),_vm._v(" "+_vm._s(leg.vesselDescription)+" ")],1):_vm._e(),(leg.voyage)?_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"grey"}},[_vm._v("tag")]),_vm._v(" "+_vm._s(leg.voyage)+" ")],1):_vm._e()],1)],1)})],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('gmap-map',{ref:"mapRef",staticClass:"ma-0 pa-0",staticStyle:{"height":"500px","width":"100%","border-radius":"20px"},attrs:{"zoom":_vm.zoom,"id":"map","options":{
              styles: _vm.mapStyle,
              disableDefaultUI: true,
            },"center":_vm.mapCenter},on:{"update:zoom":function($event){_vm.zoom=$event},"update:center":function($event){_vm.mapCenter=$event}}},[_c('gmap-custom-marker',{attrs:{"offsetY":-18,"alignment":"center","marker":{
                lat:_vm.x.latitude,
                lng:_vm.x.longitude
              }}},[_c('div',[_c('div',{staticStyle:{"border-radius":"50%","background-color":"var(--v-primary-base) !important","border":"3px solid black"}},[_c('v-avatar',{attrs:{"size":"32","color":"green"}},[_c('v-icon',[_vm._v("sailing")])],1)],1),_c('div',{staticClass:"map-marker"})])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }