<template>
  <div>
    <v-card flat :loading="loading">
      <v-toolbar flat>
        <v-toolbar-title>Export Notification JSON</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              id="task-download-request"
              icon
              @click="downloadRequest"
              ><v-icon>downloading</v-icon></v-btn
            >
          </template>
          <span style="font-size: 12px">Download Payload</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              id="task-download-response"
              icon
              @click="downloadResponse"
              ><v-icon>download</v-icon></v-btn
            >
          </template>
          <span style="font-size: 12px">Download Response</span>
        </v-tooltip>

        <a id="downloadAnchorElem" style="display: none"></a>
        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-card-text class="my-0 py-0">
        <v-tabs v-model="selectedTab" show-arrows>
          <v-tab key="payload" href="#payload"
            >Request Payload <v-icon right>data_object</v-icon></v-tab
          >
          <v-tab key="response" href="#response"
            >Response <v-icon right>data_object</v-icon></v-tab
          >
        </v-tabs>
        <v-tabs-items :key="update" v-model="selectedTab">
          <v-tab-item
            value="payload"
            style="background: var(--v-modal-base) !important"
          >
            <v-card flat>
              <v-card-text class="text-wrap ma-0 pa-0" v-loading="loading">
                <vue-json-pretty
                  :data="request"
                  :showLine="false"
                  :virtual="true"
                  style="font-size: 12px"
                  :showIcon="true"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item
            value="response"
            style="background: var(--v-modal-base) !important"
          >
            <v-card flat>
              <v-card-text class="text-wrap ma-0 pa-0" v-loading="loading">
                <vue-json-pretty
                  :data="response"
                  :showLine="false"
                  :virtual="true"
                  style="font-size: 12px"
                  :showIcon="true"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data: () => ({
    selectedTab: "payload",
    request: {},
    response: {},
    loading: false,
    update: 0,
  }),
  mounted() {
    this.getRequestData();
  },
  methods: {
    downloadRequest() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.request));
      var dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "request_" + this.data);
      dlAnchorElem.click();
    },
    downloadResponse() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.response));
      var dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "response_" + this.data);
      dlAnchorElem.click();
    },
    async getRequestData() {
      this.loading = true;
      let result = await this.$API.getExportNotificationData({
        data: this.data,
      });
      this.request = result.request;
      this.response = result.response;
      this.update++;
      this.loading = false;
    },
  },
};
</script>