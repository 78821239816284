<template>
    <v-card>
        <v-card-title>Manage Products
            <v-spacer></v-spacer>

            <v-btn icon @click="bulkUpdateProducts" color="tertiary" :loading="saveBulkUpdate"><v-icon>save</v-icon></v-btn>
            <v-btn icon @click="closeModal" color="primary">
                <v-icon>close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <!--table-->
            <v-data-table :headers="headers" :items="products" :items-per-page="5" class="elevation-1"
                item-key="bookingContainerId" :expanded="expanded" show-expand>

                <!-- <template v-slot:[`item.action`]="{ item }">
                    <v-btn icon @click="expandBooking(item)" small>
                                                    <v-icon small
                                                        v-if="expanded.map(x => x.bookingContainerId).includes(item.bookingContainerId)">expand_less</v-icon>
                                                    <v-icon small v-else>expand_more</v-icon>
                                                </v-btn>
                </template> -->
                <template v-slot:[`item.ctoNo`]="{ item }">
                    {{ item.ctoNo }}
                </template>

                <template v-slot:[`item.genset`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Genset') ? 'color: green' : 'color: red'"
                        @click="toggleProduct(item, 'Genset')">
                        <v-icon>
                            {{ productExists(item, 'Genset') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                </template>

                <template v-slot:[`item.oddFlow`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Otflow') ? 'color: green' : 'color: red'"
                        @click="toggleProduct(item, 'Otflow')">
                        <v-icon>
                            {{ productExists(item, 'Otflow') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                </template>

                <template v-slot:[`item.zoono`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Zoono') ? 'color: green' : 'color: red'"
                        @click="toggleProduct(item, 'Zoono')">
                        <v-icon>
                            {{ productExists(item, 'Zoono') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                </template>

                <!-- <template v-slot:[`item.industrial`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Industrial') ? 'color: green' : 'color: red'"
                        @click="toggleProduct(item, 'Industrial')">
                        <v-icon>
                            {{ productExists(item, 'Industrial') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                </template> -->

                <template v-slot:[`item.tempcheck`]="{ item }">
                    <v-btn icon :style="productExists(item, 'TelemPlus') ? 'color: green' : 'color: red'"
                        v-if="productExists(item, 'TelemPlus')" @click="openRemoveDialog(item, 'TelemPlus')">
                        <v-icon>
                            {{ productExists(item, 'TelemPlus') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                    <v-icon v-else :color="productExists(item, 'TelemPlus') ? 'green' : 'secondary'">
                        {{ productExists(item, 'TelemPlus') ? 'check' : 'close' }}
                    </v-icon>
                </template>


                <template v-slot:[`item.dunnageBags`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Dunnage Bags') ? 'color: green' : 'color: red'"
                        @click="toggleProduct(item, 'Dunnage Bags')">
                        <v-icon>
                            {{ productExists(item, 'Dunnage Bags') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                </template>


                <template v-slot:[`item.blastFreezing`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Blast Freezing') ? 'color: green' : 'color: red'"
                        v-if="productExists(item, 'Blast Freezing')" @click="openRemoveDialog(item, 'Blast Freezing')">
                        <v-icon>
                            {{ productExists(item, 'Blast Freezing') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                    <v-icon v-else :color="productExists(item, 'Blast Freezing') ? 'green' : 'secondary'">
                        {{ productExists(item, 'Blast Freezing') ? 'check' : 'close' }}
                    </v-icon>
                </template>



                <template v-slot:[`item.lateArrival`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Late Arrival') ? 'color: green' : 'color: red'"
                        @click="toggleProduct(item, 'Late Arrival')">
                        <v-icon>
                            {{ productExists(item, 'Late Arrival') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                </template>



                <template v-slot:[`item.voidPlugs`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Void Plugs') ? 'color: green' : 'color: red'"
                        v-if="productExists(item, 'Void Plugs')" @click="openRemoveDialog(item, 'Void Plugs')">
                        <v-icon>
                            {{ productExists(item, 'Void Plugs') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                    <v-icon v-else :color="productExists(item, 'Void Plugs') ? 'green' : 'secondary'">
                        {{ productExists(item, 'Void Plugs') ? 'check' : 'close' }}
                    </v-icon>
                </template>

                <template v-slot:[`item.fumigation`]="{ item }">
                    <v-btn icon :style="productExists(item, 'Fumigation') ? 'color: green' : 'color: red'"
                        @click="toggleProduct(item, 'Fumigation')">
                        <v-icon>
                            {{ productExists(item, 'Fumigation') ? 'check' : 'close' }}
                        </v-icon>
                    </v-btn>
                </template>


                <template v-slot:[`item.overnightGenset`]="{ item }">
                    <v-icon :color="productExists(item, 'TelemPlus') ? 'secondary' : 'secondary'">
                        {{ productExists(item, 'Overnight Genset') ? 'check' : 'close' }}
                    </v-icon>
                </template>

                <template v-slot:expanded-item="{ item, headers }">
                    <td class="px-1" :colspan="headers.length">
                        <v-expand-transition>
                            <v-card flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="3">
                                            <v-card outlined flat>
                                                <v-card-title>
                                                    <v-list-item>
                                                        <!-- {{ item }} -->
                                                        <v-select label="Monitoring Service"
                                                            placeholder="Select a Monitoring Service" persistent-placeholder
                                                            hide-details outlined dense class="py-1" :items="[
                                                                'iCheck',
                                                                'TempCheck',
                                                                'TempCheckPLUS',
                                                                // 'TempCheckLite',
                                                                'Port Monitoring'
                                                            ]" v-model="item.monitoringDevice">

                                                        </v-select>

                                                        <v-spacer></v-spacer>
                                                        <v-btn icon color="primary" class="ml-3"
                                                            v-if="item.monitoringDevice"
                                                            @click="addServices(item)"><v-icon>add_circle_outline</v-icon></v-btn>
                                                        <v-btn icon color="tertiary" class="ml-2"
                                                            @click="updateDeviceCount(item, 'TelemPlus')"
                                                            :loading="loadingStatus && loadingStatus[`${item.bookingContainerId}-TelemPlus`]"
                                                            v-if="item.monitoringDevice"><v-icon>save</v-icon></v-btn>

                                                    </v-list-item>

                                                </v-card-title>
                                                <v-card-text
                                                    v-if="item.monitoringDevice && item.deviceOptions.length > 0 && addingProductsLoader === false">
                                                    <v-list dense class="ma-0 pa-0">
                                                        <v-list-item
                                                            v-for="(product, index) in item.deviceOptions.filter(option => option.count > 0)"
                                                            :key="index">
                                                            <v-text-field type="number" v-model="product.count" :min="0"
                                                                class="small-text-field" style="text-align: center;"
                                                                :label="product.variation + ' ' + 'Template'"
                                                                @input="updateProductCount(product)" />
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="3">
                                            <v-card outlined flat>
                                                <v-card-actions class="justify-end">
                                                    <v-btn icon color="tertiary"
                                                        @click="updateDeviceCount(item, 'Void Plugs')"
                                                        :loading="loadingStatus && loadingStatus[`${item.bookingContainerId}-Void Plugs`]">
                                                        <v-icon>save</v-icon>
                                                    </v-btn>
                                                </v-card-actions>
                                                <v-card-text>
                                                    <v-list dense class="ma-0 pa-0">
                                                        <v-list-item
                                                            v-for="(product, index) in filteredProductData(item, 'Void Plugs')"
                                                            :key="index">
                                                            <v-text-field type="number" v-model="product.count" :min="0"
                                                                class="small-text-field" style="text-align: center;"
                                                                @input="updateProductCount(product)"
                                                                :label="'Void Plugs'" />
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-actions class="justify-end">
                                                    <v-btn icon color="tertiary"
                                                        @click="updateDeviceCount(item, 'Blast Freezing')"
                                                        :loading="loadingStatus && loadingStatus[`${item.bookingContainerId}-Blast Freezing`]">
                                                        <v-icon>save</v-icon>
                                                    </v-btn>
                                                </v-card-actions>
                                                <v-card-text>
                                                    <v-list dense class="ma-0 pa-0">
                                                        <v-list-item
                                                            v-for="(product, index) in filteredProductData(item, 'Blast Freezing')"
                                                            :key="index">
                                                            <v-text-field type="number" v-model="product.count" :min="0"
                                                                class="small-text-field" style="text-align: center;"
                                                                @input="updateProductCount(product)"
                                                                :label="'Blast Freezing'" />

                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-title><v-spacer></v-spacer>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-list dense class="ma-0 pa-0">
                                                        <v-list-item
                                                            v-for="(product, index) in filteredProductData(item, 'Overnight Genset')"
                                                            :key="index">
                                                            <v-text-field type="number" v-model="product.count" :min="0"
                                                                class="small-text-field" style="text-align: center;"
                                                                :label="'Overnight Genset'"
                                                                @input="updateProductCount(product)" disabled />
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card-text>

                                            </v-card>
                                        </v-col>

                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-expand-transition>
                    </td>
                </template>

            </v-data-table>
        </v-card-text>
        <v-dialog v-if="currentItem" v-model="addServiceModal" max-width="400px" persistent>
            <v-card>
                <v-card-title>TelemPlus Products <v-spacer></v-spacer>
                    <!-- <v-btn icon @click="addProducts" color="primary"><v-icon>add_circle_outline</v-icon></v-btn> -->
                    <v-btn icon @click="addServiceModal = false"
                        color="secondary"><v-icon>close</v-icon></v-btn></v-card-title>
                <v-list>
                    <v-list-item v-for="(product, index) in currentItem.deviceOptions" :key="index">
                        <v-checkbox :label="product.variation" :value="product" v-model="filterSelectedDevices"  @change="changeProductStatus(product)"/>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
        <v-dialog v-model="removeDialog" max-width="400px" persistent>
            <v-card>
                <v-card-title class="headline d-flex justify-center">Confirmation</v-card-title>
                <v-card-text>By doing this will remove all the devices linked to this container and got {{
                    this.currentDevice }} as product name?</v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn color="green darken-1" text @click="removeDevice">Yes</v-btn>
                    <v-btn color="red darken-1" text @click="removeDialog = false">No</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
export default {
    props: ['products', 'productsAndServicesModal', 'containerBookings'],
    data: () => ({
        addingProductsLoader: false,
        selectedDevices: [],
        loadingStatus: {},
        addServiceModal: false,
        deviceCountUpdate: false,
        saveBulkUpdate: false,
        removeDialog: false,
        expandedId: null,
        expandedData: null,
        partyModal: false,
        partyType: null,
        partyAddress: null,
        partyAddresses: [],
        selectedOrganisation: null,
        searchOrganisation: null,
        loadingLinkedOrganisations: false,
        linkedOrganisations: [],
        shipmentProfile: {},
        acceptLiability: false,
        loadingProfile: false,
        originCharge: '',
        freightCharge: '',
        destinationCharge: '',
        savingShipment: false,
        shipmentPartyAddresses: [],
        loadingAdresses: false,
        incoTerms: [],
        expanded: [],
        selectedProductData: [],
        headers: [
            {
                value: 'ctoNo',
                text: 'CTO No.',
                align: 'center',
                show: true
            },
            {
                value: 'genset',
                text: 'Genset',
                align: 'center',
                show: true
            },
            // {
            //     value: 'industrial',
            //     text: 'Industrial',
            //     align: 'center',
            //     show: true
            // },
            {
                value: 'tempcheck',
                text: 'TelemPlus',
                align: 'center',
                show: true,
            },
            {
                value: 'oddFlow',
                text: 'Otflow',
                align: 'center',
                show: true
            },
            {
                value: 'zoono',
                text: 'Zoono',
                align: 'center',
                show: true
            },
            {
                value: 'dunnageBags',
                text: 'Dunnage Bags',
                align: 'center',
                show: true,
            },
            {
                value: 'blastFreezing',
                text: 'Blast Freezing',
                align: 'center',
                show: true
            },
            {
                value: 'lateArrival',
                text: 'Late Arrival',
                align: 'center',
                show: true
            },

            {
                value: 'voidPlugs',
                text: 'Void Plugs',
                align: 'center',
                show: true
            },
            {
                value: 'fumigation',
                text: 'Fumigation',
                align: 'center',
                show: true
            },
            {
                value: 'overnightGenset',
                text: 'Overnight Genset',
                align: 'center',
                show: true
            },
        ],
        // deviceOptions:[
        // { product: "TelemPlus", variation: "2G GEO", count: 0 },
        // { product: "TelemPlus", variation: "3G GEO", count: 0 },
        // { product: "TelemPlus", variation: "USB", count: 0 },
        // { product: "TelemPlus", variation: "RF", count: 0 },
        // { product: "TelemPlus", variation: "4G GEO", count: 0 },
        // { product: "TelemPlus", variation: "GEO EAGLE", count: 0 },
        // { product: "TelemPlus", variation: "MOST", count: 0 },
        // { product: "TelemPlus", variation: "2G TIVE", count: 0 },
        // { product: "TelemPlus", variation: "5G TIVE", count: 0 },

        // ],
        updateFieldsRequired: false,
        currentDevice: null,
        currentBookingContainerId: null,
        currentItem: null
    }),
    watch: {

    },
    created() {
        // console.log('products',this.deviceOptions);
        console.log('this.temporaryproducts', this.products);
    },
    computed: {
        // selectedProductObjects() {
        // return this.deviceOptions.filter(product => this.selectedDevices.includes(product.variation));
        // },
        filterSelectedDevices() {
            let selectedDevices = null;
            if (this.currentItem) {
                selectedDevices= this.currentItem.deviceOptions.filter(product => product.count > 0)
                return selectedDevices
            }
            return null
        },
    },
    methods: {
        changeProductStatus(product){
            console.log('product coming in',product);
            if(product.count > 0){
                product.count = 0
            }
            else{
                product.count = 1
            }
        },
        addProducts() {
            this.addingProductsLoader = true

            this.addingProductsLoader = false

        },
        addServices(item) {
            this.addServiceModal = true
            console.log('item', item)
            this.currentItem = item


        },
        uniqueKeyFunction(item) {
            // Assuming item has a unique property 'bookingContainerId'
            return item.bookingContainerId;
        },
        openRemoveDialog(item, type) {
            this.removeDialog = true;
            this.currentDevice = type;
            this.currentBookingContainerId = item.bookingContainerId;
            this.currentItem = item;
            // this.type = type;
        },

        async removeDevice() {
            let item = this.currentItem
            console.log('this.currentItem', this.currentItem);
            let isDeviceActive = item.type.some(product => product.product === this.currentDevice && product.count > 0);
            // console.log('isDeviceActive', isDeviceActive);
            if (isDeviceActive) {
                await this.$API.removeDevices({ bookingContainerId: this.currentBookingContainerId, product: this.currentDevice });
                this.removeDialog = false;
                this.$message.success(`${this.currentDevice} has all been removed from this container`);
                this.products.forEach(product => {
                    if (product.bookingContainerId === this.currentBookingContainerId) {
                        product.type.forEach(typeItem => {
                            if (typeItem.product === this.currentDevice) {
                                typeItem.count = 0;
                            }
                        });
                    }
                });
            }
        },
        async updateDeviceCount(item, productType) {
            // this.deviceCountUpdate = true
            const key = `${item.bookingContainerId}-${productType}`;
            if (!this.loadingStatus || typeof this.loadingStatus !== 'object') {
                this.loadingStatus = {};
            }
            this.$set(this.loadingStatus, key, true);
            let bookingContainerId = item.bookingContainerId
            let bookingId = item.bookingId
            let shipmentId = item.shipmentId
            console.log('monitoringService', item.monitoringDevice)
            console.log('products', this.products)
            let monitoringService = item.monitoringDevice

            
            if(monitoringService){
                this.selectedProductData.push({ product: 'Port Monitoring', variation: 'Standard', count: 1 })
            }
            if(productType == 'TelemPlus'){
                this.selectedProductData = this.products[0].deviceOptions.filter(option => option.count > 0)
            }
            console.log('selectedProductData', this.selectedProductData)
            let res = await this.$API.updateDeviceCount({ bookingContainerId: bookingContainerId, products: this.selectedProductData, product: productType, bookingId: bookingId, shipmentId: shipmentId, monitoringService: monitoringService })
            if (res.status == 200) {
                this.$message.success('Products updated successfully')
            }
            else {
                this.$message.error('Something went wrong')
            }
            this.products.forEach(product => {
                if (product.bookingContainerId === bookingContainerId) {
                    product.type.forEach(typeItem => {
                        if (typeItem.product === productType) {
                            this.selectedProductData.forEach(telemItem => {
                                if (typeItem.variation === telemItem.variation) {
                                    typeItem.count = telemItem.count;
                                }
                            });
                        }
                    });
                }
            });


            this.selectedProductData = []
            this.$set(this.loadingStatus, key, false);
        },

        async updateProductCount(product) {
            // console.log(this.selectedProductData);
            if (this.selectedProductData.includes(product)) {
                let findIndex = this.selectedProductData.findIndex(x => {
                    return x.variation == product.variation
                })
                this.selectedProductData[findIndex].count = product.count
                this.selectedProductData[findIndex].monitoringService = product.monitoringService
            }
            else {
                this.selectedProductData.push(product)
            }


        },
        // async updateProductCount(product, event) {
        // if (event) {
        //     // If the checkbox is checked, add the product to the selectedProductData array
        //     this.selectedProductData.push(product)
        // } else {
        //     // If the checkbox is unchecked, remove the product from the selectedProductData array
        //     let index = this.selectedProductData.findIndex(selectedProduct => selectedProduct.variation === product.variation)
        //     if (index !== -1) {
        //     this.selectedProductData.splice(index, 1)
        //     }
        //     // Set the count property of the product object to 0
        //     product.count = 0
        // }
        // },
        filteredProductData(item, product) {
            let filterData = []
            for (let x = 0; x < item.type.length; x++) {
                if (item.type[x].product == product) {
                    let obj = {
                        variation: item.type[x].variation,
                        count: item.type[x].count
                    }
                    filterData.push(obj)
                }
            }
            return filterData
        },
        closeModal() {
            this.expanded = []
            this.$emit('close')
        },
        productExists(item, productName) {
            return item.type.some(product => product.product === productName && product.count > 0);
        },
        toggleProduct(item, productName) {
            const index = item.type.findIndex(product => product.product === productName);
            if (index > -1) {
                if (item.type[index].count > 0) {
                    item.type[index].count = 0;
                } else {
                    item.type[index].count = 1;
                }
            }
        },
        async bulkUpdateProducts() {
            this.saveBulkUpdate = true

           let res = await this.$API.bulkUpdateTemporaryProducts({ products: this.products })
            if (res.status == 200) {
                this.$message.success('Products updated successfully')
                this.saveBulkUpdate = false
                this.$emit('close')
            }
            else {
                this.$message.error('Something went wrong')
            }

        },
        //Expand
        async isExpanded(bookingContainerId) {
            let res = this.expanded.filter(x => x.bookingContainerId === bookingContainerId);
            return res
        },
        async expandBooking(item) {
            console.log(item)
            let findIndex = this.expanded.findIndex(x => x.bookingContainerId == item.bookingContainerId)
            if (findIndex == -1) {
                this.expanded = [item]
            } else {
                this.expanded.splice(findIndex, 1)
            }

        },

    }
}
</script>

<style scoped>
.scroll {
    overflow-y: scroll;
}

.small-text-field {
    width: 12px;
    /* Adjust the width as needed */
    height: 50px;
    /* Adjust the height as needed */
    font-size: 14px;
    /* Adjust the font size as needed */
}

.custom-width {
    width: 200px;
}
</style>